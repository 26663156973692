import config from '@config';
import File from '@projectTypes/File';
import { IframeHTMLAttributes } from 'react';

export default class JupyterService {
  host: string;
  eventListener: number;
  lastEvent: any;
  constructor(host: string) {
    this.host = host;
  }

  createEventListener(
    file: File,
    onChange: (value: string) => void,
    onLoad: () => void,
    onError: () => void,
  ) {
    if (window) {
      window.addEventListener('message', (e) => {
        if (e.origin.includes(this.host)) {
          this.lastEvent = e;
          if (e.type === config.jupyterError) {
            onError();
          }
          if (!e.data || !e.data.event) {
            return;
          }
          if (e.data.event === config.jupyterGetContentEventName) {
            const el = this.getElement();
            if (el && el.contentWindow) {
              el.contentWindow.postMessage({ event: 'content', file }, '*');
            } else {
              e.source.postMessage(
                {
                  event: 'content',
                  file,
                },
                { targetOrigin: e.origin },
              );
            }
          } else if (e.data.event === config.jupyterCodeEventName) {
            const code = e.data.code;
            onChange(code);
            onLoad();
          } else if (e.data.event === config.jupyterError) {
            onError();
          }
        }
      });
    }
  };

  getElement() {
    const el = document.querySelector('#jupyter-iframe');
    return el as any;
  }

  runCode() {
    const el = this.getElement();
    if (el && el.contentWindow) {
      el.contentWindow.postMessage({ event: config.jupyterRunEvent }, '*');
    } else if (this.lastEvent) {
      this.lastEvent.source.postMessage(
        { event: config.jupyterRunEvent },
        { targetOrigin: this.lastEvent.origin },
      );
    }
  }

  runAllCode() {
    const el = this.getElement();
    if (el && el.contentWindow) {
      for (let i = 0; i < 3; i++) {
        el.contentWindow.postMessage({ event: config.jupyterRunAllEvent }, '*');
      }
    } else if (this.lastEvent) {
      for (let i = 0; i < 3; i++) {
        this.lastEvent.source.postMessage(
          { event: config.jupyterRunAllEvent },
          { targetOrigin: this.lastEvent.origin },
        );
      }
    }
  }

  addNewBlock() {
    const el = this.getElement();
    if (el && el.contentWindow) {
      el.contentWindow.postMessage({ event: config.jupyterNewBlockEvent }, '*');
    } else if (this.lastEvent) {
      this.lastEvent.source.postMessage(
        { event: config.jupyterNewBlockEvent },
        { targetOrigin: this.lastEvent.origin },
      );
    }
  }

  removeBlock() {
    const el = this.getElement();
    if (el && el.contentWindow) {
      el.contentWindow.postMessage(
        { event: config.jupyterRemoveBlockEvent },
        '*',
      );
    } else if (this.lastEvent) {
      this.lastEvent.source.postMessage(
        { event: config.jupyterRemoveBlockEvent },
        { targetOrigin: this.lastEvent.origin },
      );
    }
  }

  getInitalPath() {
    return `${this.host}`;
  }

  getFilePath(fileId: string) {
    return `${this.host}/lab/?path=${fileId}.ipynb`;
  }
}
