import { PolicyName } from '@projectTypes/Policy';

import Policy from './Policy';
import AugmentedMemoizationPolicy from './Policy/AugmentedMemoizationPolicy';
import MemoizationPolicy from './Policy/MemoizationPolicy';
import RulePolicy from './Policy/RulePolicy';
import TEDPolicy from './Policy/TEDPolicy';

const components: Record<PolicyName, typeof Policy> = {
  [PolicyName.AugmentedMemoizationPolicy]: AugmentedMemoizationPolicy,
  [PolicyName.MemoizationPolicy]: MemoizationPolicy,
  [PolicyName.RulePolicy]: RulePolicy,
  [PolicyName.TEDPolicy]: TEDPolicy,
};

export default class PolicyFactory {
  componentId = 0;

  create(name: PolicyName, data?: Partial<Policy>): Policy {
    const NamedPolicy = components[name];
    const component = new NamedPolicy({
      id: this.componentId,
      name,
    });
    this.componentId += 1;
    if (data && data.params) {
      data.params.forEach((param) => {
        const paramIndex = component.params?.findIndex(
          (p) => p.name === param.name,
        );
        if (paramIndex !== undefined && paramIndex !== -1) {
          component.params[paramIndex] = {
            ...component.params[paramIndex],
            ...param,
          };
        }
      });
    }
    return component;
  }
}
