import BaseApiService from '@services/BaseApiService';
import { AxiosInstance } from 'axios';

export default class ApiService extends BaseApiService {
  axiosInstance: AxiosInstance;
  editorId: string;
  platformId: string;

  setEditorId(editorId: string) {
    this.editorId = editorId;
  }

  setPlatformId(platformId: string) {
    this.platformId = platformId;
  }

  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/user/user_actions/',
    });
  }

  async trackAction(actionType: string, data: any) {
    const response = await this.axiosInstance.post('', {
      action_type: actionType,
      data,
      platform_id: this.platformId,
      editor_id: this.editorId,
    });
    return response.data;
  }
}
