import FeaturizerComponent from './FeaturizerComponent';

export default class LanguageModelFeaturizer extends FeaturizerComponent {
  params = [
    {
      name: 'model_name',
      label: 'Model name',
      type: 'string',
      value: 'bert',
      required: true,
    },
    {
      name: 'model_weights',
      label: 'Model weights',
      type: 'string',
      value: 'rasa/LaBSE',
      required: true,
    },
    {
      name: 'cache_dir',
      label: 'Cache dir',
      type: 'string',
      value: '',
      required: false,
    },
  ];
}
