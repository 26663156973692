import React, { useEffect, useState } from 'react';

import File from '@projectTypes/File';
import JsonTableContainer from './JsonTableContainer';

interface JsonTableProps {
  displayedFile: File | null;
  onChange: (value: any) => void;
}

const JsonTable = (props: JsonTableProps) => {
  const { displayedFile, onChange } = props;
  const [initialFileData, setInitialFileData] = useState<null | any[]>(null);
  const [initialColumnKeys, setInitialColumnKeys] = useState([]);
  const [prevDisplayedFileId, setPrevDisplayedFileId] = useState('');

  const setData = (fileData: any[]) => {
    setInitialFileData(fileData.map((rowData) => Object.values(rowData)));
    setInitialColumnKeys(Object.keys(fileData[0] || []));
  };

  const processData = (data: Record<string, any>[]) => {
    const newData = {};
    data.forEach((row, index) => {
      newData[row.id !== undefined ? row.id : index] = row;
    });
    onChange(JSON.stringify(newData));
  };

  useEffect(() => {
    if (displayedFile && prevDisplayedFileId !== displayedFile.id) {
      setPrevDisplayedFileId(displayedFile.id);
      let fileData = [];
      try {
        fileData = Object.values(JSON.parse(displayedFile.newContent));
      } catch (e) {
        // Wrong content type
      }
      setData(fileData);
    }
  }, [displayedFile]);

  if (!initialFileData) {
    return null;
  }

  return (
    <JsonTableContainer
      initialData={initialFileData}
      initialColumnKeys={initialColumnKeys}
      onChange={(fileData) => {
        if (fileData.length !== initialFileData.length) {
          setData(fileData);
        }
        processData(fileData);
      }}
    />
  );
};

export default JsonTable;
