import getQueryStringValue from '@helpers/getQueryStringValue';
import config from '@config';

export default function getOpenFileNames() {
  let openFileNames = getQueryStringValue(config.openFileNames) || [];
  if (typeof openFileNames === 'string') {
    // Transform to array and split if multiple filenames are provided
    openFileNames = openFileNames.split(',');
  }
  return openFileNames;
}
