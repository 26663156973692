import IntentClassifierComponent from './IntentClassifierComponent';

export default class KeywordIntentClassifier extends IntentClassifierComponent {
  params = [
    {
      name: 'case_sensitive',
      label: 'Case sensitive',
      type: 'boolean',
      value: true,
      required: true,
    },
  ];
}
