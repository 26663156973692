import React, { useState } from 'react';
import useDispatch from '@hooks/useDispatch';
import { Button, Stack, Select } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import { addComponent } from '@slices/pipelineSlices';
import {
  PipelineComponentName,
  PipelineComponentNamesByType,
  PipelineComponentType,
} from '@projectTypes/Pipeline';

interface PipelineComponentContainerProps {
  pipelineComponentType: PipelineComponentType;
}

const NewComponentForm = (props: PipelineComponentContainerProps) => {
  const { pipelineComponentType } = props;
  const dispatch = useDispatch();
  const [newComponent, setNewComponent] = useState<PipelineComponentName>();

  const addNewComponent = () => {
    if (newComponent) {
      dispatch(addComponent(newComponent));
    }
  };

  return (
    <Stack direction="row" spacing={4}>
      <Select
        placeholder="Select component"
        maxW="300px"
        bg="primary"
        color="white"
        border="none"
        value={newComponent}
        onChange={(event) =>
          setNewComponent(event.target.value as PipelineComponentName)
        }
      >
        {Object.values(PipelineComponentNamesByType[pipelineComponentType]).map(
          (value) => (
            <option key={value} value={value} style={{ color: 'black' }}>
              {value}
            </option>
          ),
        )}
      </Select>
      <Button
        onClick={addNewComponent}
        leftIcon={<AddIcon />}
        bg="primary"
        color="white"
      >
        Add component
      </Button>
    </Stack>
  );
};

export default NewComponentForm;
