import {
  RunProjectDto,
  BootChatDto,
  TrainChatDto,
  ChatMessageDto,
  TrainingLogs,
  RunFrom,
} from '@projectTypes/Terminal';
import BaseApiService from '@services/BaseApiService';

export default class ApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
    });
  }

  async runFile(projectId: string, fileId: string) {
    const response = await this.axiosInstance.post<any>(
      `/run/projects/${projectId}/${fileId}`,
      {},
    );
    return response.data;
  }

  async runProject(projectId: string) {
    throw new Error('Route removed');
    const response = await this.axiosInstance.post<RunProjectDto>(
      `/run/${projectId}`,
    );
    return response.data;
  }

  async trainChat(projectId: string) {
    let queue = window.localStorage.getItem('queue') || '';
    if (queue) {
      queue = `?queue=${queue}`;
    }

    const response = await this.axiosInstance.post<TrainChatDto>(
      `/train/${projectId}${queue}`,
    );
    return response.data;
  }

  async getChatTraining(trainingId: string, timestamp: number) {
    const response = await this.axiosInstance.get<TrainingLogs>(
      `/train/trainings/${trainingId}/logs?timestamp=${timestamp}`,
    );
    return response.data;
  }

  async stopChatTraining(trainingId: string) {
    const response = await this.axiosInstance.delete<TrainChatDto>(
      `/train/trainings/${trainingId}`,
    );
    return response.data;
  }

  async shareTraining(trainingId: string, publish = false) {
    const response = await this.axiosInstance.post<TrainChatDto>(
      `/train/trainings/${trainingId}`,
    );
    if (publish) {
      await this.axiosInstance.patch<TrainChatDto>(
        `/train/trainings/${trainingId}/publish`,
      );
    }
    return response.data;
  }

  async bootChat(runId: string, from: RunFrom) {
    const response = await this.axiosInstance.post<BootChatDto>(
      `/chats/${runId}`,
      { from },
    );
    return response.data;
  }

  async getChatBoot(runId: string) {
    const response = await this.axiosInstance.get<BootChatDto>(
      `/chats/run/${runId}`,
    );
    return response.data;
  }

  async stopChatBoot(runId: string) {
    const response = await this.axiosInstance.delete<BootChatDto>(
      `/chats/run/${runId}`,
    );
    return response.data;
  }

  async resetChat(runId: string) {
    const response = await this.axiosInstance.patch<BootChatDto>(
      `/chats/run/${runId}/reset`,
    );
    return response.data;
  }

  // TODO this API route will be changed
  async sendChatMessage(runId: string, message: string) {
    let userId = window.localStorage.getItem('user_id');
    if (!userId) {
      userId = `${Date.now()}-${Math.floor(Math.random() * 1000000)}`;
      window.localStorage.setItem('user_id', userId);
    }

    const response = await this.axiosInstance.post<
      ChatMessageDto[] | { error: string }
    >(`/chats/run/${runId}/chat`, { message, user_id: userId });
    return response.data;
  }

  async getLastRunId(projectId: string) {
    const response = await this.axiosInstance.get<any>(
      `/train/${projectId}/last`,
    );
    return response.data.id;
  }
}
