import React from 'react';
import dynamic from 'next/dynamic';
import { Spinner, Image, Box } from '@chakra-ui/react';
import { getDisplayName } from 'next/dist/shared/lib/utils';

const ImageViewer = (props) => {
  const {displayedFile, onChange} = props;
  let imageSrc = displayedFile.content;
  return (
    <Box >
        <div>
            <Image borderRadius={'8px'} src={imageSrc}/>
        </div>
    </Box>
  );
};

export default ImageViewer;
