import config from '@config';
import File, { FileData, FileDto } from '@projectTypes/File';

import ApiService from './_private/ApiService';
import TemplateApiService from './_private/TemplateApiService';
import Project from '@projectTypes/Project';

export default class FileService {
  apiService: ApiService;
  templateApiService: TemplateApiService;
  files: File[] = [];

  constructor(host: string, apiRoute: string) {
    this.apiService = new ApiService(host, apiRoute);
    this.templateApiService = new TemplateApiService(host, apiRoute);
  }

  async getProjectFiles(projectId: string, withCredentials?: boolean) {
    const filesDto = await this.apiService.getProjectFiles(
      projectId,
      withCredentials,
    );

    if (filesDto === null) {
      return this.files.filter((file) => file.projectId === projectId);
    }
    let files = filesDto.map((fileDto) => this.fileConstructor(fileDto));
    files = await Promise.all(
      files.map(async (file) => {
        if ((file.blob as any) === true) {
          const blob = await this.apiService.getFileBlob(projectId, file.id);
          const blobUrl = URL.createObjectURL(blob);
          file.blob = blobUrl;
        }
        return file;
      }),
    );
    files = await this.checkConfigFiles(projectId, files);
    files.forEach((file) => this.saveLocalFile(file));
    return files;
  }

  async getFile(projectId: string, id: string) {
    const fileDto = await this.apiService.getFile(projectId, id);
    if (fileDto === null) {
      return this.files.find((file) => file.id === id);
    }
    const file = this.fileConstructor(fileDto);
    if ((file.blob as any) === true) {
      const blob = await this.apiService.getFileBlob(projectId, file.id);
      const blobUrl = URL.createObjectURL(blob);
      file.blob = blobUrl;
    }
    this.files.push(file);
    return file;
  }

  async createFile(fileData: FileData) {
    const duplicateFile = this.files.find((file) => {
      if (
        file.projectId === fileData.projectId &&
        file.path === fileData.path &&
        file.name === fileData.name &&
        file.contentType === fileData.contentType
      ) {
        return true;
      }
      return false;
    });
    if (duplicateFile) {
      throw new Error('File duplicate');
    }
    const fileDto = await this.apiService.createFile(fileData);
    if (fileDto === null) {
      return null;
    }
    const file = this.fileConstructor(fileDto);
    this.files.push(file);
    return file;
  }

  setNewFileContent(fileId: string, newContent: string, blob?: any) {
    const fileIndex = this.files.findIndex((f) => f.id === fileId);
    this.files[fileIndex] = {
      ...this.files[fileIndex],
      newContent,
      isChanged: true,
    };
    if (blob) {
      this.files[fileIndex].blob = blob;
    }
    return newContent;
  }

  async setNewFileName(
    fileId: string,
    name: string,
    ext?: string,
  ): Promise<File | undefined> {
    const file = this.files.find((file) => file.id === fileId);
    if (file) {
      file.name = name;
      if (ext) file.contentType = ext;
      const fileDto = await this.saveNewFileName(file);
      return this.fileConstructor(fileDto);
    }

    return undefined;
  }

  async saveFiles(fileIds: string[]) {
    const projectFiles = this.files.filter(
      (file) => file && fileIds.includes(file.id) && file.isChanged,
    );
    const filesDto = await Promise.all(
      projectFiles.map(async (file) => this.saveNewFileContent(file)),
    );
    if (filesDto === null) {
      return this.files.filter((file) => fileIds.includes(file.id));
    }
    const files = filesDto.map((fileDto) => this.fileConstructor(fileDto));
    files.forEach((file) => this.saveLocalFile(file));
    return files;
  }

  async saveProjectFiles(projectId: string) {
    const projectFiles = this.files.filter(
      (file) => file && file.projectId === projectId && file.isChanged,
    );
    const filesDto = await Promise.all(
      projectFiles.map(async (file) => this.saveNewFileContent(file)),
    );
    if (filesDto === null) {
      return this.files.filter((file) => file.projectId === projectId);
    }
    const files = filesDto.map((fileDto) => this.fileConstructor(fileDto));
    files.forEach((file) => this.saveLocalFile(file));
    return files;
  }

  async deleteNonTemplateFilesFromProject(selectedProject: Project) {
    if (!selectedProject?.templateId) {
      return;
    }

    const deletePromises = this.files.map(async (file) => {
      if (
        file.projectId === selectedProject.id &&
        !file?.static &&
        !file?.templateFileId
      ) {
        console.log({ file });
        await this.deleteFile(file.id);
      }
    });

    await Promise.all(deletePromises);
  }

  async getProjectTemplateFiles(templateId: string) {
    const templateFilesDto = await this.templateApiService.getTemplateFiles(
      templateId,
    );

    const templateFiles = templateFilesDto.map((templateFileDto) =>
      this.fileConstructor(templateFileDto),
    );

    const projectFiles = templateFiles
      .map((file) => {
        let returnFile = null;
        this.files.forEach((f, index) => {
          if (f.templateFileId === file.id) {
            this.files[index] = {
              ...this.files[index],
              templateContent: file.content,
            };
            returnFile = this.files[index];
          }
        });
        return returnFile;
      })
      .filter((file) => file !== null);

    return projectFiles;
  }

  async resetFile(fileId: string) {
    const file = this.files.find((f) => f.id === fileId);
    const fileDto = await this.apiService.resetFile(file.id, file.projectId);
    const newFile = this.fileConstructor(fileDto);
    this.saveLocalFile(newFile);
    return newFile;
  }

  async deleteFile(fileId: string) {
    const fileIndex = this.files.findIndex((f) => f.id === fileId);
    if (fileIndex !== -1) {
      await this.apiService.deleteFile(
        this.files[fileIndex].id,
        this.files[fileIndex].projectId,
      );
      this.files.splice(fileIndex, 1);
    }
    return true;
  }

  projectTreeFileStructure() {
    const newFileSystem = {};
    let currentId;
    this.files.forEach((file) => {
      let currentFolder = newFileSystem;
      if (file.path) {
        const currentPath = [];
        file.path.split('/').forEach((subfolder) => {
          if (!subfolder) {
            return;
          }
          currentPath.push(subfolder);
          if (!currentFolder[subfolder]) {
            this.files.forEach((el) => {
              if (el.path === currentPath.join('/') && el.name === '.keep') {
                currentId = el.id;
              }
            });
            currentFolder[subfolder] = {
              id: currentId,
              isFolder: true,
              name: subfolder,
              path: currentPath.join('/'),
            };
            currentId += 1;
          }
          currentFolder = currentFolder[subfolder];
        });
      }
      currentFolder[file.name] = file;
    });

    return { fileSystem: newFileSystem, id: currentId };
  }

  getFiles() {
    return this.files;
  }

  recreateFileStructure() {
    let fileSystem = [];
    let currentKeepPath;
    let currentKeepId;
    this.files.forEach((el) => {
      if (el.name === '.keep') {
        currentKeepPath = el?.path;
        currentKeepId = el.id;
        fileSystem.push({
          id: el.id,
          isFolder: true,
          name: el.name,
          path: el?.path,
        });
      } else {
        fileSystem.push(el);
      }
    });

    return fileSystem;
  }

  setFiles(files: File[]) {
    this.files = files;
  }

  setToken(token: string) {
    this.apiService.setToken(token);
    this.templateApiService.setToken(token);
  }

  private async saveNewFileContent(file: File) {
    if (file.blob) {
      let blob: any = file.blob;
      if (typeof blob === 'string') {
        const resp = await fetch(blob);
        blob = (await resp.blob()) as Blob;
      }
      await this.apiService.updateFileBlob(file.id, file.projectId, blob);
    }
    const fileDto = await this.apiService.updateFile(file.id, file.projectId, {
      content: file.newContent,
    });
    return fileDto;
  }

  private async saveNewFileName(file: File) {
    const fileDto = await this.apiService.updateFile(file.id, file.projectId, {
      name: file.name,
      contentType: file.contentType,
    });
    return fileDto;
  }

  private async checkConfigFiles(projectId: string, files: File[]) {
    return files;
    let pipelineFile = files.find((file) => file.name === 'pipeline.yml');
    if (!pipelineFile) {
      const pipelineFileDto = await this.createFile({
        projectId,
        name: 'pipeline.yml',
        contentType: 'yml',
        content: config.pipelineYamlDefaultContent,
      });
      pipelineFile = this.fileConstructor(pipelineFileDto);
      files.push(pipelineFile);
    }
    let policiesFile = files.find((file) => file.name === 'policies.yml');
    if (!policiesFile) {
      const pipelineFileDto = await this.createFile({
        projectId,
        name: 'policies.yml',
        contentType: 'yml',
        content: config.policiesYamlDefaultContent,
      });
      policiesFile = this.fileConstructor(pipelineFileDto);
      files.push(pipelineFile);
    }
    return files;
  }

  private saveLocalFile(file: File) {
    const foundFileIndex = this.files.findIndex((f) => file.id === f.id);
    if (foundFileIndex !== -1) {
      this.files[foundFileIndex] = file;
    } else {
      this.files.push(file);
    }
  }

  private fileConstructor(fileDto: FileDto): File {
    const file = {
      ...fileDto,
      templateContent: null,
      newContent: fileDto.content,
      isChanged: false,
    };
    return file;
  }
}
