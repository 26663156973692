import YAML from 'js-yaml';

export default class YamlEditorService {
  getComponentsAsJson() {
    return {};
  }

  toFileData() {
    const content = this.stringifyToYml();
    return content;
  }

  parseFromYml(content: string) {
    const yamlData = YAML.load(content);
    return yamlData;
  }

  stringifyToYml() {
    const obj = this.getComponentsAsJson();
    return YAML.dump(obj);
  }
}
