import { PipelineComponentType } from '@projectTypes/Pipeline';
import PipelineComponent from '../PipelineComponent';

export default class TokenizerComponent extends PipelineComponent {
  type = PipelineComponentType.Tokenizer;
  params = [
    {
      name: 'intent_tokenization_flag',
      label: 'Intent tokenization flag',
      type: 'boolean',
      value: false,
      required: true,
    },
    {
      name: 'intent_split_symbol',
      label: 'Initial split symbol',
      type: 'string',
      value: '',
      required: true,
    },
  ];
}
