import User, { UpdateUser } from '@projectTypes/User';
import BaseApiService from '@services/BaseApiService';

export default class ApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({ host, apiRoute, route: '/auth' });
  }

  async loginGuest() {
    const response = await this.axiosInstance.post('/login-guest');
    return response.data;
  }

  async loginToken({
    platformToken,
    username,
    password,
  }: {
    platformToken?: string;
    username?: string;
    password?: string;
  }) {
    const response = await this.axiosInstance.post(
      '/login-token',
      platformToken
        ? {
            token: platformToken,
          }
        : {
            username,
            password,
          },
    );
    return response.data;
  }

  async login(username: string, password: string) {
    const response = await this.axiosInstance.post('/login', {
      username,
      password,
    });
    return response.data;
  }

  async signup(username: string, password: string) {
    const response = await this.axiosInstance.post('/signup', {
      username,
      password,
    });
    return response.data;
  }

  async verify() {
    const response = await this.axiosInstance.post('/verify');
    return response.data;
  }

  async updateUser(data: UpdateUser) {
    const response = await this.axiosInstance.patch<User>('/me', data);
    return response.data;
  }

  async checkUsername(username: string): Promise<boolean> {
    const response = await this.axiosInstance.get(`/check/${username}`);
    return response.data.ok;
  }
}
