import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Head from 'next/head';
import { ChakraProvider, LightMode } from '@chakra-ui/react';
import { ConfirmContextProvider } from 'chakra-confirm';
import ConditionalWrap from 'conditional-wrap';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar';

import { useStore } from '@store';
import useOnAppInit from '@hooks/useOnAppInit';
import useErrorLogs from '@hooks/useErrorLogs';
import theme from '@styles/theme';
import { CustomPage } from '@/types';

import '@styles/index.css';
import { useRouter } from 'next/router';

const BaseApp = ({ children }: any) => {
  useOnAppInit();
  useErrorLogs();
  const router = useRouter();
  useEffect(() => {
    if (router.pathname.includes('showcase')) {
      document.body.classList.add('showcase');
    } else {
      document.body.classList.remove('showcase');
    }
  }, [router.pathname]);

  return (
    <>
      <Head>
        <title>STEMI</title>
      </Head>
      {children}
    </>
  );
};

type CustomAppProps = Omit<AppProps, 'Component'> & { Component: CustomPage };

const App: React.FC<CustomAppProps> = ({ Component, pageProps }) => {
  const store = useStore(pageProps.initialReduxState);
  const shouldPersist = Component.persist || false;

  return (
    <Provider store={store}>
      <ConditionalWrap
        condition={shouldPersist}
        wrap={(c) => (
          <PersistGate persistor={store.__PERSISTOR} loading={null}>
            {c}
          </PersistGate>
        )}
      >
        <ChakraProvider theme={theme}>
          <LightMode>
            <ConfirmContextProvider>
              <BaseApp>
                <Component {...pageProps} />
                <ProgressBar
                  height="4px"
                  color={theme.colors.primary}
                  options={{ showSpinner: false }}
                  shallowRouting
                />
              </BaseApp>
            </ConfirmContextProvider>
          </LightMode>
        </ChakraProvider>
      </ConditionalWrap>
    </Provider>
  );
};

export default appWithTranslation(App);
