import { StreamConfig } from '@projectTypes/Stream';
import ApiService from './_private/ApiService';

class PythonWorker {
  private apiService: ApiService;

  constructor() {
    this.apiService = new ApiService();
  }

  public setToken(token: string) {
    this.apiService.setToken(token);
  }

  public async getStreamConfig(): Promise<StreamConfig | null> {
    return this.apiService.getStreamConfig();
  }
}

export default PythonWorker;
