import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import Store from '@projectTypes/Store';
import Editor from '@projectTypes/Editor';
import config from '@config';

import actionNameCreator from '@helpers/actionNameCreator';
import getQueryStringValue from '@/helpers/getQueryStringValue';

const userInitialState: Editor = {
  isSmall: false,
};

const anc = actionNameCreator('EDITOR');

const editorSlices = createSlice({
  name: 'editor',
  initialState: userInitialState,
  reducers: {
    setIsSmall(state, action: PayloadAction<boolean>) {
      state.isSmall = action.payload;
    },
    setInitialData(state, action: PayloadAction<void>) {
      if (getQueryStringValue(config.smallEditor) === 'true') {
        state.isSmall = true;
      }
    },
  },
});

export const { setInitialData, setIsSmall } = editorSlices.actions;

export default editorSlices.reducer;
