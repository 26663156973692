import BaseApiService from '@services/BaseApiService';
import config from '@config';
import { PythonSavedData } from '@projectTypes/Python';

export default class ApiService extends BaseApiService {
  constructor() {
    super();

    this.createAxiosInstance({
      host: config.backendHost,
      apiRoute: config.backendBasePath,
      route: '/python',
    });
  }

  async sendResult(data: any) {
    const response = await this.axiosInstance.post<any>('/', data);
    return response.data;
  }

  async getTestResults(platformId: string, templateSlug: string) {
    if (!platformId) {
      return [];
    }

    const response = await this.axiosInstance.get<{
      runs: PythonSavedData[];
      project: any;
    }>(`/byUser/${platformId}/template/${templateSlug}`);
    const data = response.data.runs.filter((p) => p.type === 'test');
    return data;
  }
}
