export default {
  defaultBootMessage: "I'm ready. Start by saying hello to me!",
  disableUserActions: process.env.NEXT_PUBLIC_DISABLE_USER_ACTIONS === 'true',
  disableHeartBeat: process.env.NEXT_PUBLIC_DISABLE_HEART_BEAT === 'true',
  webUrl: 'https://chatbot.stemi.education',
  host:
    process.env.NODE_ENV === 'development'
      ? process.env.NEXT_PUBLIC_LOCAL_URL || 'http://localhost:3000'
      : 'https://chatbot.stemi.education',
  scratchHost:
    'https://stemi-editor.ams3.cdn.digitaloceanspaces.com/scratch/index.html',
  backendHost:
    process.env.NEXT_PUBLIC_BACKEND_HOST || 'https://file.stemi.education',
  backendBasePath: '/api/v1',
  runServerHost: process.env.NEXT_PUBLIC_RUN_SERVER_HOST || 'https://run.stemi.education',
  graphImageRoute: `${process.env.NEXT_PUBLIC_RUN_SERVER_HOST}/public/`,
  runServerPath: '/api/v1',
  arduinoBuilderHost:
    process.env.ARDUINO_BUILDER_HOST || 'https://builder2.stemi.education',
  arduinoBuilderPath: '/api/v1',
  userActionHost:
    process.env.NEXT_PUBLIC_USER_ACTION_HOST || 'http://localhost:8000',
  userActionBasePath: '/api/v1',
  mqttHost: process.env.NEXT_PUBLIC_MQTT_HOST || 'ws://188.166.101.94:8889',
  mqttFastifyHost:
    process.env.NEXT_PUBLIC_MQTT_FASTIFY_HOST ||
    'https://mqtt1.stemi.education',
  editorFileTypes: {
    py: 'python',
    yml: 'yaml',
    json: 'json',
    cpp: 'cpp',
    ino: 'cpp',
    html: 'html',
    css: 'css',
    js: 'javascript',
  },
  sidebarWidth: 240,
  leftSidebarWidth: 240,
  rightSidebarWidth: 360,
  templateSlug: 'template',
  platformId: 'platform_id',
  openFileNames: 'open-files',
  // sessionId: 'session_id',
  studentProjectId: 'student_project_id',
  smallEditor: 'small',
  // studyGroup: 'study_group',
  platformToken: 'token',
  pipelineYamlDefaultContent: `pipeline:
  - name: WhitespaceTokenizer
  - name: RegexFeaturizer
  - name: LexicalSyntacticFeaturizer
  - name: CountVectorsFeaturizer
  - name: CountVectorsFeaturizer
    analyzer: char_wb
    min_ngram: 1
    max_ngram: 4
  - name: DIETClassifier
    epochs: 100
  - name: EntitySynonymMapper
  - name: ResponseSelector
    epochs: 100
  - name: FallbackClassifier
    threshold: 0.3
    ambiguity_threshold: 0.1`,
  policiesYamlDefaultContent: `policies:
  - name: MemoizationPolicy
  - name: TEDPolicy
    max_history: 5
    epochs: 100
  - name: RulePolicy`,
  stemiGraphKeywoard: '# STEMI_GRAPH:',
  newDatabaseFileContent: '{"0": {"id": "0"}}',
  shortDescription: 'shortDescription',
  imageUrl: 'imageUrl',
  projectGoal: 'projectGoal',
  projectInfo: [
    {
      key: 'problemDefinition',
      title: 'Definirajte problem',
      titleEn: 'Define the problem',
      value: '',
    },
    {
      key: 'peopleGroups',
      title: 'Na koje sve skupine ljudi utječe ovaj problem?',
      titleEn: 'Which groups of people are affected by this problem?',
      value: '',
    },
    {
      key: 'problemResponsibility',
      title: 'Tko je sve odgovoran za ovaj problem?',
      titleEn: 'Who is responsible for this problem?',
      value: '',
    },
    {
      key: 'problemSize',
      title: 'Koliko je problem velik?',
      titleEn: 'How big is the problem?',
      value: '',
    },
    {
      key: 'resolvingProblem',
      title: 'Što trenutno stoji na putu rješenja problema?',
      titleEn: 'What is blocking the current solution of the problem?',
      value: '',
    },
    {
      key: 'problemSelection',
      title: 'Zašto ste odabrali ovaj problem, zašto je važno riješiti ga?',
      titleEn:
        'Why did you choose this problem, and why is it important to solve it?',
      value: '',
    },
    {
      key: 'howChatbotSolvesThisProblem',
      title: 'Kako chatbot riješava ovaj problem?',
      titleEn: 'How does the chatbot solve this problem?',
      value: '',
    },
  ],
  groupProjectTemplateSlugs: [
    'final-project',
    'final_project',
    'final_iot_project',
  ],
  mainHTMLFileName: 'index.html',
  mainCSSFileName: 'index.css',
  mainJSFileName: 'index.js',
  mainArduinoFileName: 'main.ino',
  mainXMLFileName: 'main.xml',
  projectGoals: [
    {
      key: 'no-poverty',
      label: 'No poverty',
      color: 'red',
    },
    {
      key: 'zero-hunger',
      label: 'Zero hunger',
      color: 'orange',
    },
    {
      key: 'health',
      label: 'Good health and well-being',
      color: 'green',
    },
    {
      key: 'quality-education',
      label: 'Quality education',
      color: 'red',
    },
    {
      key: 'gender-equality',
      label: 'Gender equality',
      color: 'orange',
    },
    {
      key: 'clean-water',
      label: 'Clean water and sanitation',
      color: 'blue',
    },
    {
      key: 'energy',
      label: 'Affordable and clean energy',
      color: 'yellow',
    },
    {
      key: 'decent-growth',
      label: 'Decent work and economic growth',
      color: 'red',
    },
    {
      key: 'industry',
      label: 'Industry, innovation and infrastructure',
      color: 'orange',
    },
    {
      key: 'reduce-inequalities',
      label: 'Reduced inequalities',
      color: 'pink',
    },
    {
      key: 'sustainable-cities',
      label: 'Sustainable cities and communities',
      color: 'orange',
    },
    {
      key: 'responsible-consumption',
      label: 'Responsible consumption and production',
      color: 'orange',
    },
    {
      key: 'climate-action',
      label: 'Climate action',
      color: 'green',
    },
    {
      key: 'life-below-water',
      label: 'Life below water',
      color: 'blue',
    },
    {
      key: 'life-on-land',
      label: 'Life on land',
      color: 'green',
    },
    {
      key: 'peace',
      label: 'Peace, justice and strong institutions',
      color: 'blue',
    },
    {
      key: 'partnerships',
      label: 'Partnerships for the goals',
      color: 'linkedin',
    },
  ],
  jupyterGetContentEventName: 'get_content',
  jupyterCodeEventName: 'code',
  jupyterRunEvent: 'run',
  jupyterRunAllEvent: 'runAll',
  jupyterNewBlockEvent: 'newBlock',
  jupyterRemoveBlockEvent: 'removeBlock',
  jupyterContentType: 'ipynb',
  jupyterError: 'error',

  scratchLoaded: 'loaded',
  scratchGetContentEventName: 'get_content',
  scratchCodeEventName: 'code',
  scratchRunEvent: 'run',
  scratchRunAllEvent: 'runAll',
  scratchContentType: 'sb3',
  scratchError: 'error',
  scratchSave: 'save_code',
  scratchRequestCode: 'send_code',

  webEditorRenderFile: 'index.html',

  allowedImageUploadTypes: ['jpg', 'png', 'gif', 'ico', 'jpeg', 'webp', 'svg'],
  allowedImageUploadTypesString: "image/jpg, image/png, image/gif, image/ico, image/jpeg, image/svg",
  folderCreationFileName: '.keep',
  folderCreationFileType: 'keep',

  allowedFileCreateTypes: [
    'html',
    'css',
    'js',
    'json',
    'cpp',
    'c',
    'ino',
    'txt',
    'py',
    'ipnyb',
  ],
  extensionErrorMessage: 'The provided extension is invalid',
  nameErrorMessage: 'The provided name contains forbidden characters',
  surplusCharErrorMessage:
    'File name should contain only a name and a valid extension',
  folderNameErrorMessage:
    'Folder name cannot contain special characters or an extension',

  chatbotCategory: {
    ETSMaliZmaj: 'Best collaboration',
    DoDo: 'Best High School chatbot',
    'PP Papuk': 'Best Middle School chatbot',
    'Mindful crew': 'Best habit changer',
    GRUPIĆI: 'Best planetary impact',
    Bokar: 'Best feature',
  },
};
