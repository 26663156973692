import { ArduinoCompileDto, PcbType } from '@projectTypes/Terminal';
import BaseApiService from '@services/BaseApiService';

export default class ApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
    });
  }

  async compileProject(
    projectId: string,
    code: any,
    ota: boolean,
    pcbType = PcbType.v1,
  ) {
    const response = await this.axiosInstance.post<any>(`/build/${projectId}`, {
      code,
      ota,
      pcb_type: pcbType,
    });
    return response.data;
  }

  async validateCode(code: any, pcbType = PcbType.v1) {
    const response = await this.axiosInstance.post<any>(`/validate`, {
      code,
      pcb_type: pcbType,
    });
    return response.data;
  }

  async getCompileLogs(runId: string) {
    const response = await this.axiosInstance.get<ArduinoCompileDto>(
      `/logs/${runId}`,
    );
    return response.data;
  }

  async getBinary(projectId: string) {
    const response = await this.axiosInstance.get<any>(
      `/binary/project/${projectId}`,
    );
    return response.data;
  }

  async getStatic() {
    const response = await this.axiosInstance.get<any>(`/binary/static`);
    return response.data;
  }

  async getOta(version = PcbType.v1) {
    let url = `/binary/ota`;
    if (version === PcbType.v2) {
      url = `/binary/ota2`;
    } else if (version === PcbType.vidix) {
      url = `/binary/vidix`;
    }
    const response = await this.axiosInstance.get<any>(url);
    return response.data;
  }
}
