import { ActionRunDto, ActionValidateDto } from '@projectTypes/Action';
import ApiService from './_private/ApiService';

export default class ChatbotTerminalStrategy {
  apiService: ApiService;

  constructor(runServerHost: string, runServerPath: string) {
    this.apiService = new ApiService(runServerHost, runServerPath);
  }

  async validate(
    projectId: string,
    action: string,
    actionName: string,
    tracker?: string,
  ): Promise<ActionValidateDto> {
    const data = await this.apiService.validateAction(
      projectId,
      action,
      actionName,
      tracker,
    );
    return data;
  }

  async run(
    projectId: string,
    action: string,
    actionName: string,
    tracker?: string,
  ): Promise<ActionRunDto> {
    const data = await this.apiService.runAction(
      projectId,
      action,
      actionName,
      tracker,
    );
    return data;
  }
}
