import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';
import policyService from '@services/PolicyService';

const state = (store: Store) => store;

export const policySelector = createSelector(state, (s) => s.policy);

export const policyFileDataSelector = createSelector(state, () =>
  policyService.toFileData(),
);
