import BaseApiService from '@services/BaseApiService';
import { Template } from '@projectTypes/Project';

export default class TemplateApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/templates',
    });
  }

  async getProjectTemplates(): Promise<Template[]> {
    const response = await this.axiosInstance.get('/');
    return response.data;
  }
}
