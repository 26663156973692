import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';

const state = (store: Store) => store;

export const userSelector = createSelector(state, (s) => s.user);

export const publicUsernameSelector = createSelector(
  state,
  (s) => s.user.publicUsername,
);
