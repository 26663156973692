import TokenizerComponent from './TokenizerComponent';

export default class MitieTokenizer extends TokenizerComponent {
  constructor(props) {
    super(props);
    this.params.push({
      name: 'token_pattern',
      label: 'Token pattern',
      type: 'string',
      value: '',
      required: false,
    });
  }
}
