import fileService from '@services/FileService';
import projectService from '@services/ProjectService';
import terminalService from '@services/TerminalService';
import realTimeEditService from '@services/RealTimeEditService';
import userActionService from '@services/UserActionService';
import platformService from '@services/PlatformService';
import pythonService from '@services/PythonService';
import deviceService from '@services/DeviceService';
import commitService from '@/services/CommitService';
import { UpdateUser } from '@projectTypes/User';
import ApiService from './_private/ApiService';

export default class AuthService {
  apiService: ApiService;
  token: string;

  constructor(host: string, apiRoute: string) {
    this.apiService = new ApiService(host, apiRoute);
  }

  async isLoggedIn(token: string) {
    this.setToken(token);
    return this.apiService.verify();
  }

  async loginGuest() {
    const data = await this.apiService.loginGuest();
    if (data.token) {
      this.setToken(data.token);
      userActionService.trackAction('editor_login_guest');
      return data;
    }
    return null;
  }

  async loginToken(data: {
    platformToken?: string;
    username?: string;
    password?: string;
  }) {
    const response = await this.apiService.loginToken(data);
    if (response.token) {
      this.setToken(response.token);
      userActionService.trackAction('editor_login');
      return response;
    }
    return null;
  }

  async login(username: string, password: string) {
    const data = await this.apiService.login(username, password);
    if (data.token) {
      this.setToken(data.token);
      userActionService.trackAction('editor_login');
      return data;
    }
    return null;
  }

  async signup(username: string, password: string) {
    const data = await this.apiService.signup(username, password);
    if (data.token) {
      this.setToken(data.token);
      userActionService.trackAction('editor_signup');
      return data;
    }
    return null;
  }

  async updateUser(userData: UpdateUser) {
    return this.apiService.updateUser(userData);
  }

  async checkUsername(username: string) {
    return this.apiService.checkUsername(username);
  }

  logout() {
    this.setToken(null);
  }

  setToken(token) {
    this.apiService.setToken(token);
    fileService.setToken(token);
    projectService.setToken(token);
    terminalService.setToken(token);
    realTimeEditService.setToken(token);
    pythonService.setToken(token);
    platformService.setToken(token);
    deviceService.setToken(token);
    commitService.setToken(token);
  }
}
