import React, { useEffect, useState } from 'react';
import jupyterService from '@services/JupyterService';
import File from '@projectTypes/File';
import { Box, Spinner } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { jupyterStateSelector } from '@selectors';

interface JupyterProps {
  displayedFile: File;
  onChange: (value: string) => void;
}

const Jupyter = (props: JupyterProps) => {
  const { displayedFile, onChange } = props;
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const jupyterState = useSelector(jupyterStateSelector);

  const firstLoad = () => {
    setTimeout(() => {
      if (!isLoading) {
        return;
      }
      if (localStorage.getItem('jupyter-loaded')) {
        return;
      }
      setCounter(counter + 1);
      firstLoad();
    }, 15000);
  };

  useEffect(() => {
    firstLoad();
  }, []);

  const onLoad = () => {
    setIsLoading(false);
    localStorage.setItem('jupyter-loaded', 'true');
  };

  const onError = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    jupyterService.createEventListener(
      displayedFile,
      onChange,
      onLoad,
      onError,
    );
  }, [jupyterState]);

  return (
    <Box width="100%" height="100%" justifyContent="center" alignItems="center">
      {isLoading && (
        <Spinner size="xl" position="absolute" left="50%" top="50%" />
      )}
      <iframe
        id="jupyter-iframe"
        key={`${counter}-${jupyterState}`}
        width="100%"
        height="100%"
        src={jupyterService.getFilePath(displayedFile.id)}
        title="Jupyter editor"
      />
    </Box>
  );
};

export default Jupyter;
