import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';

import actionNameCreator from '@helpers/actionNameCreator';
import streamService from '@services/StreamService';
import { StreamConfig, StreamStore } from '@projectTypes/Stream';

const streamSliceInitialState: StreamStore = {
  config: null,
  isLoading: false,
};

const streamSlice = createSlice({
  name: 'stream',
  initialState: streamSliceInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setConfig(state, action: PayloadAction<StreamConfig>) {
      state.config = action.payload;
    },
  },
});

export const { setIsLoading, setConfig } = streamSlice.actions;
export default streamSlice.reducer;

const anc = actionNameCreator('STREAM');

export const getStreamStatus = createAsyncThunk(
  anc('getStreamStatus'),
  async (_: undefined, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const config = await streamService.getStreamConfig();
      dispatch(setConfig(config));
    } catch (err) {
      console.error(err);
      captureException(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  },
);
