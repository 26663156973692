export enum StemiBlobType {
  Library = 'SHIELD_LIBRARY',
  Firmware = 'SHIELD_FIRMWARE',
}

export default interface StemiBlob {
  id: string;
  type: StemiBlobType;
  version: string;
  files?: StemiBlobFile[];
}

export interface StemiBlobFile {
  id: string;
  path: string;
  content?: string;
  length: number;
  crc32: string;
}
