import { Observable, Subscriber } from 'rxjs';
import {
  asyncify,
  GeneratedTest,
  MAIN_FUNCTION,
  STDIO_NAMES,
} from '@stemi-dev/python-asyncer';

import { TestReturn } from '@projectTypes/Python';
import { ReceiveMessageType, SendMessageType } from './_private/workerTypes';
import ApiService from './_private/ApiService';

// eslint-disable-next-line no-unused-vars
type RunningListener = (v: boolean) => unknown;
type TestApiData = {
  projectId: string;
  code: string;
  testName: string;
  logs: { error: true; message: string } | { error: false; data: any[] };
  inputs: GeneratedTest;
};

class PythonWorker {
  public lastRunId?: string;
  private apiService: ApiService;

  private loaded = false;
  public loading = false;
  private worker: Worker;

  private subscriber: Subscriber<any>;
  public observable: Observable<ReceiveMessageType>;

  private _running = false;
  private waitingOnInput = false;

  private runningListeners: RunningListener[] = [];
  private cachedLoggingData: TestApiData[] = [];

  constructor() {
    this.apiService = new ApiService();

    this.observable = new Observable<ReceiveMessageType>((s) => {
      this.subscriber = s;
    });

    // if (typeof window !== 'undefined') {
    //   this.load();
    // }
  }

  public setToken(token: string) {
    this.apiService.setToken(token);
  }

  public registerRunningListener(listener: RunningListener) {
    this.runningListeners.push(listener);
  }

  public get isRunning() {
    return this._running;
  }

  private setRunning(val: boolean) {
    this._running = val;
    this.runningListeners.forEach((l) => l(val));
  }

  public async stop(full = false) {
    if (this.waitingOnInput) {
      this.send({ type: 'stdin', value: 'KILL_PROGRAM' });
      this.setRunning(false);
      if (full) {
        await new Promise((res) => setTimeout(res, 500));
      }

      return 'soft' as const;
    } else if (full) {
      await this.restart();
      this.setRunning(false);
      return 'hard' as const;
    }

    return undefined;
  }

  private async awaitMessage(time: number = 30 * 1000) {
    return new Promise<ReceiveMessageType>((res, rej) => {
      const timeout = setTimeout(() => {
        rej(
          new Error('PYTHON_MESSAGE_TIMEOUT: timeout, try reloading the page'),
        );
      }, time);

      const listener = (event: MessageEvent<ReceiveMessageType>) => {
        // FIXME: Use cookie for this so I can do this in prod
        if (process.env.NEXT_PUBLIC_ENABLE_WORKER_LOG === 'true') {
          // eslint-disable-next-line no-console
          console.log('[worker]: Received one time message', event.data);
        }

        this.worker.removeEventListener('message', listener);
        clearTimeout(timeout);
        res(event.data);
      };

      this.worker.addEventListener('message', listener);
    });
  }

  private registerMessageListener() {
    this.worker.onmessage = (ev: MessageEvent<ReceiveMessageType>) => {
      const { data } = ev;

      if (process.env.NEXT_PUBLIC_ENABLE_WORKER_LOG === 'true') {
        // eslint-disable-next-line no-console
        console.log('[worker]: Received message', data);
      }

      if (data.type === 'ready') {
        this.loaded = true;
      } else {
        if (data.type === 'final' || data.type === 'error') {
          this.setRunning(false);
        }

        if (data.type === 'input') {
          this.waitingOnInput = true;
        }

        if (this.subscriber) {
          this.subscriber.next(data);
        }
      }
    };
  }

  private newWorker = () => {
    const worker = new Worker('/worker/index.js');
    return worker;
  };

  public async load(): Promise<boolean> {
    if (this.loaded) {
      return true;
    }

    if (this.loading) {
      return new Promise((res, rej) => {
        (async () => {
          const timeout = setTimeout(() => rej(new Error('Timeout')), 30000);
          // eslint-disable-next-line no-constant-condition
          while (true) {
            if (this.loaded) {
              clearTimeout(timeout);
              res(true);
            }

            // eslint-disable-next-line no-await-in-loop
            await new Promise((r) => setTimeout(r, 100));
          }
        })();
      });
    }

    this.loading = true;

    this.worker = this.newWorker();
    this.registerMessageListener();

    return this.warmup()
      .then((val) => {
        this.loading = false;
        return val;
      })
      .catch((err: unknown) => {
        this.loading = false;
        if (err instanceof Error) {
          throw err;
        } else {
          throw new Error(String(err));
        }
      });
  }

  public sendInput(value: string) {
    this.send({ type: 'stdin', value });
    this.waitingOnInput = false;
  }

  private send(data: SendMessageType) {
    if (process.env.NEXT_PUBLIC_ENABLE_WORKER_LOG === 'true') {
      // eslint-disable-next-line no-console
      console.log('[worker]: Sending message to worker', data);
    }

    this.worker.postMessage(data);
  }

  private async warmup() {
    this.send({
      type: 'warmup',
      config: { stdio: STDIO_NAMES, main: MAIN_FUNCTION },
    });

    for (let i = 0; i < 2; i++) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const message = await this.awaitMessage(30 * 1000);
        if (message.type === 'ready') {
          this.loaded = true;
          return true as const;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        // eslint-disable-next-line no-continue
        continue;
      }
    }

    throw new Error('Error loading python: timeout, try reloading the page');
  }

  public async restart() {
    this.loaded = false;
    this.worker.terminate();
    this.worker = this.newWorker();
    this.registerMessageListener();

    return this.warmup();
  }

  public async run(code: string, projectId: string) {
    if (!this.loaded) {
      throw new Error('Worker is not loaded');
    }

    if (!this.worker.onmessage) {
      this.registerMessageListener();
    }

    // TODO: dangling promise
    this.log('run', projectId, { code, inputs: '', logs: '' });

    const formattedCode = asyncify(code, {
      env: 'browser',
      indents: 4,
      maxIterations: 10000,
    });

    this.setRunning(true);
    this.send({ type: 'run', code: formattedCode });

    console.log(formattedCode);

    return formattedCode;
  }

  public logTestData(projectId: string, results: TestReturn[], code: string) {
    this.cachedLoggingData.push({
      projectId,
      testName: 'CODE_MATCH',
      inputs: undefined,
      logs: { error: false, data: results },
      code,
    });
  }

  public async runTest(
    code: string,
    formattedCode: string,
    inputs: GeneratedTest,
    testName: string,
    projectId: string,
  ) {
    if (!this.loaded) {
      throw new Error('Worker is not loaded');
    }

    const logger = (logs: any) => {
      this.cachedLoggingData.push({ projectId, testName, inputs, logs, code });
      // return this.log('test', projectId, { code, inputs, logs });
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.worker.onmessage = undefined;

    this.setRunning(true);
    this.send({ type: 'run', code: formattedCode });
    let message: ReceiveMessageType;
    try {
      message = await this.awaitMessage(3 * 1000);
    } catch (err: unknown) {
      if (
        err instanceof Error &&
        err.message.startsWith('PYTHON_MESSAGE_TIMEOUT')
      ) {
        logger({ error: true, message: 'timeout' });
        return 'TIMEOUT';
      }
    } finally {
      this.setRunning(false);
    }
    this.registerMessageListener();

    switch (message.type) {
      case 'error':
        logger({ error: true, message: message.error });
        throw new Error(message.error);
      case 'final':
        logger({ error: false, data: JSON.parse(message.results) });
        return JSON.parse(message.results) as TestReturn[];
      default:
        logger({ error: true, message: 'Unknown message type' });
        throw new Error('Unknown message type');
    }
  }

  public async flushTestData() {
    if (this.cachedLoggingData.length === 0) {
      return;
    }

    const { projectId, code } = this.cachedLoggingData[0];
    await this.log('test', projectId, {
      code,
      inputs: this.cachedLoggingData.map((a) => ({
        name: a.testName,
        data: a.inputs,
      })),
      result: this.cachedLoggingData.map((data) => {
        if (data.logs.error || (data.logs as any)?.data?.error) {
          return `-1/10`;
        }

        return `${(data.logs as any).data.filter((a) => a.test_pass).length}/${
          (data.logs as any).data.length
        }`;
      }),
      logs: this.cachedLoggingData.map((a) => ({
        name: a.testName,
        data: a.logs,
      })),
    }).finally(() => {
      this.cachedLoggingData = [];
    });
  }

  public async getTests(platformId: string, templateSlug: string) {
    const data = await this.apiService.getTestResults(platformId, templateSlug);
    return data;
  }

  private async log(
    type: 'test' | 'run',
    projectId: string,
    data: { code: string; inputs: any; logs: any; result?: string[] },
  ) {
    const res = await this.apiService.sendResult({
      type,
      projectId,
      code: data.code,
      inputs: JSON.stringify(data.inputs),
      logs: JSON.stringify(data.logs),
      ...(data.result && { result: JSON.stringify(data.result) }),
    });
    this.lastRunId = res.id;
  }
}

export default PythonWorker;
