import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';

const state = (store: Store) => store;

export const userFilePositionsSelector = createSelector(
  state,
  (s) => s.realTimeEdit.userFilePositions,
);
