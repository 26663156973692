export enum ErrorType {
  UserError = 'User Error',
  ServerError = 'Server Error',
  UnknownError = 'Unknown Error',
  TemplateNotFound = 'Template not found',
}

export interface Error {
  errorType: ErrorType | string;
  message: string;
}

export interface Info {
  title: string;
  text: string;
  status?: string;
}

export interface ErrorStore {
  errors: Error[];
  fileError: string | null;
  projectError: string | null;
  userError: string | null;
  terminalError: string | null;
  info: Info | null;
}

export interface FileContentError {
  message: string;
  name: string;
  reason: string;
  column: number;
  line: number;
  position: number;
}
