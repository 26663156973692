import React from 'react';
import useDispatch from '@hooks/useDispatch';
import { Stack } from '@chakra-ui/react';

import {
  removeComponent as removeComponentAction,
  setComponentParamValue,
} from '@slices/policySlices';
import { PolicyData } from '@projectTypes/Policy';
import PipelineComponent from './Policy';

interface PipelineComponentContainerProps {
  pipelineComponents: PolicyData[];
}

const PipelineComponentContainer = (props: PipelineComponentContainerProps) => {
  const { pipelineComponents } = props;
  const dispatch = useDispatch();

  const removeComponent = (index: number) => {
    dispatch(removeComponentAction(index));
  };

  const setValue = (index: number, paramName: string, value: any) => {
    dispatch(
      setComponentParamValue({
        paramName,
        value,
        index,
      }),
    );
  };

  return (
    <Stack spacing="10px">
      {pipelineComponents.map((component, index) => (
        <PipelineComponent
          key={component.id}
          component={component}
          removeComponent={() => removeComponent(index)}
          setValue={(paramName: string, value: any) =>
            setValue(index, paramName, value)
          }
        />
      ))}
    </Stack>
  );
};

export default PipelineComponentContainer;
