const colorList = [
  '#c5225e',
  '#3d66c6',
  '#54c662',
  '#d633d8',
  '#c0721f',
  '#807fca',
  '#26391c',
  '#000000',
  '#6a4326',
  '#9ea9c0',
];

const randomColor = (index?: number) => {
  if (index !== undefined) {
    return colorList[index % colorList.length];
  }
  const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  if (color.length === 7) {
    return color;
  }
  return randomColor();
};

export default randomColor;
