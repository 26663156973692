/* eslint-disable @typescript-eslint/no-empty-function */
import { Observable, Subscriber } from 'rxjs';
import { TestData } from './Python';

export interface TerminalStore {
  messages: Message[];
  isLoading: boolean;
  isBooting: boolean;
  showTerminal: boolean;
  showChat: boolean;
  isWaitingResponse: boolean;
  isWaitingForInput: boolean;
  minimizeTerminal: boolean;
  removeIsWaitingResponseOnNextMessage: boolean;
  lastCompileId: string | null;
  espDevice: any;
  shouldCompileModal: boolean;
  testResults: TestData[] | undefined;
  isDesktopLive: boolean;
  isHexapodReady: boolean;
  showUsbUploadButton: boolean;
  pcbType: PcbType;
}

export enum MessageDirection {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}

export interface Message {
  action?: {
    value: any;
    type: string;
  };
  content?: string;
  image?: string;
  error?: string;
  charts?: Chart[];
  link?: string;
  input?: string;
  direction: MessageDirection;
  date: number;
  style?: 'big';
  debug?: any;
  buttons?: { title: string; payload: string }[];
  join?: boolean;
}

export interface ReceivedMessage {
  text?: string;
  image?: string;
  error?: string;
  link?: string;
  charts?: Chart[];
  buttons?: { title: string; payload: string }[];

  type?: 'input' | 'inputResponse' | 'exit';
  input?: string;
  style?: 'big';
  debug?: any;
  join?: boolean;
}

export abstract class TerminalStrategy {
  observable: Observable<ReceivedMessage>;
  subscriber: Subscriber<ReceivedMessage>;

  setToken(_token: string) {}
  async sendMessage(_message: string) {}
  async runFile(_projectId: string, _fileId: string, _code: string) {}
  async runProject(_projectId: string) {}
  async closeTerminal() {}
  async closeChat() {}
  async trainProject(_projectId: string): Promise<string> {
    throw new Error('Not implemented');
  }
  async bootChat(_trainingId: string, _from: any): Promise<BootChatDto> {
    throw new Error('Not implemented');
  }
  async getLastRunId(_trainingId: string): Promise<string | null> {
    throw new Error('Not implemented');
  }
  async compileProject(
    _projectId: string,
    _code: string,
    _ota = false,
    _pcbType = PcbType.v1,
    _skipValidation = false,
  ): Promise<any> {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async shareProjectTrainingId(_projectId: string, _publish?: boolean) {}

  async registerMessageReceiver() {
    if (!this.observable) {
      this.observable = new Observable((subscriber) => {
        this.subscriber = subscriber;
      });
    }
    return this.observable;
  }

  answerMessage(message: ReceivedMessage) {
    if (this.subscriber) {
      this.subscriber.next(message);
    }
  }

  exitPython() {
    this.subscriber.next({ type: 'exit' });
  }
}

export interface RunProjectDto {
  output: string;
  time: number;
}

export interface TrainChatDto {
  id: string;
  projectId: string;
  taskId: string;
  state: any;
  output: string;
  progress: number;
  createdAt: Date;
  updatedAt: Date;
  error?: string;
}

interface Log {
  data: string;
  time: number;
}

export enum ServerTaskState {
  queued = 'queued',
  started = 'started',
  done = 'done',
  stopped = 'stopped',
  errored = 'errored',
}

export enum MinifiedServerTaskState {
  queued = 'queued',
  running = 'running',
  done = 'done',
  error = 'error',
}

export interface TrainingLogs {
  state: ServerTaskState;
  logs: Log[];
  error?: string;
}
export interface BootChatDto {
  id: string;
  trainingId: string;
  taskId: string;
  state: any;
  port: number;
  host: string;
  createdAt: Date;
  updatedAt: Date;
  bootMessage?: string;
  error?: string;
}

export interface ChatMessageDto {
  text?: string;
  image?: string;
}

export interface Point {
  x: number;
  y: number;
  r: number;
}

export interface Chart {
  name: string;
  url: string;
}

export interface ArduinoCompileDto {
  id: string;
  code: string;
  status: MinifiedServerTaskState;
  logs: string;
  createdAt: string;
  error?: any;
}

export enum TerminalStrategyType {
  Chatbot,
  Arduino,
  Python,
  EmbeddedPython,
}

export enum UploadType {
  USB,
  Mobile,
}

export enum ArduinoLogType {
  stdout = 'stdout',
  stderr = 'stderr',
}

export enum RunFrom {
  editor = 'editor',
  editorChat = 'editorChat',
  publicChat = 'publicChat',
}

export enum PcbType {
  v1 = 'v1',
  v2 = 'v2',
  vidix = 'vidix',
}
