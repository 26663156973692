import { StemiBlobType } from '@/types/StemiBlobs';
import ApiService from './_private/ApiService';

export default class StemiBlobsService {
  apiService: ApiService;

  constructor(host: string, apiRoute: string) {
    this.apiService = new ApiService(host, apiRoute);
  }

  setToken(token: string) {
    this.apiService.setToken(token);
  }

  async getVersions() {
    const blobs = await this.apiService.listAll();
    const libVersions = [];
    const firmwareVersions = [];

    blobs.forEach((blob) => {
      if (blob.type === StemiBlobType.Firmware) {
        firmwareVersions.push(blob.version);
      } else if (blob.type === StemiBlobType.Library) {
        libVersions.push(blob.version);
      }
    });

    libVersions.sort().reverse();
    firmwareVersions.sort().reverse();

    return { libVersions, firmwareVersions };
  }
}
