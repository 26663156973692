import Mqtt from 'async-mqtt';
import {
  OnCloseCallback,
  OnConnectCallback,
  OnDisconnectCallback,
  OnErrorCallback,
  OnMessageCallback,
  OnPacketCallback,
} from './types';

import ApiService from './_private/ApiService';

// eslint-disable-next-line no-unused-vars
type MsgCb = (topic: string, message: string) => void;

export default class MqttService {
  private apiService: ApiService;
  private host = '';
  private fastifyHost = '127.0.0.1:6789';
  private mqttClient: Mqtt.AsyncMqttClient;
  private topicMap: Map<string, MsgCb[]>;

  constructor(host: string, fastifyHost: string) {
    this.host = host;
    this.apiService = new ApiService(fastifyHost, '/clients');
    this.fastifyHost = fastifyHost;
    this.topicMap = new Map();
  }

  connect() {
    this.mqttClient = Mqtt.connect(this.host, {
      username: 'admin-web-editor',
      password: 'hahaha-yes-security-xD',
    });
    this.mqttClient.on('message', this.onMessage);
  }

  on = (
    event:
      | 'connect'
      | 'message'
      | 'packetsend'
      | 'packetreceive'
      | 'disconnect'
      | 'error'
      | 'close'
      | 'end'
      | 'reconnect'
      | 'offline'
      | 'outgoingEmpty',
    cb:
      | OnConnectCallback
      | OnMessageCallback
      | OnPacketCallback
      | OnDisconnectCallback
      | OnErrorCallback
      | OnCloseCallback,
  ) => {
    this.mqttClient.on(event, cb);
  };

  subscribe = (topic: string, fn: MsgCb, singleCb = false) => {
    if (!this.mqttClient) {
      this.connect();
    }
    if (!this.topicMap.has(topic) || singleCb) {
      this.topicMap.set(topic, [fn]);
    } else {
      this.topicMap.set(topic, [...this.topicMap.get(topic), fn]);
    }
    this.mqttClient.subscribe(topic);
  };

  unSubscribe = (topic: string) => {
    this.topicMap.delete(topic);
  };

  unSubscribeFromDevices = () => {
    this.topicMap.forEach((v, k) => {
      if (k.includes('device')) {
        this.topicMap.delete(k);
        console.log(k);
      }
    });
    console.log(this.topicMap);
  };

  onMessage = (topic: string, message: string) => {
    if (this.topicMap.has(topic)) {
      this.topicMap.get(topic).forEach((fn) => fn(topic, message));
    }
  };

  publish = (topic: string, msg: string) => {
    if (!this.mqttClient) {
      this.connect();
    }
    this.mqttClient.publish(topic, msg);
  };

  getOnlineDevicesInfo = async (data: string[]) => {
    const info = await this.apiService.getLiveClients(data);
    return info;
  };
}
