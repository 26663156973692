import faker from 'faker';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { captureException } from '@sentry/nextjs';
import {
  asyncify,
  cleanup,
  generateTest,
  STDIO_NAMES,
  tracebackFormatter,
} from '@stemi-dev/python-asyncer';

import pythonService from '@services/PythonService';
import { TerminalStrategy } from '@projectTypes/Terminal';
import { safeParseJSON } from '@helpers/json';
import { padLeft } from '@helpers/padLeft';
import { TestData, TestReturn } from '@projectTypes/Python';
import { getAffirmationMessage } from './data';

export default class PythonTerminalStrategy extends TerminalStrategy {
  tmpInputObs: Observable<string>;
  tmpInputSub: Subscriber<string>;

  private pyodideSubscription: Subscription;

  async loadPyodide() {
    return pythonService.load();
  }

  registerInputs() {
    if (!this.tmpInputObs) {
      this.tmpInputObs = new Observable((sub) => {
        this.tmpInputSub = sub;
      });
    }

    return this.tmpInputObs;
  }

  async sendInput(message: string): Promise<void> {
    this.tmpInputSub.next(message);
  }

  async requestMessage(text: string) {
    this.subscriber.next({ type: 'input', input: text });

    return new Promise<string>((res, rej) => {
      const t = setTimeout(() => {
        rej(new Error('timeout'));
      }, 60 * 60 * 1000);

      const sub = this.tmpInputObs.subscribe((message) => {
        sub.unsubscribe();
        clearTimeout(t);
        res(message);
      });
    });
  }

  async closeTerminal(): Promise<void> {
    await pythonService.stop();
  }

  async killTerminal() {
    return pythonService.stop(true);
  }

  async testFile(
    code: string,
    testContent: string,
    projectId: string,
    language: 'en' | 'hr',
  ) {
    if (pythonService.isRunning) {
      this.exitPython();
      this.answerMessage({ text: 'Restarting...' });
      await pythonService.stop(true);
    }

    this.answerMessage({ text: 'Testing...' });

    const results: TestData[] = [];

    const tmp = JSON.parse(testContent);
    const tests = tmp.tests.map((t) => ({
      variables: tmp.variables,
      test: t.steps,
      defined: tmp.defined,
    }));

    let allPassed = true;
    if (tmp.matches) {
      const lines = cleanup(code);
      const matchResults: TestReturn[] = [];

      const linesWithoutComment = lines.filter((l) => {
        const lineWithoutSpaces = l.replaceAll(' ', '');
        return lineWithoutSpaces[0] != '#';
      });

      const codeWithoutNewlines = code
        .split('\n')
        .filter((l) => {
          const lineWithoutSpaces = l.replaceAll(' ', '');
          return lineWithoutSpaces[0] != '#';
        })
        .join('');

      tmp.matches.forEach(
        (match: { name: string; description?: string; match: string }) => {
          const pass =
            linesWithoutComment.some((l) =>
              new RegExp(match.match.slice(1, -1)).test(l),
            ) || !!codeWithoutNewlines.match(match.match.slice(1, -1));

          matchResults.push({
            index: 0,
            test_pass: pass,
            comment: `${match.name} -> ${
              match.description || `Regex match -> ${match.match}`
            }`,
            type: 'fullMatch',
            verbose: null,
          });
        },
      );

      results.push({
        name: 'Code match',
        description: 'Code match to check if you have specific lines/methods',
        results: matchResults,
      });

      const count = matchResults.length;
      const passCount = count - matchResults.filter((r) => !r.test_pass).length;

      pythonService.logTestData(projectId, matchResults, code);

      if (passCount !== count) {
        allPassed = false;
      }

      this.answerMessage({
        text: `Test [CODE_MATCH]: ${passCount}/${count} passed ${
          passCount === count ? '✅' : '❌'
        }`,
      });
    }

    // eslint-disable-next-line no-restricted-syntax
    // eslint-disable-next-line guard-for-in
    for (let i = 0; i < tests.length; i++) {
      let value;
      try {
        const inputs = generateTest(tests[i], faker, true);
        const stdioConfig = {
          stdioInput: 'test_input',
          stdioOutput: 'test_print',
        };

        const formattedCode = asyncify(
          code,
          {
            maxIterations: 10000,
            env: 'tests',
            indents: 4,
            ...stdioConfig,
          },
          inputs,
        );

        // eslint-disable-next-line no-await-in-loop
        value = await pythonService.runTest(
          code,
          formattedCode,
          inputs,
          tmp.tests[i].name,
          projectId,
        );

        if (value?.error) {
          this.answerMessage({
            text: `Test [${tmp.tests[i].name}]: Error occurred while running the code ❌ 😵`,
          });

          this.answerMessage({
            error: tracebackFormatter(value.error, formattedCode, stdioConfig),
          });

          allPassed = false;
          results.push({
            name: tmp.tests[i].name,
            description: tmp.tests[i].description,
            failed: true,
            results: [
              {
                index: 0,
                comment: 'Error occurred while running the code ❌ 😵',
                test_pass: false,
                type: 'defined',
                verbose: null,
              },
            ],
          });

          // eslint-disable-next-line no-continue
          continue;
        }
      } catch (err) {
        this.answerMessage({
          text: `Test [${tmp.tests[i].name}]: Error occurred while running the code ❌ 😖`,
        });

        allPassed = false;
        results.push({
          name: tmp.tests[i].name,
          description: tmp.tests[i].description,
          failed: true,
          results: [
            {
              index: 0,
              comment: 'Error occurred while running the code',
              test_pass: false,
              type: 'defined',
              verbose: null,
            },
          ],
        });

        captureException(err);

        // eslint-disable-next-line no-continue
        continue;
      }

      if (value === 'TIMEOUT') {
        this.answerMessage({
          text: `Test [${tmp.tests[i].name}]: TIMED OUT ❌ ⌚`,
        });

        allPassed = false;
        results.push({
          name: tmp.tests[i].name,
          description: tmp.tests[i].description,
          failed: true,
          results: [
            {
              index: 0,
              comment: 'TEST TIMED OUT',
              test_pass: false,
              type: 'defined',
              verbose: null,
            },
          ],
        });

        // eslint-disable-next-line no-continue
        continue;
      }

      const failed = value.filter((v) => !v.test_pass);

      const count = value.length;
      const passCount = count - failed.length;

      this.answerMessage({
        text: `Test [${tmp.tests[i].name}]: ${passCount}/${count} passed ${
          passCount === count ? '✅' : '❌'
        }`,
      });

      const outputs = tests[i].test.filter((t) => !!t.out);
      const verbose = false;

      if (failed.length > 0) {
        allPassed = false;

        // eslint-disable-next-line no-restricted-syntax
        for (const test of failed) {
          if (test.type === 'match') {
            const comment = outputs[test.index]?.description;
            let text = `Error: ${comment || 'unexpected output'}`;
            if (verbose) {
              text += `(${test.comment})`;
            }

            if (text) {
              this.answerMessage({ text });
            }
          } else {
            this.answerMessage({
              text: `Error: ${test.comment}`,
            });
          }
        }

        // if (i !== 0) {
        //   this.answerMessage({ text: '' });
        // }
      }

      results.push({
        name: tmp.tests[i].name,
        description: tmp.tests[i].description,
        results: value,
      });
    }

    this.answerMessage({ text: '' });
    this.answerMessage({
      text: getAffirmationMessage(allPassed ? 'pass' : 'fail', language),
      style: 'big',
    });

    await pythonService.flushTestData();

    if (results.length > 0) results[0].runId = pythonService.lastRunId;

    return results;
  }

  async runFile(
    projectId: string,
    _fileId: string,
    code: string,
  ): Promise<void> {
    const time = Date.now();

    if (pythonService.loading) {
      this.answerMessage({
        error: 'Python not loaded',
      });
      return;
    }

    if (pythonService.isRunning) {
      this.exitPython();
      this.answerMessage({ text: 'Restarting...' });
      await pythonService.stop(true);
    }

    this.answerMessage({ text: 'Starting...' });

    if (this.pyodideSubscription) {
      this.pyodideSubscription.unsubscribe();
    }

    const formattedCode = await pythonService.run(code, projectId);

    this.pyodideSubscription = pythonService.observable.subscribe(
      // eslint-disable-next-line consistent-return
      async (data) => {
        if (data.type === 'input') {
          const out = await this.requestMessage(data.text);
          pythonService.sendInput(out);
        } else {
          switch (data.type) {
            case 'ready':
              return this.answerMessage({ text: 'ready' });
            case 'error':
              return this.answerMessage({
                error: tracebackFormatter(data.error, formattedCode, {
                  stdioInput: STDIO_NAMES.input,
                  stdioOutput: STDIO_NAMES.print,
                }),
              });
            case 'final':
              const duration = (Date.now() - time) / 1000;
              const s = Math.floor((duration % 60) * 1000) / 1000;
              const m = Math.floor(duration / 60);

              if (data.results === 'KILL_PROGRAM') {
                // eslint-disable-next-line consistent-return
                return;
              }

              const type =
                data.results === 'KILL_PROGRAM' ? 'killed' : 'finished';
              return this.answerMessage({
                text: `>> Program ${type}, running ${padLeft(m)}:${padLeft(s)}`,
              });
            case 'output':
              return this.answerMessage({
                text: safeParseJSON(data.data, [])
                  .map((item) => {
                    if (Array.isArray(item) || typeof item === 'object') {
                      const out = JSON.stringify(item, null, 2);
                      if (out.split('\n').length === 3) {
                        const start = out[0];
                        const end = out[out.length - 1];

                        return `${start} ${out.split('\n')[1].trim()} ${end}`;
                      }

                      if (out.length > 5000) {
                        return `${out.substring(0, 1000)}...`;
                      }

                      return out;
                    }

                    return item;
                  })
                  .join(', '),
              });
            default:
              throw new Error('Unknown type returned from Worker');
          }
        }
      },
    );
  }
}
