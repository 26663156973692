import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';
import { reverseCommitsSelector } from './commitSelectors';

const state = (store: Store) => store;

export const deviceSelector = createSelector(state, (s) => s.device);

export const sortedDevicesSelector = createSelector(state, (s) => {
  return [...s.device.devices].sort((a, b) => {
    if (s.device.connectedDevice) {
      if (a.id === s.device.connectedDevice.id) return -1;
      if (b.id === s.device.connectedDevice.id) return 1;
    }
    if (a.lastUsedById == null && b.lastUsedById != null) return -1;
    if (a.lastUsedById != null && b.lastUsedById == null) return 1;
    if (a.mac != null && b.mac == null) return -1;
    if (a.mac == null && b.mac != null) return 1;
    return 0;
  });
});

export const connectedDeviceSelector = createSelector(
  state,
  (s) => s.device.connectedDevice,
);

export const deviceHasNewVersionSelector = createSelector(state, (s) => {
  if (s.device.isLoadingVersions || s.device.isLoadingVersionsFromDevice) {
    return false;
  }
  if (
    s.device.connectedDeviceVersions &&
    s.device.connectedDeviceVersions.libVersion !== s.device.libVersions[0]
  ) {
    return true;
  }
  if (
    s.device.connectedDeviceVersions &&
    s.device.connectedDeviceVersions.firmwareVersion !==
      s.device.firmwareVersions[0]
  ) {
    return true;
  }
  return false;
});

export const commitIdToRunAgainSelector = createSelector(
  state,
  reverseCommitsSelector,
  (s, commits) => {
    const commitIds = commits.map((c) => c.id);
    if (
      s.device.connectedDevice &&
      s.device.connectedDevice.currentCommitId &&
      commitIds.includes(s.device.connectedDevice.currentCommitId)
    ) {
      return s.device.connectedDevice.currentCommitId;
    }
    if (commitIds.length > 0) {
      return commitIds[0];
    }
    return undefined;
  },
);
