export default interface Project {
  id: string;
  name: string;
  user: string;
  createdAt: Date;
  bootMessage?: string;
  updatedAt: Date;
  lastFileUpdatedAt: Date;
  isChanged: boolean;
  templateId: string;
  type: ProjectType;
  isGroupProject: boolean;
  sectionName: string;
  sectionId?: string;
  info?: string;
  infoEn?: string;
  botLanguage?: 'english' | 'croatian';
  finalProjectType?: string;
  namespace?: string;
  attributes: {
    database: boolean;
    is_group: boolean;
    is_sherable: boolean;
  };
  runId?: string;
  yearId?: string;
}

export type InfoPartOfProject = Pick<
  Project,
  'info' | 'infoEn' | 'finalProjectType' | 'botLanguage'
>;

export interface Template {
  id: string;
  name: string;
  slug: string;
  type: ProjectType;
  sandbox: boolean;
}

export enum ProjectType {
  RASA = 'RASA',
  RASA_PYTHON = 'RASA_PYTHON',
  PYTHON = 'PYTHON',
  ANSWERS = 'ANSWERS',
  PLATFORMIO = 'PLATFORMIO',
  WEB = 'WEB',
  SCRATCH = 'SCRATCH',
}

export enum ProjectNamespace {
  PYTHON = 'PYTHON',
  EMBED_PYTHON = 'EMBED_PYTHON',
}

export interface ProjectStore {
  selectedProjectId: string | null;
  showProjectDiff: boolean;
  projects: Project[];
  templates: Template[];
  showProjectShare: boolean;
  isLoading: boolean;
  loadingCounter: number;
  error: string | null;
  showProjectInfo: boolean;
  isNewProjectInfo: boolean;
  chatInfo: ProjectInfo | null;
  lastOpenedProject?: string | undefined;
  hasMultiYear: boolean;
  showProjectModal: boolean;
  TargetFile: string;
}

export interface ProjectDto {
  id: string;
  name: string;
  user: string;
  createdAt: Date;
  updatedAt: Date;
  lastFileUpdatedAt: Date;
  bootMessage?: string;
  isChanged: boolean;
  templateId: string;
  type: ProjectType;
  isGroupProject: boolean;
  sectionName: string;
  attributes: {
    database: boolean;
    is_group: boolean;
    is_sherable: boolean;
  };
  info?: string;
}

export interface ProjectInfoDto {
  id: string;
  name: string;
  isGroupProject: boolean;
  isHighlight: boolean;
  info: string;
  likes: number;
  type: ProjectType;
  attributes: any;
  schoolId?: string;
  schoolName?: string;
  botLanguage?: string;
  finalProjectType?: any;
  infoEn?: string;
  year?: { id: number };
  keepLive?: boolean;
}

export type ProjectInfo = ProjectInfoDto;
