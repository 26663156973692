import { RunFrom, TerminalStrategy } from '@projectTypes/Terminal';
import MqttService from '@services/MqttService';

export default class EmbeddedPythonTerminalStrategy extends TerminalStrategy {
  private deviceId: string;
  private projectId: string;

  setProjectId(projectId: string) {
    this.projectId = projectId;
  }

  setDeviceId(deviceId?: string, projectId?: string) {
    if (!deviceId) return;
    MqttService.unSubscribe(`device/${this.deviceId}/log`);

    this.deviceId = deviceId;
    this.projectId = projectId;

    MqttService.subscribe(
      `device/${this.deviceId}/log`,
      (topic, message) => {
        const msg = JSON.parse(message.toString());
        if (msg.projectId == null || msg.projectId === this.projectId) {
          if (msg.type === 'error')
            this.answerMessage({
              error: msg.content.toString(),
            });
          else
            this.answerMessage({
              text: msg.content.toString(),
            });
        }
      },
      true,
    );
  }

  async bootChat(_trainingId: string, _from: RunFrom) {
    this.answerMessage({ text: 'Connecting to device...' });

    MqttService.on('error', (error) => {
      this.answerMessage({ error: error?.message || error.toString() });
    });

    MqttService.subscribe(
      `device/${this.deviceId}/log`,
      (topic, message) => {
        const msg = JSON.parse(message.toString());
        if (msg.projectId == null || msg.projectId === this.projectId) {
          if (msg.type === 'error')
            this.answerMessage({
              error: msg.content.toString(),
            });
          else
            this.answerMessage({
              text: msg.content.toString(),
            });
        }
      },
      true,
    );

    return '' as any;
  }

  async closeChat() {
    MqttService.unSubscribe(`device/${this.deviceId}/log`);
  }

  async closeTerminal() {
    this.closeChat();
  }
}
