import React from 'react';
import dynamic from 'next/dynamic';
import { Spinner } from '@chakra-ui/react';

import { BlocklyEditorProps } from './BlocklyEditor';

const BlocklyEditorComponent = dynamic(() => import('./BlocklyEditor'), {
  loading: () => <Spinner />,
  ssr: false,
});

const BlocklyEditor = (props: BlocklyEditorProps) => {
  const { displayedFile, onChange } = props;
  return (
    <BlocklyEditorComponent displayedFile={displayedFile} onChange={onChange} />
  );
};

export default BlocklyEditor;
