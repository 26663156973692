import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';
import { displayedFileSelector } from '.';

const state = (store: Store) => store;

export const commitDiffLeft = createSelector(
  state,
  displayedFileSelector,
  (s, displayedFile) => {
    let info = '';
    let content = '';
    if (s.commit.showDiff) {
      const currentCommit = s.commit.commits[s.commit.diffIndex];
      const commitedFile = currentCommit?.commitedFiles.find(
        (f) => f.fileId === displayedFile.id,
      );
      content = s.commit.isLoading
        ? 'commit is loading'
        : commitedFile?.fileContent || '';
      const username =
        currentCommit?.userId === s.user.id ? 'You' : 'Someone else';
      const message =
        currentCommit?.message.length > 0
          ? currentCommit.message
          : '[no message]';

      info =
        s.commit.diffIndex < 0
          ? ''
          : s.commit.isLoading
          ? 'loading...'
          : `${username}, ${new Date(
              currentCommit?.createdAt,
            ).toLocaleString()} - #${s.commit.diffIndex + 1}, ${message}`;
    } else if (s.project.showProjectDiff || s.file.showFileDiff) {
      info = 'Stemi, template';
      content = displayedFile.templateContent;
    }
    return { info, content };
  },
);

export const commitDiffRight = createSelector(
  state,
  displayedFileSelector,
  (s, displayedFile) => {
    let info = '';
    let content = '';
    let isWorkingDraft = false;

    if (
      s.commit.diffWithWorkingDraft ||
      s.commit.diffIndex === s.commit.commits.length - 1 ||
      (!s.commit.showDiff && (s.file.showFileDiff || s.project.showProjectDiff))
    ) {
      content = displayedFile.newContent;
      info = 'You, working draft';
      isWorkingDraft = true;
    } else if (s.commit.diffIndex < s.commit.commits.length - 1) {
      const currentCommit = s.commit.commits[s.commit.diffIndex + 1];
      const commitedFile = currentCommit?.commitedFiles.find(
        (f) => f.fileId === displayedFile.id,
      );
      content = s.commit.isLoading
        ? 'commit is loading'
        : commitedFile?.fileContent || '';
      const username =
        currentCommit.userId === s.user.id ? 'You' : 'Someone else';
      const message =
        currentCommit.message.length > 0
          ? currentCommit.message
          : '[no message]';
      info = s.commit.isLoading
        ? 'loading...'
        : `${username}, ${new Date(
            currentCommit?.createdAt,
          ).toLocaleString()} - #${s.commit.diffIndex + 2}, ${message}`;
    }
    return { info, content, isWorkingDraft };
  },
);
