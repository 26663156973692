import { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { errorSelector } from '@selectors';

const useErrorLogs = () => {
  const errorState = useSelector(errorSelector);
  const [lastDisplayedCount, setLastDisplayedCount] = useState(0);
  const toast = useToast();

  useEffect(() => {
    const error = errorState.errors[lastDisplayedCount];
    if (error) {
      toast({
        title: error.errorType,
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLastDisplayedCount((value) => value + 1);
    }
  }, [errorState.errors]);

  useEffect(() => {
    if (errorState.info) {
      toast({
        title: errorState.info.title,
        description: errorState.info.text,
        status: errorState.info.status
          ? (errorState.info.status as any)
          : 'info',
        duration: 15000,
        isClosable: true,
      });
    }
  }, [errorState.info]);
};

export default useErrorLogs;
