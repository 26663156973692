import BaseApiService from '@services/BaseApiService';
import { AxiosInstance } from 'axios';

export default class ApiService extends BaseApiService {
  axiosInstance: AxiosInstance;
  platformId: string;

  setPlatformId(platformId: string) {
    this.platformId = platformId;
  }

  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/records/heartbeat/',
    });
  }

  async heartBeat(url: string, scrollTop: number, scrollLeft: number) {
    const response = await this.axiosInstance.post('', {
      url,
      scroll: { top: scrollTop, left: scrollLeft },
      platform_id: this.platformId,
    });
    return response.data;
  }
}
