import IntentClassifierComponent from './IntentClassifierComponent';

export default class FallbackClassifier extends IntentClassifierComponent {
  params = [
    {
      name: 'threshold',
      label: 'Threshold',
      type: 'number',
      value: 0.3,
      required: true,
    },
    {
      name: 'ambiguity_threshold',
      label: 'Ambiguity threshold',
      type: 'number',
      value: 0.1,
      required: true,
    },
  ];
}
