import BaseApiService from '@services/BaseApiService';
import Commit from '@projectTypes/Commit';

export default class ApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/commits',
    });
  }

  async getCommits(
    projectId: string,
    userId: string,
    deviceId?: string | undefined,
  ) {
    const response = await this.axiosInstance.get<Commit[]>(
      `/for-user/?projectId=${projectId}&userId=${userId}`,
    );

    return response.data;
  }

  async createCommit(
    data: { message: string; files: { id: string; content: string }[] },
    projectId: string,
    userId: string,
    deviceId: string = null,
  ) {
    const response = await this.axiosInstance.post<Commit>('/', {
      data,
      projectId,
      userId,
      deviceId,
    });
    return response.data;
  }
}
