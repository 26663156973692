import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import policyService from '@services/PolicyService';
import PolicyStore, { PolicyName, PolicyData } from '@projectTypes/Policy';
import File from '@projectTypes/File';
import actionNameCreator from '@helpers/actionNameCreator';

const policyInitialState: PolicyStore = {
  components: [],
};

const pipelineSlices = createSlice({
  name: 'policy',
  initialState: policyInitialState,
  reducers: {
    addComponent(state, action: PayloadAction<PolicyName>) {
      const component = policyService.addComponent(action.payload);
      state.components.push(component);
    },
    removeComponent(state, action: PayloadAction<number>) {
      const components = policyService.removeComponent(action.payload);
      state.components = [...components];
    },
    setComponentParamValue(
      state,
      action: PayloadAction<{ index: number; paramName: string; value: any }>,
    ) {
      const { index, paramName, value } = action.payload;
      const component = policyService.setComponentParamValue(
        index,
        paramName,
        value,
      );
      state.components[index] = {
        ...state.components[index],
        ...component,
      };
    },
    setComponents(state, action: PayloadAction<PolicyData[]>) {
      state.components = action.payload;
    },
  },
});

export const {
  addComponent,
  removeComponent,
  setComponentParamValue,
  setComponents,
} = pipelineSlices.actions;

export default pipelineSlices.reducer;

const anc = actionNameCreator('policy');

export const fillPolicy = createAsyncThunk(
  anc('fillPolicy'),
  async (file: File, { dispatch }) => {
    policyService.parsePoliciesFromYml(file.newContent);
    dispatch(
      setComponents(JSON.parse(JSON.stringify(policyService.components))),
    );
  },
);
