import React, { useEffect, useState } from 'react';

import useDispatch from '@hooks/useDispatch';
import ScratchService from '@services/ScratchService';
import File from '@projectTypes/File';
import { Box, Spinner } from '@chakra-ui/react';
import { saveProjectFiles } from '@slices/fileSlices';

import config from '@config';
interface ScratchProps {
  displayedFile: File;
  onChange: (value: string) => void;
}

const Scratch = (props: ScratchProps) => {
  const { displayedFile, onChange } = props;
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const firstLoad = () => {
    setTimeout(() => {
      if (!isLoading) {
        return;
      }
      if (localStorage.getItem('scratch-loaded')) {
        return;
      }
      setCounter(counter + 1);
      firstLoad();
    }, 15000);
    setIsLoading(false);
  };

  useEffect(() => {
    firstLoad();
  }, []);

  const onLoad = () => {
    setIsLoading(false);
  };

  const onError = () => {
    setIsLoading(false);
  };

  const onSave = () => {
    dispatch(saveProjectFiles(displayedFile.projectId));
  };

  useEffect(() => {
    setIsLoading(true);
    ScratchService.createEventListener(
      displayedFile,
      onChange,
      onLoad,
      onError,
      onSave,
    );
  }, []);

  return (
    <Box width="100%" height="100%" justifyContent="center" alignItems="center">
      <iframe
        id="scratch-iframe"
        key={`${counter}`}
        width="100%"
        height="100%"
        src={config.scratchHost}
        title="scratch editor"
        allow="autoplay; fullscreen; camera"
      />
    </Box>
  );
};

export default Scratch;
