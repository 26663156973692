import { io, Socket } from 'socket.io-client';
import { Observable, Subscriber } from 'rxjs';
import { RealTimeEvents } from '@projectTypes/RealTimeEdit';

export default class SocketService {
  observable: Observable<any>;
  subscriber: Subscriber<any>;
  socket: Socket;
  host: string;
  token: string;
  isSetup = false;

  constructor(host: string) {
    this.host = host;
  }

  setup() {
    this.socket = io(this.host);
    this.socket.onAny((event, data) => {
      this.subscriber?.next({ event, data });
    });
    this.isSetup = true;
    this.sendEvent(RealTimeEvents.SETUP, {
      token: this.token,
    });
  }

  sendEvent(event: RealTimeEvents, data: any) {
    if (!this.isSetup) {
      this.setup();
    }
    this.socket.emit(event, data);
  }

  setToken(token: string) {
    this.token = token;
  }

  subscribeToEvents() {
    if (!this.observable) {
      this.observable = new Observable((subscriber) => {
        this.subscriber = subscriber;
      });
    }
    return this.observable;
  }
}
