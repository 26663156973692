import { safeParseJSON } from '@/helpers/json';
import config from '@config';
import {
  ReceivedMessage,
  BootChatDto,
  ServerTaskState,
  TerminalStrategy,
  TrainChatDto,
  RunFrom,
} from '@projectTypes/Terminal';
import delay from '@helpers/delay';

import ApiService from './_private/ApiService';

export default class ChatbotTerminalStrategy extends TerminalStrategy {
  apiService: ApiService;
  trainingId: string;
  chatId: string;
  isTraining = false;
  isBooting = false;

  constructor() {
    super();
    this.apiService = new ApiService(
      config.runServerHost,
      config.runServerPath,
    );
  }

  async sendMessage(message: string) {
    try {
      const data: any = await this.apiService.sendChatMessage(
        this.chatId,
        message,
      );
      if (data.error) {
        if (data.error === 'ConnectionError') {
          this.answerMessage({
            error: 'Ooops chatbot got disconnected. Please try reconnecting.',
          });
        } else {
          this.answerMessage({
            error: data.error,
          });
        }
      } else if (data.out.length === 0) {
        const message: ReceivedMessage = {
          text: '[Chatbot is confused 🤔]',
        };
        if (data.nlu) {
          message.debug = data.nlu;
        }
        this.answerMessage(message);
      } else {
        data.out.forEach((receivedData) => {
          if (receivedData && receivedData.error) {
            if (typeof receivedData.error === 'string') {
              try {
                receivedData.error = JSON.parse(receivedData.error);
              } catch (e) {
                console.log(e);
              }
            }
            receivedData.debug = receivedData.error;
          } else if (data.nlu) {
            receivedData.debug = data.nlu;
          }
          this.answerMessage(receivedData);
        });
      }
    } catch (e) {
      this.answerMessage({
        error: e.message || e,
        debug: e,
      });
    }
  }

  async runFile(projectId: string, fileId: string) {
    let data: any = null;
    try {
      data = await this.apiService.runFile(projectId, fileId);
    } catch (e) {
      return this.handleError(e.message);
    }

    const otherData: ReceivedMessage = {};
    if (data?.out?.result?.graphs) {
      otherData.charts = data.out.result.graphs;
    }
    this.handleOutput(data?.out?.result?.output || '', otherData);
    if (data?.out?.traceback) {
      this.handleError(data?.out?.traceback);
    }
  }

  async runProject(projectId: string) {
    const data = await this.apiService.runProject(projectId);
    this.handleOutput(data.output);
  }

  async trainChat(projectId: string): Promise<TrainChatDto> {
    const data = await this.apiService.trainChat(projectId);
    this.trainingId = data.id;
    return data;
  }

  async getChatTraining(trainingId: string): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      let timestamp = 0;
      const fetchTrainingData = async () => {
        console.log('fetchTrainingData');
        if (!this.isTraining) {
          reject(new Error('canceled'));
        }
        const trainingStatus = await this.apiService.getChatTraining(
          trainingId,
          timestamp,
        );
        if (trainingStatus?.logs?.length) {
          timestamp = trainingStatus.logs[trainingStatus.logs.length - 1].time;
          for (let i = 0; i < trainingStatus.logs.length; i += 1) {
            if (i % 20 === 0) {
              // eslint-disable-next-line no-await-in-loop
              await delay(0);
            }
            this.answerMessage({ text: trainingStatus.logs[i].data });
          }
        }
        if (trainingStatus?.error) {
          this.answerMessage({ error: trainingStatus.error });
        }

        switch (trainingStatus.state) {
          case ServerTaskState.queued:
          case ServerTaskState.started:
            return setTimeout(fetchTrainingData, 2500);
          case ServerTaskState.done:
            return resolve();
          case ServerTaskState.stopped:
          case ServerTaskState.errored:
          default:
            this.isTraining = false;
            return reject(new Error(trainingStatus.error || 'errored'));
        }
      };
      fetchTrainingData();
    });
  }

  async stopChatTraining(): Promise<TrainChatDto | null> {
    if (this.trainingId) {
      const data = await this.apiService.stopChatTraining(this.trainingId);
      return data;
    }
    return null;
  }

  async bootChat(trainingId: string, from: RunFrom): Promise<BootChatDto> {
    this.isBooting = true;
    const data = await this.apiService.bootChat(trainingId, from);
    this.trainingId = trainingId;
    this.chatId = data.id;
    await this.getChatBoot(data.id);
    this.isBooting = false;
    return data;
  }

  async getChatBoot(chatId: string): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      const fetchBootData = async () => {
        if (!this.isBooting) {
          reject(new Error('canceled'));
        }
        const trainingStatus = await this.apiService.getChatBoot(chatId);
        switch (trainingStatus.state) {
          case ServerTaskState.queued:
          case ServerTaskState.started:
            return setTimeout(fetchBootData, 2500);
          case ServerTaskState.done:
            return resolve();
          case ServerTaskState.stopped:
          case ServerTaskState.errored:
          default:
            this.isBooting = false;
            return reject(new Error(trainingStatus.error || 'errored'));
        }
      };
      fetchBootData();
    });
  }

  async stopChatBoot(): Promise<BootChatDto | null> {
    if (this.chatId) {
      const data = await this.apiService.stopChatBoot(this.chatId);
      return data;
    }
    return null;
  }

  async resetChat(chatId: string): Promise<BootChatDto> {
    const data = await this.apiService.resetChat(chatId);
    return data;
  }

  async closeTerminal() {
    this.isTraining = false;
    await this.stopChatTraining();
  }

  async shareProjectTrainingId(trainingId: string, publish = false) {
    await this.apiService.shareTraining(trainingId, publish);
  }

  async closeChat() {
    this.isBooting = false;
    await this.stopChatBoot();
  }

  async trainProject(projectId: string) {
    if (this.isTraining) {
      // eslint-disable-next-line no-throw-literal
      throw 'Training in progress';
    }

    this.isTraining = true;
    try {
      const data = await this.trainChat(projectId);

      await this.getChatTraining(data.id);
      this.isTraining = false;

      if (data.error) {
        throw data.error;
      }

      return data.id;
    } finally {
      this.isTraining = false;
    }
  }

  async getLastRunId(projectId: string) {
    const runId = await this.apiService.getLastRunId(projectId);
    return runId;
  }

  setToken(token: string) {
    this.apiService.setToken(token);
  }

  private handleOutput(output: string, otherData: any = {}) {
    const outputs = output.split('\n').map((o) => safeParseJSON(o, o));
    outputs.forEach((outputMessage) => {
      this.answerMessage({ text: outputMessage, ...otherData });
    });
  }

  private handleError(output: string, otherData: any = {}) {
    const outputs = output.split('\n').map((o) => {
      return o;
    });
    outputs.forEach((outputMessage) => {
      this.answerMessage({ error: outputMessage, ...otherData });
    });
  }
}
