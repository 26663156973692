import Store from '@projectTypes/Store';
import { createSelector } from 'reselect';

const state = (store: Store) => store;

export const terminalSelector = createSelector(state, (s) => s.terminal);
export const terminalMessageSelector = createSelector(state, (s) =>
  [...s.terminal.messages].sort((a, b) => a.date - b.date),
);

export const isDesktopLiveSelector = createSelector(
  state,
  (s) => s.terminal.isDesktopLive,
);
export const isHexapodReadySelector = createSelector(
  state,
  (s) => s.terminal.isHexapodReady,
);
export const espDeviceSelector = createSelector(state, (s) => s.terminal.espDevice);
export const showUsbUploadButtonSelector = createSelector(state, (s) => s.terminal.showUsbUploadButton);
export const pcbTypeSelector = createSelector(state, (s) => s.terminal.pcbType);
