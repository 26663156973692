import BaseApiService from '@services/BaseApiService';
import StemiBlob from '@projectTypes/StemiBlobs';

export default class ApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/stemi-blobs',
    });
  }

  async listAll() {
    const response = await this.axiosInstance.get<StemiBlob[]>('/');
    return response.data;
  }
}
