import { TerminalStrategy, TerminalStrategyType } from '@projectTypes/Terminal';
import PythonTerminalStrategy from './TerminalStrategies/PythonTerminalStrategy/PythonTerminalStrategy';
import ArduinoTerminalStrategy from './TerminalStrategies/ArduinoTerminalStrategy/ArduinoTerminalStrategy';
import EmbeddedPythonTerminalStrategy from './TerminalStrategies/EmbeddedPythonTerminalStrategy/EmbeddedPythonTerminalStrategy';

export function strategyIsPython(
  type: TerminalStrategyType,
  strategy: TerminalStrategy,
): strategy is PythonTerminalStrategy {
  return type === TerminalStrategyType.Python;
}

export function strategyIsArduino(
  type: TerminalStrategyType,
  strategy: TerminalStrategy,
): strategy is ArduinoTerminalStrategy {
  return type === TerminalStrategyType.Arduino;
}

export function strategyIsEmbedPy(
  type: TerminalStrategyType,
  strategy: TerminalStrategy,
): strategy is EmbeddedPythonTerminalStrategy {
  return type === TerminalStrategyType.EmbeddedPython;
}
