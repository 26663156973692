const affirmationMessages = {
  en: {
    pass: [
      "Amazing. You're a genius.",
      "Great. You're a master of programming.",
      "Bravo, you're getting better and better with every program you write.",
      "You're doing great. Keep it up.",
      "Bravo, if you keep this up you'll be a master of programming.",
    ],
    fail: [
      'Nice try, but there are things you can do better. Check the details and try again.',
      'Not bad, but you can do better. Check the details and try again.',
      "You're getting better but there are still some things to fix. Check the details and try again.",
      'This was a good try, but there are still some things to fix. Check the details and try again.',
    ],
  },
  hr: {
    pass: [
      'Bravo. Rasturaš ovaj Python.',
      'Svaka čast, sve si bolji u programiranju.',
      'Odlično. Ti si majstor programiranja.',
      'Bravo. Svakim zadatkom si sve bolji.',
      'Bravo. Nastavi tako i postat ćeš vrhunski programer.',
    ],
    fail: [
      'Dobar pokušaj, ali još neke stvari u programu treba popraviti. Pogledaj detalje i saznaj koje.',
      'Sve ti bolje ide, ali još neke stvari u programu treba popraviti. Pogledaj detalje i saznaj koje.',
      'Nije loše, ali još neke stvari u programu treba popraviti. Pogledaj detalje i saznaj koje.',
      'Ovo je jako blizu konačnog rješenja, ali još neke stvari u programu treba popraviti. Pogledaj detalje i saznaj koje.',
    ],
  },
};

export const getAffirmationMessage = (
  key: 'pass' | 'fail',
  language: 'en' | 'hr',
) => {
  const section = affirmationMessages[language][key];
  return section[Math.floor(section.length * Math.random())];
};
