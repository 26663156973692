import { Observable, Subscriber } from 'rxjs';

import {
  PcbType,
  ReceivedMessage,
  RunFrom,
  TerminalStrategy,
  TerminalStrategyType,
} from '@projectTypes/Terminal';
import { strategyIsPython } from './helpers';
import EmbeddedPythonTerminalStrategy from './TerminalStrategies/EmbeddedPythonTerminalStrategy/EmbeddedPythonTerminalStrategy';
import PythonTerminalStrategy from './TerminalStrategies/PythonTerminalStrategy/PythonTerminalStrategy';
import ChatbotTerminalStrategy from './TerminalStrategies/ChatbotTerminalStrategy/ChatbotTerminalStrategy';
import ArduinoTerminalStrategy from './TerminalStrategies/ArduinoTerminalStrategy/ArduinoTerminalStrategy';

export default class TerminalService {
  observable: Observable<ReceivedMessage>;
  subscriber: Subscriber<any>;

  terminalStrategy: TerminalStrategy = new ChatbotTerminalStrategy();
  terminalStrategyType: TerminalStrategyType = TerminalStrategyType.Chatbot;

  selectTerminalStrategy(type: TerminalStrategyType) {
    let isTerminalStrategyChanged = true;
    if (this.terminalStrategyType !== type) {
      this.terminalStrategyType = type;
      switch (type) {
        case TerminalStrategyType.Arduino: {
          this.terminalStrategy = new ArduinoTerminalStrategy();
          break;
        }
        case TerminalStrategyType.Chatbot: {
          this.terminalStrategy = new ChatbotTerminalStrategy();
          break;
        }
        case TerminalStrategyType.Python: {
          this.terminalStrategy = new PythonTerminalStrategy();
          break;
        }
        case TerminalStrategyType.EmbeddedPython: {
          this.terminalStrategy = new EmbeddedPythonTerminalStrategy();
          break;
        }
        default:
          isTerminalStrategyChanged = false;
      }
    }

    if (isTerminalStrategyChanged) {
      this.observable = null;
      if (strategyIsPython(type, this.terminalStrategy)) {
        this.terminalStrategy.registerInputs();
        this.terminalStrategy.loadPyodide();
      }

      this.subscribeToTerminalMessages();
    }
  }

  async sendInput(text: string) {
    if (!strategyIsPython(this.terminalStrategyType, this.terminalStrategy)) {
      // eslint-disable-next-line no-console
      console.error('Wrong terminal strategy');
      return;
    }

    await this.terminalStrategy.sendInput(text);
  }

  async sendMessage(message: string) {
    await this.terminalStrategy.sendMessage(message);
  }

  async killPython() {
    if (!strategyIsPython(this.terminalStrategyType, this.terminalStrategy)) {
      // eslint-disable-next-line no-console
      console.error('Wrong terminal strategy');
      return undefined;
    }

    return this.terminalStrategy.killTerminal();
  }

  async runFile(projectId: string, fileId: string, fileCode?: string) {
    await this.terminalStrategy.runFile(projectId, fileId, fileCode);
  }

  // eslint-disable-next-line consistent-return
  async testFile(
    fileCode: string,
    testContent: string,
    projectId: string,
    language: 'en' | 'hr',
  ) {
    if (strategyIsPython(this.terminalStrategyType, this.terminalStrategy)) {
      return this.terminalStrategy.testFile(
        fileCode,
        testContent,
        projectId,
        language,
      );
    }

    // eslint-disable-next-line no-console
    console.error('Wrong terminal strategy');
    return undefined;
  }

  async runProject(projectId: string) {
    await this.terminalStrategy.runProject(projectId);
  }

  async closeTerminal() {
    await this.terminalStrategy.closeTerminal();
  }

  async closeChat() {
    await this.terminalStrategy.closeChat();
  }

  async trainProject(projectId: string) {
    return this.terminalStrategy.trainProject(projectId);
  }

  async bootChat(trainingId: string, from: RunFrom) {
    return this.terminalStrategy.bootChat(trainingId, from);
  }

  async compileProject(
    projectId: string,
    code: string,
    ota = false,
    pcbType = PcbType.v1,
    skipValidation = false,
  ) {
    return this.terminalStrategy.compileProject(
      projectId,
      code,
      ota,
      pcbType,
      skipValidation,
    );
  }

  async setupEspDevice(onDisconnect?: () => void): Promise<any> {
    if ((this.terminalStrategy as any).setupEspDevice) {
      return (this.terminalStrategy as any).setupEspDevice(onDisconnect);
    }
    return undefined;
  }

  async disconnectEspDevice(): Promise<any> {
    if ((this.terminalStrategy as any).disconnectEspDevice) {
      return (this.terminalStrategy as any).disconnectEspDevice();
    }
    return undefined;
  }

  async flashEsp(params: {
    projectId: string;
    hardReset: boolean;
    version: PcbType;
  }): Promise<any> {
    if ((this.terminalStrategy as any).disconnectEspDevice) {
      return (this.terminalStrategy as any).flashEsp(params);
    }
    return undefined;
  }

  async shareProjectTrainingId(
    trainingId: string,
    publish = false,
  ): Promise<void> {
    await this.terminalStrategy.shareProjectTrainingId(trainingId, publish);
  }

  async subscribeToTerminalMessages() {
    if (!this.observable) {
      this.observable = new Observable((sub) => {
        this.subscriber = sub;
      });
      const sub = await this.terminalStrategy.registerMessageReceiver();
      sub.subscribe((message) => {
        this.subscriber.next(message);
      });
    }
    return this.observable;
  }

  async getLastRunId(projectId: string) {
    const runId = await this.terminalStrategy.getLastRunId(projectId);
    return runId;
  }

  setToken(token: string) {
    this.terminalStrategy.setToken(token);
  }
}
