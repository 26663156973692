import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';

const state = (store: Store) => store;

export const trackerSelector = createSelector(state, (s) => s.tracker);

export const trackerSlotsSelector = createSelector(
  state,
  (s) => s.tracker.slots,
);

export const trackerSlotDataSelector = createSelector(state, (s) => {
  const data = {};
  s.tracker.slots.forEach((slot) => {
    if (slot.key) {
      data[slot.key] = slot.value;
    }
  });
  return JSON.stringify(data);
});
