import { AdditionalUserInfoDto } from '@projectTypes/Platform';
import ApiService from './_private/ApiService';

export default class PlatformService {
  apiService: ApiService;

  constructor(host: string, path: string) {
    this.apiService = new ApiService(host, path);
  }

  setToken(token: string) {
    this.apiService.setToken(token);
  }

  async getAdditionalUserInfo(): Promise<AdditionalUserInfoDto> {
    try {
      const retData = await this.apiService.getUserInfo();
      return retData;
    } catch (err) {
      return { schoolIds: [], showExperimentalFeatures: false };
    }
  }
}
