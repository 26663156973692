import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import pipelineService from '@services/PipelineService';
import Pipeline, {
  PipelineComponentName,
  PipelineComponentData,
} from '@projectTypes/Pipeline';
import File from '@projectTypes/File';
import actionNameCreator from '@helpers/actionNameCreator';
import { setInfo } from './errorSlices';

const pipelineInitialState: Pipeline = {
  components: [],
  isComponentOrderValid: true,
  areComponentValuesValid: true,
  isComponentMinimumValid: true,
  componentIndexOrderNotValid: [],
  componentIndexValueNotValid: [],
};

function validatePipeline(state: Pipeline) {
  const {
    isComponentOrderValid,
    componentIndexOrderNotValid,
  } = pipelineService.getIsComponentOrderValid();
  state.isComponentMinimumValid = pipelineService.getIsComponentMinimumValid();
  state.isComponentOrderValid = isComponentOrderValid;
  state.componentIndexOrderNotValid = componentIndexOrderNotValid;

  const {
    areComponentValuesValid,
    componentIndexValueNotValid,
  } = pipelineService.getAreComponentValuesValid();
  state.areComponentValuesValid = areComponentValuesValid;
  state.componentIndexValueNotValid = componentIndexValueNotValid;

  return state;
}

const pipelineSlices = createSlice({
  name: 'pipeline',
  initialState: pipelineInitialState,
  reducers: {
    addComponent(state, action: PayloadAction<PipelineComponentName>) {
      const { component, newComponentIndex } = pipelineService.addComponent(
        action.payload,
      );
      state.components.splice(newComponentIndex, 0, component);
    },
    removeComponent(state, action: PayloadAction<number>) {
      const components = pipelineService.removeComponent(action.payload);
      state.components = [...components];
    },
    changeComponentIndex(
      state,
      action: PayloadAction<{
        oldComponentIndex: number;
        newComponentIndex: number;
      }>,
    ) {
      const { oldComponentIndex, newComponentIndex } = action.payload;
      const components = pipelineService.changeComponentIndex(
        oldComponentIndex,
        newComponentIndex,
      );
      state.components = [...components];
    },
    setComponentParamValue(
      state,
      action: PayloadAction<{ index: number; paramName: string; value: any }>,
    ) {
      const { index, paramName, value } = action.payload;
      const component = pipelineService.setComponentParamValue(
        index,
        paramName,
        value,
      );
      state.components[index] = {
        ...state.components[index],
        ...component,
      };
    },
    setComponents(state, action: PayloadAction<PipelineComponentData[]>) {
      state.components = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return action.type.startsWith('pipeline');
      },
      (state) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state = validatePipeline(state);
      },
    );
  },
});

export const {
  addComponent,
  removeComponent,
  changeComponentIndex,
  setComponentParamValue,
  setComponents,
} = pipelineSlices.actions;

export default pipelineSlices.reducer;

const anc = actionNameCreator('pipeline');

export const fillPipeline = createAsyncThunk(
  anc('fillPipeline'),
  async (file: File, { dispatch }) => {
    pipelineService.parsePipelineFromYml(file.newContent);
    dispatch(
      setComponents(JSON.parse(JSON.stringify(pipelineService.components))),
    );
  },
);

export const checkPipeline = createAsyncThunk(
  anc('checkPipeline'),
  async (_, { dispatch, getState }) => {
    const {
      isComponentOrderValid,
      isComponentMinimumValid,
      areComponentValuesValid,
    } = (getState() as any).pipeline as Pipeline;
    let text = null;
    if (!isComponentMinimumValid) {
      text =
        'Pipeline must have Intent Classifier and Entity Extractor or Combined Intent Classifiers And Entity Extractors';
    } else if (!isComponentOrderValid) {
      text = 'Component order is not valid';
    } else if (!areComponentValuesValid) {
      text = 'Component values are not valid';
    }
    if (text) {
      dispatch(
        setInfo({
          text,
          title: 'Pipeline error',
          status: 'error',
        }),
      );
    }
  },
);
