import React from 'react';
import { HStack, Badge } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { pipelineStatusSelector } from '@selectors';

const PipelineStatusContainer = () => {
  const pipelineStatusState = useSelector(pipelineStatusSelector);
  return (
    <HStack>
      {!pipelineStatusState.isComponentMinimumValid && (
        <Badge colorScheme="red">
          Pipeline must have intent classifier and entity extractor or combined
        </Badge>
      )}
      {!pipelineStatusState.areComponentValuesValid && (
        <Badge colorScheme="red">Check required component values</Badge>
      )}
      {!pipelineStatusState.isComponentOrderValid && (
        <Badge colorScheme="red">Check component order</Badge>
      )}
    </HStack>
  );
};

export default PipelineStatusContainer;
