import React from 'react';
import { Box, Input } from '@chakra-ui/react';

interface JsonTableContainerProps {
  data: any;
  isKey?: boolean;
  disabled?: boolean;
  onCellValueChange: (value: any) => void;
}

const JsonTableHead = (props: JsonTableContainerProps) => {
  const { data, isKey, disabled, onCellValueChange } = props;

  return (
    <Box>
      <Input
        maxW={isKey ? '150px' : '300px'}
        border="none"
        focusBorderColor="#00000000"
        color="#FFF"
        textAlign="center"
        defaultValue={data}
        disabled={disabled}
        _disabled={{ color: '#FFF' }}
        onChange={(event) => onCellValueChange(event.target.value)}
      />
    </Box>
  );
};

export default JsonTableHead;
