import BaseApiService from '@services/BaseApiService';

export default class ApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/actions',
    });
  }

  async validateAction(
    projectId: string,
    action: string,
    actionName: string,
    tracker = '{}',
  ) {
    const response = await this.axiosInstance.post(
      `/validate/projects/${projectId}`,
      {
        action,
        actionName,
        tracker,
      },
    );
    return response.data;
  }

  async runAction(
    projectId: string,
    action: string,
    actionName: string,
    tracker = '{}',
  ) {
    const response = await this.axiosInstance.post(
      `/run/projects/${projectId}`,
      {
        action,
        actionName,
        tracker,
      },
    );
    return response.data;
  }
}
