import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import Store from '@projectTypes/Store';
import {
  RealTimeEditStore,
  RealTimeEvents,
  UserFilePosition,
} from '@projectTypes/RealTimeEdit';
import File from '@projectTypes/File';
import actionNameCreator from '@helpers/actionNameCreator';
import realTimeEditService from '@services/RealTimeEditService';
import { isUsingSpecificFileEditor } from '@components/Editor/CodeEditor/helpers/specificFileEditors';

import { addFile, increaseFileCounter, setNewFileContent } from './fileSlices';

const userInitialState: RealTimeEditStore = {
  userFilePositions: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    addUserFilePosition(
      state,
      action: PayloadAction<{ key: string; value: UserFilePosition }>,
    ) {
      const { key, value } = action.payload;
      state.userFilePositions[key] = value;
      state.userFilePositions = { ...state.userFilePositions };
    },
    removeUserFilePosition(state, action: PayloadAction<string>) {
      delete state.userFilePositions[action.payload];
      state.userFilePositions = { ...state.userFilePositions };
    },
    setUserFilePositions(
      state,
      action: PayloadAction<Record<string, UserFilePosition>>,
    ) {
      state.userFilePositions = { ...action.payload };
    },
    changeRealTimeFile(state, action: PayloadAction<string>) {
      realTimeEditService.fileChange({
        fileId: action.payload,
      });
    },
    selectRealTimeProject(state, action: PayloadAction<string>) {
      realTimeEditService.projectSelect(action.payload);
    },
  },
});

export const {
  addUserFilePosition,
  removeUserFilePosition,
  setUserFilePositions,
  changeRealTimeFile,
  selectRealTimeProject,
} = userSlice.actions;

export default userSlice.reducer;

const anc = actionNameCreator('REAL_TIME_EDIT');

export const fileSave = createAsyncThunk<void, File, { state: Store }>(
  anc('fileSave'),
  async (file, { getState }) => {
    if (isUsingSpecificFileEditor(file)) {
      return;
    }
    const state = getState();
    const selectedProject = state.project.projects.find(
      (project) => project.id === state.project.selectedProjectId,
    );
    if (selectedProject.isGroupProject) {
      realTimeEditService.fileSave(file);
    }
  },
);

export const sendFileData = createAsyncThunk<void, void, { state: Store }>(
  anc('sendFileData'),
  async (_v, { getState }) => {
    const state = getState();
    const displayedFile = state.file.files.find(
      (file) => file.id === state.file.displayedFileId,
    );
    if (isUsingSpecificFileEditor(displayedFile)) {
      return;
    }
    realTimeEditService.fileData(displayedFile);
  },
);

let eventSubscription;

export const unsubscribeFromChanges = createAsyncThunk<
  void,
  void,
  { state: Store }
>(anc('unsubscribeFromChanges'), async (_v) => {
  if (eventSubscription) {
    eventSubscription.unsubscribe();
  }
});

export const subscribeToChanges = createAsyncThunk<
  void,
  void,
  { state: Store }
>(anc('subscribeToChanges'), async (_v, { dispatch, getState }) => {
  dispatch(unsubscribeFromChanges());
  const realTimeChanges = realTimeEditService.subscribeToEvents();
  eventSubscription = realTimeChanges.subscribe(({ event, data }) => {
    if (event === RealTimeEvents.USER_DISCONNECTED) {
      dispatch(removeUserFilePosition(data.uniqueId));
    } else if (data.fileId) {
      dispatch(
        addUserFilePosition({
          key: data.uniqueId,
          value: {
            username: data.username,
            uniqueId: data.uniqueId,
            fileId: data.fileId,
            color: data.color,
          },
        }),
      );
    }
    if (event === RealTimeEvents.FILE_CHANGE) {
      const state = getState();
      const displayedFile = state.file.files.find(
        (file) => file.id === state.file.displayedFileId,
      );
      if (displayedFile && displayedFile.id === data.fileId) {
        if (!isUsingSpecificFileEditor(displayedFile)) {
          dispatch(sendFileData());
        } else {
          // Don't send data
        }
      }
    } else if (event === RealTimeEvents.FILE_DATA) {
      dispatch(
        setNewFileContent({ fileId: data.id, newContent: data.newContent }),
      );
      dispatch(increaseFileCounter());
    } else if (event === RealTimeEvents.FILE_SAVE) {
      dispatch(addFile({ ...data }));
      dispatch(increaseFileCounter());
    }
  });
});
