import BaseApiService from '@services/BaseApiService';
import Device from '@projectTypes/Device';

export default class ApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/devices',
    });
  }

  async getDevicesBySchoolIds(schoolIds: string[], userId: string) {
    const response = await this.axiosInstance.post<{
      devices: Device[];
      connectedDevice?: Device;
    }>('/list-by-schools', {
      schoolIds,
      userId,
    });
    return response.data;
  }

  async connectToDevice(deviceId: string) {
    const response = await this.axiosInstance.get<Device>(
      `/connect-to/${deviceId}`,
    );
    return response.data;
  }

  async pushCommitToDevice(
    deviceId: string,
    commitId: string,
    _noConfirm: boolean,
  ) {
    const response = await this.axiosInstance.post<Device>('/code', {
      deviceId,
      commitId,
    });
    return response.data;
  }

  async disconnectFromDevice(deviceId: string) {
    const response = await this.axiosInstance.get<Device>(
      `/disconnect-from/${deviceId}`,
    );
    return response.data;
  }
}
