import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import { ErrorType, ErrorStore, Error, Info } from '@projectTypes/Error';

import isRejectedAction from './helpers/isRejectedAction';

const SKIP_ACTION_NAMESPACES = ['terminal'];

const errors: Error[] = [
  {
    errorType: ErrorType.UserError,
    message: 'User error',
  },
  {
    errorType: ErrorType.ServerError,
    message: 'Something went wrong',
  },
];

const projectInitialState: ErrorStore = {
  errors: [],
  fileError: null,
  projectError: null,
  userError: null,
  terminalError: null,
  info: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState: projectInitialState,
  reducers: {
    setInfo(state, action: PayloadAction<Info>) {
      state.info = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        !SKIP_ACTION_NAMESPACES.find((namespace) =>
          action.type.toLowerCase().startsWith(namespace),
        ) && isRejectedAction(action),
      (state, action) => {
        captureException(action && action.error ? action.error : action);
        if (process.env.NODE_ENV === 'development') {
          console.error(action.error);
          console.error(action.error.stack);
        }

        const error = errors.find((e) => e.errorType === action.error.message);
        if (error) {
          state.errors.push(error);
        } else {
          state.errors.push({
            errorType: (action.error as any).error,
            message: (action.error as any).message,
          });
        }
      },
    );
  },
});

export const { setInfo } = errorSlice.actions;
export default errorSlice.reducer;
