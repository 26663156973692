import Policy from './Policy';

export default class TEDPolicy extends Policy {
  params = [
    {
      name: 'max_history',
      label: 'Max history',
      type: 'number',
      value: 8,
      required: false,
    },
    {
      name: 'epochs',
      label: 'Epochs',
      type: 'number',
      value: 200,
      required: false,
    },
    {
      name: 'constrain_similarities',
      label: 'Constrain similarities',
      type: 'boolean',
      value: true,
      required: true,
    },
    {
      name: 'split_entities_by_comma',
      label: 'Split entities by comma',
      type: 'boolean',
      value: true,
      required: false,
    },
  ];
}
