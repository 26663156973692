import EntityExtractorComponent from './EntityExtractorComponent';

export default class RegexEntityExtractor extends EntityExtractorComponent {
  params = [
    {
      name: 'case_sensitive',
      label: 'Case sensitive',
      type: 'boolean',
      value: true,
      required: true,
    },
    {
      name: 'use_lookup_tables',
      label: 'Use lookup tables',
      type: 'boolean',
      value: true,
      required: true,
    },
    {
      name: 'use_word_boundaries',
      label: 'Use word boundaries',
      type: 'boolean',
      value: true,
      required: true,
    },
    {
      name: 'use_regexes',
      label: 'Use regexes',
      type: 'boolean',
      value: true,
      required: true,
    },
  ];
}
