import React from 'react';
import { Box, Input, Text } from '@chakra-ui/react';

interface JsonTableContainerProps {
  data: any;
  isKey?: boolean;
  isInvalid?: boolean;
  onCellValueChange: (value: any) => void;
}

const JsonTableCell = (props: JsonTableContainerProps) => {
  const { data, isKey, isInvalid, onCellValueChange } = props;

  return (
    <Box flexDirection="row" alignItems="center">
      <Input
        maxW={isKey ? '140px' : '300px'}
        borderColor={isInvalid ? '#F00' : '#888'}
        focusBorderColor={isInvalid ? '#F00' : 'primary'}
        color="#FFF"
        defaultValue={data}
        onChange={(event) => onCellValueChange(event.target.value)}
      />
      {isKey && (
        <Text
          as="span"
          color="#FFF"
          fontWeight="bold"
          fontSize="24px"
          marginLeft="12px"
          minW="10px"
        >
          :
        </Text>
      )}
    </Box>
  );
};

export default JsonTableCell;
