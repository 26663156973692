import { createSelector } from 'reselect';
import Store from '@projectTypes/Store';
import { selectedProjectSelector } from './projectSelectors';

const state = (store: Store) => store;

export const streamSelector = createSelector(state, (s) => s.stream);

export const isStreamLive = createSelector(
  streamSelector,
  (s) => s.config?.live,
);

export const currentIsLive = createSelector(state, (s) => {
  const project = selectedProjectSelector(s);
  if (!project) {
    return false;
  }

  const stream = streamSelector(s);

  return (
    stream.config?.live && project.templateId === stream.config?.templateId
  );
});
