export default interface Pipeline {
  components: PipelineComponentData[];
  isComponentOrderValid: boolean;
  areComponentValuesValid: boolean;
  isComponentMinimumValid: boolean;
  componentIndexOrderNotValid: number[];
  componentIndexValueNotValid: number[];
}

export interface PipelineComponentData {
  id: number;
  name: string;
  type: PipelineComponentType;
  params: PipelineComponentDataParam[];
  requiredComponentType?: PipelineComponentType;
  requiredComponentName?: PipelineComponentName;
}

export interface PipelineComponentDataParam {
  name: string;
  label: string;
  type: string;
  value: any;
  required?: boolean;
}

export enum PipelineComponentName {
  MitieNLP = 'MitieNLP',
  SpacyNLP = 'SpacyNLP',
  WhitespaceTokenizer = 'WhitespaceTokenizer',
  SpacyTokenizer = 'SpacyTokenizer',
  MitieTokenizer = 'MitieTokenizer',
  RegexFeaturizer = 'RegexFeaturizer',
  MitieFeaturizer = 'MitieFeaturizer',
  SpacyFeaturizer = 'SpacyFeaturizer',
  CountVectorsFeaturizer = 'CountVectorsFeaturizer',
  ConveRTFeaturizer = 'ConveRTFeaturizer',
  LanguageModelFeaturizer = 'LanguageModelFeaturizer',
  KeywordIntentClassifier = 'KeywordIntentClassifier',
  MitieIntentClassifier = 'MitieIntentClassifier',
  FallbackClassifier = 'FallbackClassifier',
  LexicalSyntacticFeaturizer = 'LexicalSyntacticFeaturizer',
  EntityExtractorComponent = 'EntityExtractorComponent',
  EntitySynonymMapper = 'EntitySynonymMapper',
  MitieEntityExtractor = 'MitieEntityExtractor',
  RegexEntityExtractor = 'RegexEntityExtractor',
  SpacyEntityExtractor = 'SpacyEntityExtractor',
  ResponseSelector = 'ResponseSelector',
  DIETClassifier = 'DIETClassifier',
}

export enum PipelineComponentType {
  LanguageModel,
  Tokenizer,
  Featurizer,
  IntentClassifier,
  EntityExtractor,
  Selector,
  CombinedIntentClassifiersAndEntityExtractors,
}

export interface PipelineComponentParam {
  name: string;
  label: string;
  type: string;
  value: any;
  predefinedValues?: any[];
  required?: boolean;
}

export const PipelineComponentNamesByType = {
  [PipelineComponentType.LanguageModel]: [
    PipelineComponentName.MitieNLP,
    PipelineComponentName.SpacyNLP,
  ],
  [PipelineComponentType.Tokenizer]: [
    PipelineComponentName.WhitespaceTokenizer,
    PipelineComponentName.SpacyTokenizer,
    PipelineComponentName.MitieTokenizer,
  ],
  [PipelineComponentType.Featurizer]: [
    PipelineComponentName.RegexFeaturizer,
    PipelineComponentName.MitieFeaturizer,
    PipelineComponentName.SpacyFeaturizer,
    PipelineComponentName.CountVectorsFeaturizer,
    PipelineComponentName.ConveRTFeaturizer,
    PipelineComponentName.LanguageModelFeaturizer,
    PipelineComponentName.LexicalSyntacticFeaturizer,
  ],
  [PipelineComponentType.IntentClassifier]: [
    PipelineComponentName.KeywordIntentClassifier,
    PipelineComponentName.MitieIntentClassifier,
    PipelineComponentName.FallbackClassifier,
  ],
  [PipelineComponentType.EntityExtractor]: [
    PipelineComponentName.EntityExtractorComponent,
    PipelineComponentName.EntitySynonymMapper,
    PipelineComponentName.MitieEntityExtractor,
    PipelineComponentName.RegexEntityExtractor,
    PipelineComponentName.SpacyEntityExtractor,
  ],
  [PipelineComponentType.Selector]: [PipelineComponentName.ResponseSelector],
  [PipelineComponentType.CombinedIntentClassifiersAndEntityExtractors]: [
    PipelineComponentName.DIETClassifier,
  ],
};
