import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PythonSavedData, PythonStore } from '@projectTypes/Python';
import Store from '@projectTypes/Store';
import pythonService from '@services/PythonService';
import actionNameCreator from '@helpers/actionNameCreator';
import { isProjectPython } from '@/selectors';
import { setInfo } from './errorSlices';

const pythonSliceInitialState: PythonStore = {
  isLoading: undefined,
  isRunning: false,
  isKilling: false,
  isTesting: false,
  areTestsLoading: false,
  tests: [],
};

const pythonSlice = createSlice({
  name: 'python',
  initialState: pythonSliceInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsRunning(state, action: PayloadAction<boolean>) {
      state.isRunning = action.payload;
    },
    setIsKilling(state, action: PayloadAction<boolean>) {
      state.isKilling = action.payload;
    },
    setIsTesting(state, action: PayloadAction<boolean>) {
      state.isTesting = action.payload;
    },
    setAreTestsLoading(state, action: PayloadAction<boolean>) {
      state.areTestsLoading = action.payload;
    },
    setTests(state, action: PayloadAction<PythonSavedData[]>) {
      state.tests = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setIsRunning,
  setIsKilling,
  setIsTesting,
  setAreTestsLoading,
  setTests,
} = pythonSlice.actions;
export default pythonSlice.reducer;

const anc = actionNameCreator('PYTHON');

export const loadPython = createAsyncThunk(
  anc('loadPython'),
  async (_: undefined, { dispatch, getState }) => {
    const store = getState() as Store;
    pythonService.registerRunningListener((v) => {
      if (v) {
        dispatch(setIsKilling(false));
      }

      dispatch(setIsRunning(v));
    });

    if (store.python.isLoading) return;
    if (!isProjectPython(store)) {
      return;
    }

    dispatch(setIsLoading(true));
    await pythonService.load();
    dispatch(setIsLoading(false));
  },
);

export const getTests = createAsyncThunk<
  void,
  { platformId: string; templateSlug: string; sendOldEventOnly?: boolean }
>(
  anc('getTests'),
  async ({ platformId, templateSlug, sendOldEventOnly }, { dispatch }) => {
    dispatch(setAreTestsLoading(true));
    try {
      const data = await pythonService.getTests(platformId, templateSlug);
      data.sort(
        (a, b) => +new Date(a?.createdAt || 0) - +new Date(b?.createdAt || 0),
      );
      if (sendOldEventOnly && data.length) {
        const lastTest = data[data.length - 1];
        const passing = JSON.parse(lastTest.result).reduce((acc, cur) => {
          const parts = cur.split('/');
          return acc && parts[0] === parts[1];
        }, true);

        window.top.postMessage(
          {
            event: 'editor_run_tests',
            templateSlug,
            ...lastTest,
            passing,
          },
          '*',
        );
      } else {
        dispatch(setTests(data));
      }
    } catch (e) {
      dispatch(
        setInfo({
          title: 'Test loading error',
          text: e.message,
          status: 'error',
        }),
      );
    }
    dispatch(setAreTestsLoading(false));
  },
);
