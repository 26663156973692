import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useDispatch from '@hooks/useDispatch';
import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { fillPipeline } from '@slices/pipelineSlices';
import File from '@projectTypes/File';
import { PipelineComponentType } from '@projectTypes/Pipeline';
import { pipelineSelector, pipelineFileDataSelector } from '@selectors';

import NewComponentForm from './NewComponentForm';
import PipelineComponentContainer from './PipelineComponentContainer';
import PipelineStatusContainer from './PipelineStatusContainer';

interface PipelineEditorProps {
  displayedFile: File;
  onChange: (value: string) => void;
}

const PipelineEditor = (props: PipelineEditorProps) => {
  const { displayedFile, onChange } = props;
  const dispatch = useDispatch();
  const pipelineState = useSelector(pipelineSelector);
  const pipelineFileData = useSelector(pipelineFileDataSelector);
  const [prevDisplayedFileId, setPrevDisplayedFileId] = useState('');
  const [prevFileCounter, setPrevFileCounter] = useState(-1);

  useEffect(() => {
    if (
      displayedFile &&
      (prevDisplayedFileId !== displayedFile.id ||
        displayedFile.fileCounter !== prevFileCounter)
    ) {
      setPrevDisplayedFileId(displayedFile.id);
      setPrevFileCounter(displayedFile.fileCounter);
      dispatch(fillPipeline(displayedFile));
    }
  }, [displayedFile]);

  useEffect(() => {
    onChange(pipelineFileData);
  }, [pipelineState.components]);

  return (
    <Box p="10px">
      <Stack spacing={4}>
        <Heading color="white">RASA pipeline</Heading>
        <PipelineStatusContainer />
        {Object.keys(PipelineComponentType)
          .filter((v) => Number.isNaN(Number(v)))
          .map((pipelineComponentType: string) => (
            <Fragment key={pipelineComponentType}>
              <Text fontSize="2xl" color="white">
                {pipelineComponentType}
              </Text>
              <DndProvider backend={HTML5Backend}>
                <PipelineComponentContainer
                  pipelineComponentType={
                    PipelineComponentType[pipelineComponentType]
                  }
                />
              </DndProvider>
              <NewComponentForm
                pipelineComponentType={
                  PipelineComponentType[pipelineComponentType]
                }
              />
            </Fragment>
          ))}
      </Stack>
    </Box>
  );
};

export default PipelineEditor;
