import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {
    background: '#E6E6E6',
    background2: '#1E1E1E',
    itemBackground: '#CCC',
    primary: '#0465AC',
    primaryLighter: '#0465ACDD',
    primaryLighterNoA: '#2579b7',
    secondary: '#FF6230',
    secondaryLighter: '#FF6230DD',
    danger: '#FF0000',
    warning: '#f19200',
    // gray: '#747474',
    white: '#FFFFFF',
  },
  styles: {
    global: {
      '.infoRender *': {
        color: 'black !important',
        background: 'white !important',
      },
    },
  },
});

export default theme;
