import { combineReducers } from '@reduxjs/toolkit';
import projectReducer from './projectSlices';
import fileReducer from './fileSlices';
import terminalReducer from './terminalSlices';
import userReducer from './userSlices';
import pipelineSlices from './pipelineSlices';
import policySlices from './policySlices';
import errorReducer from './errorSlices';
import trackerSlices from './trackerSlices';
import realTimeEditSlices from './realTimeEditSlices';
import globalSlices from './globalSlices';
import pythonSlices from './pythonSlices';
import editorSlices from './editorSlices';
import streamSlice from './streamSlice';
import deviceSlices from './deviceSlices';
import commitSlices from './commitSlices';

const rootReducer = combineReducers({
  project: projectReducer,
  file: fileReducer,
  terminal: terminalReducer,
  user: userReducer,
  error: errorReducer,
  pipeline: pipelineSlices,
  policy: policySlices,
  tracker: trackerSlices,
  realTimeEdit: realTimeEditSlices,
  global: globalSlices,
  python: pythonSlices,
  editor: editorSlices,
  stream: streamSlice,
  device: deviceSlices,
  commit: commitSlices,
});

const resettableRootReducer = <K extends { type: unknown }>(
  state: ReturnType<typeof rootReducer>,
  action: K,
) => {
  if (action.type === 'store/reset') {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export default resettableRootReducer;
