import SelectorComponent from './SelectorComponent';

export default class ResponseSelector extends SelectorComponent {
  params = [
    {
      name: 'epochs',
      label: 'Epochs',
      type: 'number',
      value: 100,
      required: true,
    },
    {
      name: 'constrain_similarities',
      label: 'Constrain similarities',
      type: 'boolean',
      value: true,
      required: true,
    },
  ];
}
