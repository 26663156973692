import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Subscription } from 'rxjs';
import { captureException } from '@sentry/nextjs';

import {
  Message,
  MessageDirection,
  ReceivedMessage,
  RunFrom,
  TerminalStore,
  TerminalStrategyType,
  UploadType,
  BootChatDto,
  PcbType,
} from '@projectTypes/Terminal';
import Project from '@projectTypes/Project';
import Store from '@projectTypes/Store';
import terminalService from '@services/TerminalService';
import userActionService from '@services/UserActionService';
import actionNameCreator from '@helpers/actionNameCreator';
import { setProjectRunId } from '@slices/projectSlices';
import actionService from '@services/ActionService';
import desktopService from '@services/DesktopService';
import config from '@config';
import {
  strategyIsArduino,
  strategyIsEmbedPy,
} from '@services/TerminalService/helpers';
import { TestData } from '@projectTypes/Python';
import File from '@projectTypes/File';

import isRejectedAction from './helpers/isRejectedAction';
import {
  getProjectFiles,
  saveFiles,
  saveProjectFiles,
  setNewFileContent,
} from './fileSlices';
import { checkPipeline } from './pipelineSlices';
import { setIsTesting } from './pythonSlices';
import delay from '@/helpers/delay';

const terminalInitialState: TerminalStore = {
  messages: [],
  isLoading: false,
  isBooting: false,
  isWaitingResponse: false,
  isWaitingForInput: false,
  showTerminal: false,
  minimizeTerminal: false,
  showChat: false,
  removeIsWaitingResponseOnNextMessage: false,
  lastCompileId: null,
  espDevice: null,
  shouldCompileModal: false,
  testResults: [],
  isDesktopLive: false,
  isHexapodReady: false,
  showUsbUploadButton: false,
  pcbType: PcbType.v1,
};

const userSlice = createSlice({
  name: 'terminal',
  initialState: terminalInitialState,
  reducers: {
    addMessage(state, action: PayloadAction<Message>) {
      state.messages.push(action.payload);
    },
    modifyLastMessage(state, action: PayloadAction<string>) {
      state.messages[state.messages.length - 1].input = action.payload;
    },
    setIsWaitingResponse(state, action: PayloadAction<boolean>) {
      state.isWaitingResponse = action.payload;
    },
    setIsWaitingForInput(state, action: PayloadAction<boolean>) {
      state.isWaitingForInput = action.payload;
    },
    setIsBooting(state, action: PayloadAction<boolean>) {
      state.isBooting = action.payload;
    },
    setRemoveIsWaitingResponseOnNextMessage(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.removeIsWaitingResponseOnNextMessage = action.payload;
    },
    clearMessages(state) {
      state.messages = [];
      state.isWaitingForInput = false;
      state.testResults = undefined;
    },
    setShowTerminal(state, action: PayloadAction<boolean>) {
      state.showTerminal = action.payload;
      state.minimizeTerminal = false;
      if (action.payload === false) {
        terminalService.closeTerminal();
      }
    },
    setMinimizeTerminal(state, action: PayloadAction<boolean>) {
      state.minimizeTerminal = action.payload;
    },
    setShowChat(state, action: PayloadAction<boolean>) {
      state.showChat = action.payload;
      if (action.payload === false) {
        terminalService.closeChat();
      }
    },
    showTerminalOnly(state) {
      if (state.showChat) {
        state.messages = [];
      }
      state.showTerminal = true;
      state.showChat = false;
    },
    showChatOnly(state) {
      if (state.showTerminal) {
        state.messages = [];
      }
      state.showChat = true;
      state.showTerminal = false;
    },
    setLastCompileId(state, action: PayloadAction<string | null>) {
      state.lastCompileId = action.payload;
    },
    setEspDevice(state, action: PayloadAction<any>) {
      state.espDevice = action.payload;
    },
    setShouldCompileModal(state, action: PayloadAction<boolean>) {
      state.shouldCompileModal = action.payload;
    },
    setTestResults(state, action: PayloadAction<TestData[] | undefined>) {
      state.testResults = action.payload;
    },
    setIsDesktopLive(state, action: PayloadAction<boolean>) {
      state.isDesktopLive = action.payload;
    },
    setIsHexapodReady(state, action: PayloadAction<boolean>) {
      state.isHexapodReady = action.payload;
    },
    setShowUsbUploadButton(state, action: PayloadAction<boolean>) {
      state.showUsbUploadButton = action.payload;
    },
    setPcbType(state, action: PayloadAction<PcbType>) {
      state.pcbType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return (
          action.type.toLowerCase().startsWith('terminal') &&
          isRejectedAction(action)
        );
      },
      (state, action) => {
        // TODO: Handle this SENTRY
        captureException(action.error);
        state.messages.push({
          error: action.error.message || action.error.error,
          direction: MessageDirection.Inbound,
          date: new Date().getTime(),
        });
        state.isWaitingResponse = false;
        state.isLoading = false;
      },
    );
  },
});

export const {
  addMessage,
  modifyLastMessage,
  clearMessages,
  setShowTerminal,
  setShowChat,
  showChatOnly,
  showTerminalOnly,
  setIsWaitingResponse,
  setIsWaitingForInput,
  setRemoveIsWaitingResponseOnNextMessage,
  setMinimizeTerminal,
  setIsBooting,
  setLastCompileId,
  setEspDevice,
  setShouldCompileModal,
  setTestResults,
  setIsDesktopLive,
  setIsHexapodReady,
  setShowUsbUploadButton,
  setPcbType,
} = userSlice.actions;
export default userSlice.reducer;

const anc = actionNameCreator('TERMINAL');

let subscription: Subscription;

export const subscribeToTerminalMessages = createAsyncThunk(
  anc('subscribeToTerminalMessages'),
  async (_, { dispatch, getState }) => {
    // prettier-ignore
    const terminalObservable = await terminalService.subscribeToTerminalMessages();
    if (subscription) {
      subscription.unsubscribe();
    }

    subscription = terminalObservable.subscribe((message: ReceivedMessage) => {
      if (message.type === 'input') {
        dispatch(
          addMessage({
            content: message.input,
            direction: MessageDirection.Inbound,
            date: new Date().getTime(),
          }),
        );
        dispatch(setIsWaitingForInput(true));
        return;
      }

      if (message.type === 'exit') {
        dispatch(setIsWaitingForInput(false));
        return;
      }

      const state = getState() as any;
      if (state.terminal.removeIsWaitingResponseOnNextMessage) {
        dispatch(setIsWaitingResponse(false));
        dispatch(setRemoveIsWaitingResponseOnNextMessage(false));
      }
      if (typeof message.text === 'number') {
        message.text = (message.text as number).toString();
      }
      if (
        typeof message.text === 'string' ||
        message.link ||
        message.image ||
        message.error
      ) {
        if (message.error) {
          // TODO: Handle this SENTRY
          captureException(message.error);
        }
        dispatch(
          addMessage({
            content: typeof message.text === 'string' ? message.text : null,
            image: message.image || null,
            error: message.error
              ? typeof message.error === 'string'
                ? message.error
                : JSON.stringify(message.error)
              : null,
            charts: message.charts || null,
            link: message.link || null,
            direction: MessageDirection.Inbound,
            date: new Date().getTime(),
            style: message.style,
            debug: message.debug || null,
            buttons: message.buttons,
            join: message.join,
          }),
        );
      }
    });
  },
);

type MInput = { message: string; payload: string };
type MInputType = string | MInput;
const getMessageContent = (m: MInputType): string => (m as any).message || m;
const getMessagePayload = (m: MInputType): string => (m as any).payload || m;

export const sendTerminalMessage = createAsyncThunk<unknown, MInputType>(
  anc('sendTerminalMessage'),
  async (content, { dispatch }) => {
    dispatch(setIsWaitingResponse(true));
    dispatch(setRemoveIsWaitingResponseOnNextMessage(true));
    const message = {
      content: getMessageContent(content),
      direction: MessageDirection.Outbound,
      date: new Date().getTime(),
    };

    dispatch(addMessage(message));
    terminalService.sendMessage(getMessagePayload(content));
  },
);

export const sendChatMessage = createAsyncThunk<unknown, MInputType>(
  anc('sendChatMessage'),
  async (content, { dispatch, getState }) => {
    const isBooting = (getState() as Store).terminal.isBooting;
    if (isBooting) {
      return;
    }
    dispatch(setRemoveIsWaitingResponseOnNextMessage(true));
    dispatch(sendTerminalMessage(content));
  },
);

export const killPython = createAsyncThunk(
  anc('killPython'),
  async (_, { dispatch }) => {
    dispatch(setIsWaitingForInput(false));
    const type = await terminalService.killPython();

    dispatch(
      addMessage({
        date: new Date().getTime(),
        direction: MessageDirection.Inbound,
        error: `Program killed${
          type === 'hard' ? ' and worker restarted' : ''
        }`,
      }),
    );
  },
);

export const sendPythonMessage = createAsyncThunk(
  anc('sendPythonMessage'),
  async (content: string, { dispatch }) => {
    dispatch(modifyLastMessage(content));

    dispatch(setIsWaitingForInput(false));
    terminalService.sendInput(content);
  },
);

export const runFile = createAsyncThunk<
  void,
  { fileId: string; projectId: string }
>(anc('runFile'), async ({ fileId, projectId }, { dispatch, getState }) => {
  await dispatch(saveFiles([fileId]));
  dispatch(showTerminalOnly());
  dispatch(clearMessages());

  const message = {
    content: `Running file`,
    direction: MessageDirection.Outbound,
    date: new Date().getTime(),
  };

  userActionService.trackAction('editor_run_file', { projectId, fileId });

  dispatch(addMessage(message));
  dispatch(setIsWaitingResponse(true));
  dispatch(setRemoveIsWaitingResponseOnNextMessage(true));

  const fileCode = (getState() as Store).file.files.find(
    (f) => f.id === fileId,
  );

  setTimeout(
    () => terminalService.runFile(projectId, fileId, fileCode?.content),
    0,
  );
});

export const testFile = createAsyncThunk<
  void,
  { fileId: string; templateId?: string }
>(anc('testFile'), async ({ fileId, templateId }, { dispatch, getState }) => {
  await dispatch(saveFiles([fileId]));
  dispatch(showTerminalOnly());
  dispatch(clearMessages());

  dispatch(setIsTesting(true));

  const message = {
    content: `Testing file`,
    direction: MessageDirection.Outbound,
    date: new Date().getTime(),
  };

  dispatch(addMessage(message));
  dispatch(setIsWaitingResponse(true));
  dispatch(setRemoveIsWaitingResponseOnNextMessage(true));

  const files = (getState() as Store).file.files;
  const templates = (getState() as Store).project.templates;
  const template = templates.find((t) => t.id === templateId);
  const templateLanguage = 'en'; // template.slug.endsWith('_en') ? 'en' : 'hr';
  const templateSlug = template?.slug || '';
  const file = files.find((f) => f.id === fileId);
  if (!file.content) {
    dispatch(addMessage({ ...message, content: 'Error loading file' }));
    return;
  }

  const test = files.find(
    (f) => f.name === 'test.json' && f.projectId === file.projectId,
  );
  if (!test.content) {
    dispatch(addMessage({ ...message, content: 'Error loading test' }));
    return;
  }

  setTimeout(() => {
    terminalService
      .testFile(file.content, test.content, file.projectId, templateLanguage)
      .then((data) => {
        if (data) {
          dispatch(setTestResults(data));
          const runId = data[0].runId || '';
          const passing =
            data.findIndex((test) => {
              // eslint-disable-next-line no-restricted-syntax
              for (const r of test.results) {
                if (!r.test_pass) {
                  return true;
                }
              }
              return false;
            }) === -1;
          const results = `[${data
            .reduce((p, c) => {
              p.push(
                `"${c.results.filter((r) => r.test_pass).length}/${
                  c.results.length
                }"`,
              );
              return p;
            }, [])
            .join(', ')}]`;

          userActionService.trackAction('editor_run_tests', {
            runId,
            fileId,
            projectId: file.projectId,
            passing,
            results,
          });
          window.top.postMessage(
            {
              event: 'editor_run_tests',
              runId,
              fileId,
              projectId: file.projectId,
              passing,
              results,
              templateSlug,
            },
            '*',
          );
        } else {
          userActionService.trackAction('editor_run_tests', {
            fileId,
            projectId: file.projectId,
            passing: false,
            error: 'no test data returned',
          });
        }

        dispatch(setIsTesting(false));
      });
  }, 0);
});

export const runProject = createAsyncThunk(
  anc('runProject'),
  async (project: Project, { dispatch }) => {
    dispatch(showTerminalOnly());
    dispatch(clearMessages());
    const message = {
      content: `Running ${project.name}`,
      direction: MessageDirection.Outbound,
      date: new Date().getTime(),
    };
    userActionService.trackAction('editor_run_project', {
      projectId: project.id,
    });
    dispatch(addMessage(message));
    terminalService.runProject(project.id);
    dispatch(setIsWaitingResponse(true));
  },
);

export const bootChat = createAsyncThunk<
  void,
  {
    runId: string;
    from: RunFrom;
  }
>(anc('bootChat'), async ({ runId, from }, { dispatch, getState }) => {
  // TODO: quickfix for selectProject in useOnAppInit
  terminalService.selectTerminalStrategy(TerminalStrategyType.Chatbot);
  const isBooting = (getState() as Store).terminal.isBooting;
  if (isBooting) {
    return;
  }
  dispatch(setIsBooting(true));
  dispatch(showChatOnly());
  dispatch(
    addMessage({
      content: `Chatbot is warming up, please wait...`,
      direction: MessageDirection.Outbound,
      date: new Date().getTime(),
    }),
  );
  let bootStatus: BootChatDto | undefined;
  try {
    bootStatus = await terminalService.bootChat(runId, from);
  } catch (e) {
    // TODO: Handle this SENTRY
    captureException(e);
  }
  dispatch(
    addMessage({
      content: bootStatus
        ? bootStatus.bootMessage || config.defaultBootMessage
        : 'Chat not started, something went wrong',
      direction: MessageDirection.Inbound,
      date: new Date().getTime(),
    }),
  );
  dispatch(setIsBooting(false));
});

export const trainProject = createAsyncThunk<
  void,
  { project: Project; shouldBootChat?: boolean; shouldShare?: boolean }
>(
  anc('trainProject'),
  async ({ project, shouldBootChat, shouldShare }, { dispatch, getState }) => {
    const userRole = (getState() as Store).user.role;
    dispatch(checkPipeline());
    dispatch(setIsWaitingResponse(true));
    await dispatch(saveProjectFiles(project.id));
    dispatch(showTerminalOnly());
    dispatch(clearMessages());
    dispatch(
      addMessage({
        content: `Train project ${project.name}`,
        direction: MessageDirection.Outbound,
        date: new Date().getTime(),
      }),
    );
    userActionService.trackAction('editor_train_start', {
      projectId: project.id,
    });
    const trainingId = await terminalService.trainProject(project.id);
    userActionService.trackAction('editor_train_project', {
      projectId: project.id,
      trainingId,
    });

    // TODO: rename runId from here
    // runId is from boot
    dispatch(setProjectRunId({ runId: trainingId, projectId: project.id }));
    if (shouldBootChat) {
      dispatch(bootChat({ runId: trainingId, from: RunFrom.editorChat }));
    }
    if (shouldShare) {
      try {
        const publish = userRole === 'Admin';
        await terminalService.shareProjectTrainingId(trainingId, publish);
      } catch (e: any) {
        // TODO: Handle this SENTRY
        captureException(e);

        dispatch(
          addMessage({
            direction: MessageDirection.Inbound,
            date: new Date().getTime(),
            error: e.message,
          }),
        );
      }
    }
    dispatch(setIsWaitingResponse(false));
  },
);

export const publishProject = createAsyncThunk<void, { trainingId: string }>(
  anc('publishProject'),
  async ({ trainingId }) => {
    await terminalService.shareProjectTrainingId(trainingId, true);
  },
);
export const getPcbTypeFromFile = createAsyncThunk<void, { file: File }>(
  anc('getPcbTypeFromFile'),
  async ({ file }, { dispatch }) => {
    const fileLines = file.newContent.split('\n');
    const fileLine = fileLines.find((line) =>
      line.includes('#define PCB_TYPE'),
    );
    if (fileLine) {
      const newPcbType = fileLine
        .replace('#define PCB_TYPE', '')
        .replaceAll(' ', '')
        .toLowerCase();
      if (newPcbType === 'v1') {
        dispatch(setPcbType(PcbType.v1));
      } else if (newPcbType === 'v2') {
        dispatch(setPcbType(PcbType.v2));
      }
    }
  },
);

export const addPcbType = createAsyncThunk<void, { file: File }>(
  anc('addPcbType'),
  async ({ file }, { dispatch, getState }) => {
    const pcbType = (getState() as Store).terminal.pcbType;
    const fileLines = file.newContent.split('\n');
    const fileLineIndex = fileLines.findIndex((line) =>
      line.includes('#define PCB_TYPE'),
    );
    if (fileLineIndex !== -1) {
      fileLines[fileLineIndex] = `#define PCB_TYPE ${pcbType}`;
    } else {
      fileLines.unshift(`#define PCB_TYPE ${pcbType}`);
    }
    dispatch(
      setNewFileContent({ fileId: file.id, newContent: fileLines.join('\n') }),
    );
  },
);

export const compileProject = createAsyncThunk<
  void,
  { project: Project; ota: boolean; uploadType: UploadType; hardReset: boolean }
>(
  anc('compileProject'),
  async ({ project, ota, uploadType, hardReset }, { dispatch, getState }) => {
    const file = (getState() as Store).file.files.find(
      (file) =>
        file.projectId === project.id &&
        file.name === config.mainArduinoFileName,
    );
    if (!file) {
      throw new Error('No project files');
    }
    await dispatch(saveProjectFiles(project.id));
    dispatch(showTerminalOnly());
    dispatch(clearMessages());
    // User action is needed here
    if (uploadType === UploadType.USB) {
      if (
        strategyIsArduino(
          terminalService.terminalStrategyType,
          terminalService.terminalStrategy,
        )
      ) {
        await terminalService.terminalStrategy.requestPort();
      }
    }
    dispatch(setRemoveIsWaitingResponseOnNextMessage(false));
    dispatch(setIsWaitingResponse(true));
    dispatch(
      addMessage({
        content: `Compile project ${project.name}`,
        direction: MessageDirection.Outbound,
        date: new Date().getTime(),
      }),
    );
    try {
      dispatch(setLastCompileId(null));
      if (!hardReset) {
        userActionService.trackAction('editor_compile_start', {
          projectId: project.id,
        });
        let pcbType = (getState() as Store).terminal.pcbType;
        // TODO: make this editable in admin
        const isVidiX = project.sectionName === 'VidiX';
        const skipValidation = isVidiX;
        if (isVidiX) {
          pcbType = PcbType.vidix;
        }
        const r = await terminalService.compileProject(
          project.id,
          file.newContent,
          ota,
          pcbType,
          skipValidation,
        );
      }
      if (uploadType === UploadType.Mobile) {
        dispatch(setLastCompileId(project.id));
      } else {
        await dispatch(flashEsp({ projectId: project.id, hardReset }));
      }
      userActionService.trackAction('editor_compile_project', {
        projectId: project.id,
      });
      dispatch(setIsWaitingResponse(false));
      dispatch(
        addMessage({
          content: `\n✅ Compilation successful`,
          direction: MessageDirection.Outbound,
          date: new Date().getTime(),
        }),
      );
    } catch (e) {
      dispatch(setIsWaitingResponse(false));
      dispatch(
        addMessage({
          content: `\n❌ Compilation failed`,
          direction: MessageDirection.Outbound,
          date: new Date().getTime(),
        }),
      );
      throw e;
    }
  },
);

export const validateAction = createAsyncThunk<
  void,
  { projectId: string; action: string; actionName: string; tracker: string }
>(
  anc('validateAction'),
  async ({ projectId, action, actionName, tracker }, { dispatch }) => {
    dispatch(setIsWaitingResponse(true));
    dispatch(showTerminalOnly());
    dispatch(clearMessages());
    dispatch(
      addMessage({
        content: `Validate action ${actionName}`,
        direction: MessageDirection.Outbound,
        date: new Date().getTime(),
      }),
    );
    const data = await actionService.validate(
      projectId,
      action,
      actionName,
      tracker,
    );
    userActionService.trackAction('editor_validate_action', {
      projectId,
      actionName,
      ok: data.ok,
    });
    dispatch(
      addMessage({
        content: data.ok ? 'ok' : 'not ok',
        direction: MessageDirection.Inbound,
        date: new Date().getTime(),
      }),
    );
    dispatch(setIsWaitingResponse(false));
  },
);

export const runAction = createAsyncThunk<
  void,
  { projectId: string; action: string; actionName: string; tracker: string }
>(
  anc('runAction'),
  async ({ projectId, action, actionName, tracker }, { dispatch }) => {
    await dispatch(saveProjectFiles(projectId));
    dispatch(setIsWaitingResponse(true));
    dispatch(showTerminalOnly());
    dispatch(clearMessages());
    dispatch(
      addMessage({
        content: `Run action ${actionName}`,
        direction: MessageDirection.Outbound,
        date: new Date().getTime(),
      }),
    );
    const data = await actionService.run(
      projectId,
      action,
      actionName,
      tracker,
    );
    userActionService.trackAction('editor_run_action', { actionName });
    if (data.out.result?.output?.length) {
      Object.values(data.out.result?.output).forEach((response) => {
        dispatch(
          addMessage({
            action: response,
            direction: MessageDirection.Inbound,
            date: new Date().getTime(),
          }),
        );
      });
    }
    if (data.out.result?.stdout?.length) {
      data.out.result?.stdout.forEach((print) => {
        dispatch(
          addMessage({
            content: print.data,
            direction: MessageDirection.Inbound,
            date: new Date().getTime(),
          }),
        );
      });
    }
    if (data.out.traceback) {
      dispatch(
        addMessage({
          error: data.out.traceback,
          direction: MessageDirection.Inbound,
          date: new Date().getTime(),
        }),
      );
    } else if (
      data.out.result?.response?.length === 0 &&
      data.out.result?.stdout?.length === 0
    ) {
      dispatch(
        addMessage({
          content: data.ok ? 'ok' : 'not ok',
          direction: MessageDirection.Inbound,
          date: new Date().getTime(),
        }),
      );
    }
    dispatch(setIsWaitingResponse(false));
    dispatch(getProjectFiles(projectId));
  },
);

export const runChat = createAsyncThunk(
  anc('runChat'),
  async (project: Project, { dispatch }) => {
    if (!project.runId) {
      return;
    }
    dispatch(clearMessages());
    dispatch(bootChat({ runId: project.runId, from: RunFrom.editorChat }));
  },
);

export const runLogs = createAsyncThunk(
  anc('runLogs'),
  async (_, { dispatch, getState }) => {
    dispatch(clearMessages());
    terminalService.selectTerminalStrategy(TerminalStrategyType.EmbeddedPython);
    const projectId = (getState() as Store).project.selectedProjectId;
    const device = (getState() as Store).device.connectedDevice;
    if (device) {
      if (
        strategyIsEmbedPy(
          terminalService.terminalStrategyType,
          terminalService.terminalStrategy,
        )
      ) {
        terminalService.terminalStrategy.setDeviceId(device.id, projectId);
      }
    }
    await terminalService.bootChat('', RunFrom.editorChat);
    dispatch(setShowTerminal(true));
  },
);

export const setupEspDevice = createAsyncThunk(
  anc('setupEspDevice'),
  async (_, { dispatch }) => {
    dispatch(clearMessages());
    dispatch(
      addMessage({
        content: 'Serial Monitor',
        direction: MessageDirection.Outbound,
        date: new Date().getTime(),
      }),
    );
    dispatch(showTerminalOnly());
    dispatch(setIsWaitingResponse(true));
    try {
      const device = await terminalService.setupEspDevice(() => {
        dispatch(setEspDevice(null));
        dispatch(
          addMessage({
            error: 'Device disconnected',
            direction: MessageDirection.Inbound,
            date: new Date().getTime(),
          }),
        );
      });
      dispatch(setEspDevice(JSON.parse(JSON.stringify(device))));
      dispatch(
        addMessage({
          content:
            'Serial monitor connected. Restart device if there are no logs.\n',
          direction: MessageDirection.Inbound,
          date: new Date().getTime(),
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(setEspDevice(null));
      dispatch(
        addMessage({
          error: 'Something went wrong.',
          direction: MessageDirection.Inbound,
          date: new Date().getTime(),
        }),
      );
    } finally {
      dispatch(setIsWaitingResponse(false));
    }
  },
);

export const disconnectEspDevice = createAsyncThunk(
  anc('disconnectEspDevice'),
  async (_, { dispatch }) => {
    await terminalService.disconnectEspDevice();
    dispatch(clearMessages());
    dispatch(setShowTerminal(false));
    dispatch(setEspDevice(null));
  },
);

export const flashEsp = createAsyncThunk(
  anc('flashEsp'),
  async (
    { projectId, hardReset }: { projectId: string; hardReset: boolean },
    { dispatch, getState },
  ) => {
    const { espDevice, pcbType } = (getState() as Store).terminal;
    if (!espDevice) {
      // await dispatch(setupEspDevice());
    }
    dispatch(setIsWaitingResponse(true));
    dispatch(setShowUsbUploadButton(true));
    try {
      await terminalService.flashEsp({
        projectId,
        hardReset,
        version: pcbType,
      });
      dispatch(
        addMessage({
          content: `\n✅ Firmware flashed`,
          direction: MessageDirection.Outbound,
          date: new Date().getTime(),
        }),
      );
    } catch (e) {
      dispatch(
        addMessage({
          content: `\n❌ Flash failed`,
          direction: MessageDirection.Outbound,
          date: new Date().getTime(),
        }),
      );
    }
    dispatch(setIsWaitingResponse(false));
    dispatch(setEspDevice(null));
  },
);

export const uploadUsingDesktopApp = createAsyncThunk(
  anc('uploadUsingDesktopApp'),
  async (_, { dispatch, getState }) => {
    const { lastCompileId } = (getState() as Store).terminal;
    if (!lastCompileId) {
      console.log('No last compile id');
      return;
    }
    dispatch(showTerminalOnly());

    const message = {
      content: `Upload using Desktop App`,
      direction: MessageDirection.Outbound,
      date: new Date().getTime(),
    };

    dispatch(addMessage(message));
    dispatch(setIsWaitingResponse(true));
    desktopService.onLogs = async (logs) => {
      logs.logs.forEach((log) => {
        const msg = {
          content: undefined,
          error: undefined,
          direction:
            log.type === 'out'
              ? MessageDirection.Outbound
              : MessageDirection.Inbound,
          date: new Date().getTime(),
        };
        if (log.type === 'err') {
          msg.error = log.data;
        } else {
          msg.content = log.data;
        }
        dispatch(addMessage(msg));
        if (
          log.data.includes('Hard resetting via RTS pin') ||
          log.type === 'err'
        ) {
          dispatch(setIsWaitingResponse(false));
        }
      });
    };
    await desktopService.upload(lastCompileId);
  },
);
