import BaseApiService from '@services/BaseApiService';
import { FileData, FileDto } from '@projectTypes/File';

export default class ApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/projects',
    });
  }

  async createFile(data: FileData) {
    const { name, content, contentType, projectId, path } = data;
    const response = await this.axiosInstance.post<FileDto>(
      this.route(projectId),
      {
        name,
        content,
        contentType,
        path,
      },
    );
    return response.data;
  }

  async getProjectFiles(projectId: string, withCredentials?: boolean) {
    const response = await this.axiosInstance.get<FileDto[]>(
      this.route(projectId) + (withCredentials ? window.location.search : ''),
    );
    return response.data;
  }

  async getFile(projectId: string, id: string) {
    const response = await this.axiosInstance.get<FileDto>(
      this.route(projectId, id),
    );
    return response.data;
  }

  async getFileBlob(projectId: string, id: string) {
    const response = await this.axiosInstance.get<Blob>(
      this.route(projectId, id) + '/blob',
      { responseType: 'blob' },
    );
    return response.data;
  }

  async updateFile(id: string, projectId: string, data: Partial<FileData>) {
    const response = await this.axiosInstance.patch<FileDto>(
      this.route(projectId, id),
      {
        ...data,
      },
    );
    return response.data;
  }

  async updateFileBlob(id: string, projectId: string, blob: Blob) {
    const formData = new FormData();
    formData.append('blob', blob);
    const response = await this.axiosInstance.patch<FileDto>(
      this.route(projectId, id),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  }

  async deleteFile(id: string, projectId: string) {
    const response = await this.axiosInstance.delete<any>(
      this.route(projectId, id),
    );
    return response.data;
  }

  async resetFile(id: string, projectId: string) {
    const response = await this.axiosInstance.patch<FileDto>(
      `${this.route(projectId, id)}/reset`,
    );
    return response.data as FileDto;
  }

  private route(projectId: string, id?: string) {
    return `/${projectId}/files/${id ? id : ''}`;
  }
}
