import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import Project, {
  InfoPartOfProject,
  ProjectInfo,
  ProjectNamespace,
  ProjectStore,
  ProjectType,
  Template,
} from '@projectTypes/Project';
import Store from '@projectTypes/Store';
import { TerminalStrategyType } from '@projectTypes/Terminal';
import projectService from '@services/ProjectService';
import terminalService from '@services/TerminalService';
import userActionService from '@services/UserActionService';
import actionNameCreator from '@helpers/actionNameCreator';
import getQueryStringValue from '@helpers/getQueryStringValue';
import config from '@config';
// TODO: refactor out
import { InfoObject } from '@components/Editor/ProjectInfoEditor/util/handleInfo';
import desktopService from '@services/DesktopService';
import fileService from '@services/FileService';

import {
  closeAllFiles,
  getProjectFiles,
  getProjectTemplateFiles,
  setShowFileDiff,
} from './fileSlices';
import {
  selectRealTimeProject,
  setUserFilePositions,
} from './realTimeEditSlices';
import { setInfo } from './errorSlices';
import { getTests } from './pythonSlices';
import {
  setIsDesktopLive,
  setShowChat,
  setShowTerminal,
  setIsHexapodReady,
} from './terminalSlices';
import { getCommits } from './commitSlices';

const projectInitialState: ProjectStore = {
  selectedProjectId: null,
  showProjectDiff: false,
  projects: [],
  templates: [],
  showProjectShare: false,
  isLoading: false,
  loadingCounter: 0,
  error: null,
  showProjectInfo: false,
  isNewProjectInfo: false,
  chatInfo: null,
  hasMultiYear: false,
  showProjectModal: false,
  TargetFile: config.mainHTMLFileName,
};

const projectSlice = createSlice({
  name: 'project',
  initialState: projectInitialState,
  reducers: {
    addProject(state, action: PayloadAction<Project>) {
      const projectIndex = state.projects.findIndex(
        (project) => project.id === action.payload.id,
      );
      if (projectIndex === -1) {
        state.projects.push(action.payload);
      } else {
        state.projects[projectIndex] = action.payload;
      }
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setProjects(state, action: PayloadAction<Project[]>) {
      state.hasMultiYear =
        new Set(action.payload.map((p) => p.yearId)).size > 1;
      state.projects = action.payload;
    },
    setTemplates(state, action: PayloadAction<Template[]>) {
      state.templates = action.payload;
    },
    setSelectedProject(state, action: PayloadAction<string>) {
      state.selectedProjectId = action.payload;
      state.showProjectDiff = false;
      state.lastOpenedProject = action.payload;
      localStorage.setItem('lastOpenedProjectId', action.payload);
    },
    setLastOpenedProjectId(state, action: PayloadAction<string>) {
      state.lastOpenedProject = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.loadingCounter += 1;
      } else {
        state.loadingCounter = Math.max(state.loadingCounter - 1, 0);
      }
      state.isLoading = state.loadingCounter > 0;
    },
    setProjectRunId(
      state,
      action: PayloadAction<{ projectId: string; runId: string }>,
    ) {
      const projetIndex = state.projects.findIndex(
        (project) => project.id === action.payload.projectId,
      );
      if (projetIndex !== -1) {
        state.projects[projetIndex].runId = action.payload.runId;
      }
    },
    setShowProjectDiff(state, action: PayloadAction<boolean>) {
      state.showProjectDiff = action.payload;
    },
    setInitialProjects(state, _action: PayloadAction<void>) {
      projectService.setProjects(JSON.parse(JSON.stringify(state.projects)));
    },
    setShowProjectShare(state, action: PayloadAction<boolean>) {
      state.showProjectShare = action.payload;
    },
    setShowProjectInfo(state, action: PayloadAction<boolean>) {
      state.showProjectInfo = action.payload;
    },
    setProjectInfo(state, action: PayloadAction<InfoPartOfProject>) {
      const projetIndex = state.projects.findIndex(
        (project) => project.id === state.selectedProjectId,
      );
      if (projetIndex !== -1) {
        Object.keys(action.payload).forEach((key) => {
          state.projects[projetIndex][key] = action.payload[key];
        });
      }
    },
    setIsNewProjectInfo(state, action: PayloadAction<boolean>) {
      state.isNewProjectInfo = action.payload;
    },
    setChatInfo(state, action: PayloadAction<ProjectInfo>) {
      state.chatInfo = action.payload;
    },
    setIsProjectChanged(state, action: PayloadAction<boolean>) {
      const projetIndex = state.projects.findIndex(
        (project) => project.id === state.selectedProjectId,
      );
      if (projetIndex !== -1 && state.projects[projetIndex]) {
        state.projects[projetIndex].isChanged = action.payload;
      }
    },
    setShowProjectModal(state, action: PayloadAction<boolean>) {
      state.showProjectModal = action.payload;
    },
    setTargetFile(state, action: PayloadAction<string>) {
      state.TargetFile = action.payload != 'about:srcdoc'
        ? action.payload
        : config.mainHTMLFileName;
    },
  },
});

export const {
  addProject,
  setProjects,
  setTemplates,
  setSelectedProject,
  setIsLoading,
  setProjectRunId,
  setShowProjectDiff,
  setInitialProjects,
  setShowProjectShare,
  setShowProjectInfo,
  setProjectInfo,
  setIsNewProjectInfo,
  setChatInfo,
  setIsProjectChanged,
  setLastOpenedProjectId,
  setShowProjectModal,
  setTargetFile,
} = projectSlice.actions;
export default projectSlice.reducer;

const anc = actionNameCreator('PROJECT');

export const selectProject = createAsyncThunk<
  void,
  string | undefined,
  { state: Store }
>(anc('selectProject'), async (projectId, { dispatch, getState }) => {
  const projectState = getState().project;
  const { user, device } = getState();
  const selectedProject = projectState.projects.find((project) =>
    projectId
      ? project.id === projectId
      : project.id === projectState.selectedProjectId,
  );
  // eslint-disable-next-line no-console
  console.log({ selectedProject });
  dispatch(setShowTerminal(false));
  dispatch(setShowChat(false));
  if (selectedProject?.type === ProjectType.PLATFORMIO) {
    terminalService.selectTerminalStrategy(TerminalStrategyType.Arduino);
    desktopService.onCheckIsDesktopLive = (
      status: boolean,
      hexapodReady: boolean,
    ) => {
      dispatch(setIsDesktopLive(status));
      dispatch(setIsHexapodReady(hexapodReady));
    };
  } else if (selectedProject?.namespace === ProjectNamespace.PYTHON) {
    terminalService.selectTerminalStrategy(TerminalStrategyType.Python);
  } else if (selectedProject?.namespace === ProjectNamespace.EMBED_PYTHON) {
    terminalService.selectTerminalStrategy(TerminalStrategyType.EmbeddedPython);
    if (device.connectedDevice) {
      setTimeout(
        () =>
          dispatch(
            getCommits({
              deviceId: device.connectedDevice.id,
              userId: user.id,
              projectId,
            }),
          ),
        500,
      );
    }
  } else {
    terminalService.selectTerminalStrategy(TerminalStrategyType.Chatbot);
  }

  const prevSelectedProject = projectState.projects.find(
    (project) => project.id === projectState.selectedProjectId,
  );
  if (projectId === undefined) {
    if (projectState.selectedProjectId) {
      dispatch(setUserFilePositions({}));
      if (selectedProject?.isGroupProject) {
        dispatch(selectRealTimeProject(projectState.selectedProjectId));
      }
      dispatch(getProjectFiles(projectState.selectedProjectId));
    }
    return;
  }
  if (projectState.selectedProjectId === projectId) {
    return;
  }
  dispatch(setUserFilePositions({}));
  if (selectedProject?.isGroupProject || prevSelectedProject?.isGroupProject) {
    dispatch(selectRealTimeProject(projectId));
  }
  dispatch(closeAllFiles());
  dispatch(setSelectedProject(projectId));
  userActionService.trackAction('editor_open_project', {
    projectId,
  });
  dispatch(getProjectFiles(projectId));
  if (selectedProject.type === ProjectType.PYTHON) {
    const template = projectState.templates.find(
      (t) => t.id === selectedProject.templateId,
    );
    if (template) {
      dispatch(
        getTests({
          platformId: user.platformId,
          templateSlug: template.slug,
          sendOldEventOnly: true,
        }),
      );
    }
  } else if (selectedProject.type === ProjectType.RASA) {
    try {
      const runId = await (terminalService as any).getLastRunId(
        selectedProject.id,
      );
      if (runId) {
        dispatch(setProjectRunId({ projectId: selectedProject.id, runId }));
      }
    } catch (e) {
      // Last training not found
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
});

export const selectProjectFromTemplate = createAsyncThunk<
  void,
  string,
  { state: Store }
>(anc('selectProjectFromTemplate'), async (templateSlug, { dispatch }) => {
  dispatch(setIsLoading(true));
  const project = await projectService.getProjectByTemplate({ templateSlug });
  if (project) {
    dispatch(addProject(project));
    dispatch(selectProject(project.id));
    userActionService.trackAction('editor_open_template', {
      projectId: project.id,
    });
  }
  dispatch(setIsLoading(false));
});

export const getUserProjects = createAsyncThunk(
  anc('getUserProjects'),
  async (_, { dispatch, getState }) => {
    dispatch(setIsLoading(true));
    await dispatch(getProjectTemplates());
    const { templates } = (getState() as Store).project;
    const studentProjectId = getQueryStringValue(config.studentProjectId);
    const templateSlug = getQueryStringValue(config.templateSlug);
    const platformId = getQueryStringValue(config.platformId);
    let selectedProject: Project | null = null;
    const projects = [];
    if (typeof studentProjectId === 'string') {
      selectedProject = await projectService.getProject(studentProjectId, true);
      if (selectedProject.type === ProjectType.PYTHON && platformId) {
        const template = templates.find(
          (t) => t.id === selectedProject.templateId,
        );
        if (template) {
          dispatch(
            getTests({
              platformId: platformId as string,
              templateSlug: template.slug,
            }),
          );
        }
      }
      projects.push(selectedProject);
    } else {
      const userProjects = await projectService.getUserProjects();
      projects.push(...userProjects);
      const template = templates.find((t) => t.slug === templateSlug);
      if (
        config.groupProjectTemplateSlugs.includes(templateSlug as string) &&
        template
      ) {
        const groupProjects = userProjects.filter(
          (project) =>
            project.isGroupProject && project.templateId === template.id,
        );
        if (groupProjects.length === 1) {
          selectedProject = groupProjects[0];
        } else if (groupProjects.length === 0) {
          dispatch(
            setInfo({
              title: 'No group project',
              text:
                'You are not part of any group project yet. Please ask your teacher to create a group for you.',
              status: 'error',
            }),
          );
        } else if (groupProjects.length > 1) {
          selectedProject = groupProjects[0];
          dispatch(
            setInfo({
              title: 'More than 1 group project',
              text: `You are a part of ${groupProjects.length} group projects. We opened up the first one!`,
            }),
          );
        }
        userActionService.trackAction('editor_open_project', {
          templateSlug,
          group: true,
        });
      } else if (typeof templateSlug === 'string') {
        selectedProject = await projectService.getProjectByTemplate({
          templateSlug,
        });

        if (!selectedProject) {
          dispatch(setShowProjectModal(true));
          dispatch(
            setInfo({
              title: 'Template error',
              text: `Template not found or you don\'t have access to it`,
            }),
          );
        }

        if (
          selectedProject &&
          !projects.find((project) => project.id === selectedProject.id)
        ) {
          projects.push(selectedProject);
        }

        userActionService.trackAction('editor_open_template', { templateSlug });
      }
    }
    dispatch(setIsLoading(false));
    if (projects) {
      dispatch(setProjects(projects));
      if (selectedProject) {
        dispatch(selectProject(selectedProject.id));
        userActionService.trackAction('editor_open_project', {
          projectId: selectedProject.id,
        });
      }
    }
  },
);

export const createProject = createAsyncThunk<void, string, { state: Store }>(
  anc('createProject'),
  async (name, { dispatch, getState }) => {
    const { username } = getState().user;
    dispatch(setIsLoading(true));
    const project = await projectService.createProject(name, username);
    dispatch(setIsLoading(false));
    if (project) {
      dispatch(addProject(project));
    }
  },
);

export const updateProject = createAsyncThunk(
  anc('updateProject'),
  async (partialProject: Partial<Project>, { dispatch }) => {
    dispatch(setIsLoading(true));
    const project = await projectService.updateProject(
      partialProject.id,
      partialProject,
    );
    dispatch(setIsLoading(false));
    if (project) {
      dispatch(addProject(project));
    }
  },
);

export const showFileDiff = createAsyncThunk<void, string, { state: Store }>(
  anc('showFileDiff'),
  async (projectId, { dispatch, getState }) => {
    const project = getState().project.projects.find((p) => p.id === projectId);
    if (!project || !project.templateId) {
      throw Error('Project not found');
    }
    dispatch(setIsLoading(true));
    dispatch(getProjectTemplateFiles(project.templateId));
    dispatch(setShowFileDiff(true));
    dispatch(setIsLoading(false));
  },
);

export const showProjectDiff = createAsyncThunk<void, string, { state: Store }>(
  anc('showProjectDiff'),
  async (projectId, { dispatch, getState }) => {
    const project = getState().project.projects.find((p) => p.id === projectId);
    if (!project || !project.templateId) {
      throw Error('Project not found');
    }
    dispatch(setIsLoading(true));
    dispatch(getProjectTemplateFiles(project.templateId));
    dispatch(setShowProjectDiff(true));
    dispatch(setIsLoading(false));
  },
);

export const resetProject = createAsyncThunk(
  anc('resetProject'),
  async (projectId: string, { dispatch }) => {
    dispatch(setIsLoading(true));
    const project = await projectService.resetProject(projectId);
    userActionService.trackAction('editor_reset_project', { projectId });
    dispatch(addProject(project));
    dispatch(getProjectFiles(projectId));
    dispatch(setShowProjectDiff(false));
    dispatch(setIsLoading(false));
  },
);

export const saveProjectInfo = createAsyncThunk<
  Promise<void>,
  InfoObject,
  { state: Store }
>(anc('saveProjectInfo'), async (infoState, { dispatch, getState }) => {
  const state = getState();
  const selectedProject = state.project.projects.find(
    (project) => project.id === state.project.selectedProjectId,
  );

  const projectInfo: InfoPartOfProject = {
    botLanguage: infoState.botLanguage,
    finalProjectType: infoState.finalProjectType,
    info: JSON.stringify({
      ...infoState.info.hr,
      [config.shortDescription]: infoState.general.hr.shortDescription,
      [config.imageUrl]: infoState.general.hr.imageUrl,
      [config.projectGoal]: infoState.projectGoal,
    }),
    infoEn: JSON.stringify({
      ...infoState.info.en,
      [config.shortDescription]: infoState.general.en.shortDescription,
      [config.imageUrl]: infoState.general.en.imageUrl,
    }),
  };

  if (selectedProject) {
    await projectService.setProjectInfo(selectedProject.id, projectInfo);
    dispatch(setProjectInfo(projectInfo));
  } else {
    await Promise.resolve();
  }
});

export const getProjectTemplates = createAsyncThunk(
  anc('getProjectTemplates'),
  async (_, { dispatch }) => {
    const templates = await projectService.getProjectTemplates();
    dispatch(setTemplates(templates));
  },
);

export const getProjectInfo = createAsyncThunk(
  anc('getProjectInfo'),
  async (projectId: string, { dispatch }) => {
    const projectInfo = await projectService.getProjectInfo(projectId);
    dispatch(setChatInfo(projectInfo));
  },
);

export const likeProject = createAsyncThunk(
  anc('likeProject'),
  async (projectId: string) => {
    await projectService.likeProject(projectId);
  },
);
