import BaseApiService from '@services/BaseApiService';
// import { FileData, FileDto } from '@projectTypes/File';

interface DeviceInfo {
  id: string;
}

export default class ApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '',
    });
  }

  async getLiveClients(data: string[]) {
    const response = await this.axiosInstance.post<DeviceInfo[]>('/online', {
      ids: data,
    });
    return response.data;
  }
}
