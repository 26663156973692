import config from '@config';
import JsonTable from '@components/JsonTable';
import Jupyter from '@components/Jupyter';
import File from '@projectTypes/File';
import Scratch from '@components/Scratch';
import dynamic from 'next/dynamic';

import PipelineEditor from '../../ConfigEditor/PipelineEditor';
import PoliciesEditor from '../../ConfigEditor/PoliciesEditor';
import BlocklyEditor from '../../BlocklyEditor';
import ImageViewer from './ImageViewer';

const PythonBlockEditor = dynamic(
  () => import('../../PythonBlockEditor/PythonBlockEditor'),
  {
    ssr: false,
  },
);

export const SPECIFIC_FILE_EDITORS = [
  {
    contentType: 'json',
    component: JsonTable,
    rerenderOnChange: false,
  },
  {
    contentType: 'py',
    rerenderOnChange: false,
    component: PythonBlockEditor,
    useCodeEditorPrimarily: true,
    changeEditorButtonText: 'Blocks',
  },
  {
    contentType: 'sb3',
    component: Scratch,
    specialName: 'Scratch',
    rerenderOnChange: false,
    useCodeEditorPrimarily: false,
  },
  {
    name: 'pipeline.yml',
    component: PipelineEditor,
    rerenderOnChange: true,
  },
  {
    name: 'policies.yml',
    component: PoliciesEditor,
    rerenderOnChange: true,
  },
  {
    contentType: config.jupyterContentType,
    nameIncludes: config.jupyterContentType,
    component: Jupyter,
    rerenderOnChange: false,
  },
  {
    contentType: 'ino',
    component: BlocklyEditor,
    useCodeEditorPrimarily: true,
    changeEditorButtonText: 'Blocks',
    rerenderOnChange: false,
    saveToProjectFileName: config.mainXMLFileName,
  },
];

addImageViewer();

function addImageViewer() {
  const imageTypes = ['png', 'jpg', 'ico', 'gif', 'jpeg'];
  imageTypes.forEach((el) => {
    SPECIFIC_FILE_EDITORS.push({
      contentType: el,
      component: ImageViewer,
      rerenderOnChange: false,
    });
  });
}

export function getSpecificFileEditor(displayedFile: File) {
  const specificFileEditor = SPECIFIC_FILE_EDITORS.find(
    (fileEditor) =>
      fileEditor.contentType === displayedFile.contentType ||
      (fileEditor.nameIncludes &&
        displayedFile.name.includes(fileEditor.nameIncludes)) ||
      fileEditor.name === displayedFile.name,
  );
  return specificFileEditor;
}

export function isSourceCodeVisible(displayedFile: File) {
  return displayedFile.name !== config.mainXMLFileName;
}

export function isUsingSpecificFileEditor(displayedFile: File) {
  return !!getSpecificFileEditor(displayedFile);
}
