import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useDispatch from './useDispatch';
import { setIsLoggedIn, verifyUser } from '@slices/userSlices';
import { subscribeToTerminalMessages } from '@slices/terminalSlices';
import { setInitialFiles } from '@slices/fileSlices';
import {
  selectProject,
  setInitialProjects,
  setLastOpenedProjectId,
} from '@slices/projectSlices';
import { loadPython } from '@slices/pythonSlices';
import { selectedProjectSelector } from '@selectors';
import { setInitialData } from '@slices/editorSlices';
import { getStreamStatus } from '@slices/streamSlice';

/*
  This function knows what needs to be triggered on app initialization
  It should only delegate jobs to other actions and have no knowledge about services
*/

const initFunction = async (dispatch) => {
  dispatch(setIsLoggedIn(false));
  const { payload } = await dispatch(verifyUser());

  if (payload) {
    await dispatch((selectProject as any)());
    await dispatch(getStreamStatus());
  }

  dispatch(subscribeToTerminalMessages());
};

export default function useOnAppInit() {
  // const store = useSelector(stateSelector);
  const dispatch = useDispatch();
  const project = useSelector(selectedProjectSelector);

  useEffect(() => {
    initFunction(dispatch);

    // DISABLE WHEN ADDING DARK MODE
    if (localStorage.getItem('chakra-ui-color-mode') === 'dark') {
      localStorage.setItem('chakra-ui-color-mode', 'light');
    }
    if (localStorage.getItem('lastOpenedProjectId') != null) {
      dispatch(
        setLastOpenedProjectId(localStorage.getItem('lastOpenedProjectId')),
      );
    }
  }, []);

  useEffect(() => {
    dispatch(loadPython());
  }, [project]);
}

export function useOnEditorInit() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitialFiles());
    dispatch(setInitialProjects());
    dispatch(setInitialData());
  }, []);
}
