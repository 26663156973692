import { PipelineComponentName } from '@projectTypes/Pipeline';
import EntityExtractorComponent from './EntityExtractorComponent';

export default class SpacyEntityExtractor extends EntityExtractorComponent {
  requiredComponentName = PipelineComponentName.SpacyNLP;
  params = [
    {
      name: 'dimensions',
      label: 'Dimensions',
      type: 'string',
      value: '["PERSON", "LOC", "ORG", "PRODUCT"]',
      required: true,
    },
  ];
}
