import YamlEditorService from '@services/YamlEditorService';
import {
  PipelineComponentData,
  PipelineComponentName,
  PipelineComponentType,
} from '@projectTypes/Pipeline';

import PipelineComponent from './_private/PipelineComponent';
import PipelineComponentFactory from './_private/PipelineComponentFactory';

export default class PipelineService extends YamlEditorService {
  components: PipelineComponent[] = [];
  componentFactory: PipelineComponentFactory;

  constructor() {
    super();
    this.componentFactory = new PipelineComponentFactory();
    this.reset();
  }

  reset() {
    this.components = [];
  }

  addComponent(name: PipelineComponentName) {
    const pipelineComponent = this.componentFactory.create(name);
    const reverseIndex = [...this.components]
      .reverse()
      .findIndex((component) => component.type <= pipelineComponent.type);
    const newComponentIndex =
      reverseIndex === -1 ? 0 : this.components.length - reverseIndex;
    this.components.splice(newComponentIndex, 0, pipelineComponent);
    return {
      newComponentIndex,
      component: this.getPipelineComponentData(
        newComponentIndex,
      ) as PipelineComponentData,
    };
  }

  removeComponent(componentIndex: number) {
    this.components.splice(componentIndex, 1);
    return this.getPipelineComponentData() as PipelineComponentData[];
  }

  changeComponentIndex(oldComponentIndex: number, newComponentIndex: number) {
    if (
      oldComponentIndex < this.components.length &&
      newComponentIndex < this.components.length
    ) {
      const component = this.components.splice(oldComponentIndex, 1)[0];
      this.components.splice(newComponentIndex, 0, component);
    }
    return this.getPipelineComponentData() as PipelineComponentData[];
  }

  setComponentParamValue(index: number, paramName: string, value: any) {
    this.components[index]?.setParamValue(paramName, value);
    return this.getPipelineComponentData(index) as PipelineComponentData;
  }

  getIsPipelineValid() {
    return (
      this.getIsComponentMinimumValid &&
      this.getIsComponentOrderValid().isComponentOrderValid &&
      this.getAreComponentValuesValid().areComponentValuesValid
    );
  }

  getIsComponentOrderValid() {
    const componentIndexOrderNotValid = [];
    const isComponentOrderValid = this.components.reduce(
      (acc, component, index) => {
        if (component.requiredComponentType) {
          const requiredComponentIndex = this.components.findIndex(
            (c) => c.type === component.requiredComponentType,
          );
          if (requiredComponentIndex === -1 || requiredComponentIndex > index) {
            componentIndexOrderNotValid.push(index);
            return false;
          }
        }
        if (component.requiredComponentName) {
          const requiredComponentIndex = this.components.findIndex(
            (c) => c.name === component.requiredComponentName,
          );
          if (requiredComponentIndex === -1 || requiredComponentIndex > index) {
            componentIndexOrderNotValid.push(index);
            return false;
          }
        }
        return acc;
      },
      true,
    );
    return {
      isComponentOrderValid,
      componentIndexOrderNotValid,
    };
  }

  getAreComponentValuesValid() {
    const componentIndexValueNotValid = [];
    const areComponentValuesValid = this.components.reduce(
      (acc, component, index) => {
        if (!component.areValuesValid) {
          componentIndexValueNotValid.push(index);
          return false;
        }
        return acc;
      },
      true,
    );
    return {
      componentIndexValueNotValid,
      areComponentValuesValid,
    };
  }

  getIsComponentMinimumValid() {
    const intentClassifierComponent = this.components.find(
      (component) => component.type === PipelineComponentType.IntentClassifier,
    );
    const entityExtractorComponent = this.components.find(
      (component) => component.type === PipelineComponentType.EntityExtractor,
    );
    const combinedComponent = this.components.find(
      (component) =>
        component.type ===
        PipelineComponentType.CombinedIntentClassifiersAndEntityExtractors,
    );
    return !!(
      (intentClassifierComponent && entityExtractorComponent) ||
      combinedComponent
    );
  }

  setComponents(components: PipelineComponentData[]) {
    this.components = components.map((component) =>
      this.componentFactory.create(
        component.name as PipelineComponentName,
        component,
      ),
    );
  }

  getComponentsAsJson() {
    return {
      pipeline: this.components.map((component) => {
        const newComponent = {
          name: component.name,
        };
        component.params?.forEach((param) => {
          newComponent[param.name] =
            param.type === 'number' ? parseFloat(param.value) : param.value;
        });
        return newComponent;
      }),
    };
  }

  parsePipelineFromYml(content: string) {
    const yamlData = super.parseFromYml(content);
    if (yamlData && (yamlData as any).pipeline) {
      this.components = (yamlData as any).pipeline.map((component) =>
        this.componentFactory.create(component.name, {
          name: component.name,
          params: Object.entries(component)
            .filter(([key]) => key !== 'name')
            .map(([key, value]) => ({
              value,
              name: key,
            })) as any,
        }),
      );
    } else {
      this.components = [];
    }
  }

  private getPipelineComponentData(index?: number) {
    if (index >= 0) {
      return JSON.parse(
        JSON.stringify(this.components[index]),
      ) as PipelineComponentData;
    }
    return JSON.parse(
      JSON.stringify(this.components),
    ) as PipelineComponentData[];
  }
}
