import ApiService from './_private/ApiService';

export default class DeviceService {
  apiService: ApiService;

  constructor(host: string, apiRoute: string) {
    this.apiService = new ApiService(host, apiRoute);
  }

  setToken(token: string) {
    this.apiService.setToken(token);
  }

  async getDevicesInSchools(schoolIds: string[], userId: string) {
    const devices = await this.apiService.getDevicesBySchoolIds(schoolIds, userId);

    return devices;
  }

  async connectToDevice(deviceId: string) {
    const device = await this.apiService.connectToDevice(deviceId);
    return device;
  }

  async pushCommitToDevice(deviceId: string, commitId: string, noConfirm = false) {
    const device = await this.apiService.pushCommitToDevice(deviceId, commitId, noConfirm);
    return device;
  }

  async disconnectFromDevice(deviceId: string) {
    const device = await this.apiService.disconnectFromDevice(deviceId);
    return device;
  };
}
