import axios from 'axios';
import BaseApiService from '@services/BaseApiService';
import Project, {
  InfoPartOfProject,
  ProjectDto,
  ProjectInfoDto,
} from '@projectTypes/Project';

export default class ApiService extends BaseApiService {
  runHost: string;

  constructor(runHost: string, host: string, apiRoute: string) {
    super();
    this.runHost = runHost;
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/projects',
    });
  }

  async getProjects() {
    const response = await this.axiosInstance.get<ProjectDto[]>('/');
    return response.data;
  }

  async getProjectByTemplate(templateId) {
    const response = await this.axiosInstance.get<ProjectDto>(
      `/template/${templateId}`,
    );
    return response.data;
  }

  async createProject(name: string, user: string) {
    const response = await this.axiosInstance.post<ProjectDto>('/', {
      name,
      user,
    });
    return response.data;
  }

  async getUserProjects() {
    return this.getProjects();
  }

  async getProject(id: string, withCredentials?: boolean) {
    const response = await this.axiosInstance.get<ProjectDto>(
      `/${id}${withCredentials ? window.location.search : ''}`,
    );
    return response.data;
  }

  async updateProject(id: string, partialProject: Partial<Project>) {
    const response = await this.axiosInstance.patch<ProjectDto>(
      `/${id}`,
      partialProject,
    );
    return response.data;
  }

  async resetProject(id: string) {
    const response = await this.axiosInstance.patch<ProjectDto>(`/${id}/reset`);
    return response.data as ProjectDto;
  }

  async getProjectTemplates() {
    const response = await this.axiosInstance.get('/templates');
    return response.data;
  }

  async getProjectInfo(id: string) {
    const response = await this.axiosInstance.get<ProjectInfoDto>(
      `/${id}/info`,
    );
    return response.data;
  }

  async setProjectInfo(id: string, info: InfoPartOfProject) {
    const response = await this.axiosInstance.put<ProjectInfoDto>(
      `/${id}/info`,
      { ...info },
    );
    return response.data;
  }

  async likeProject(id: string) {
    const response = await this.axiosInstance.post<ProjectInfoDto>(
      `/${id}/likes`,
    );
    return response.data;
  }

  async getShowcaseProjects(year?: number) {
    const response = await this.axiosInstance.get<ProjectInfoDto[]>(
      `/showcase`,
      {
        params: year
          ? {
              yearId: year,
            }
          : {},
      },
    );
    return response.data;
  }

  async getTrainingsForProjects(projectIds: { id: string }[]) {
    const response = await axios.post<{ id: string; state: string }>(
      `${this.runHost}/api/v1/chats/multiple/last`,
      { ids: projectIds.map((p) => p.id) },
    );
    return response.data;
  }
}
