import { Observable, Subject, Subscriber } from 'rxjs';

import randomColor from '@helpers/randomColor';
import { RealTimeEvents } from '@projectTypes/RealTimeEdit';

import SocketService from './_private/SocketService';

export default class RealTimeEditService {
  userId: string;
  uniqueId: number;
  socketService: SocketService;
  observable: Observable<any>;
  subject: Subject<any>;
  subscriber: Subscriber<any>;
  uniqueIdColors: Record<string, string> = {};

  constructor(host: string) {
    this.socketService = new SocketService(host);
    const sub = this.socketService.subscribeToEvents();
    sub.subscribe((message) => {
      if (!this.messageHandler(message)) {
        return;
      }
      if (!this.uniqueIdColors[message.data.uniqueId]) {
        this.uniqueIdColors[message.data.uniqueId] = randomColor(
          message.data.uniqueId,
        );
      }
      message.data.color = this.uniqueIdColors[message.data.uniqueId];
      this.subscriber?.next(message);
    });
  }

  projectSelect(projectId: string) {
    this.sendEvent(RealTimeEvents.PROJECT_SELECT, { projectId });
  }

  sendKeyChanges(data: any) {
    this.sendEvent(RealTimeEvents.KEY, data);
  }

  sendSelectionChanges(data: any) {
    this.sendEvent(RealTimeEvents.SELECTION, data);
  }

  fileChange(data: any) {
    this.sendEvent(RealTimeEvents.FILE_CHANGE, data);
  }

  fileData(data: any) {
    this.sendEvent(RealTimeEvents.FILE_DATA, data);
  }

  fileSave(data: any) {
    this.sendEvent(RealTimeEvents.FILE_SAVE, data);
  }

  setUserId(userId: string) {
    this.userId = userId;
  }

  setToken(token: string) {
    this.socketService.setToken(token);
  }

  sendEvent(event: RealTimeEvents, data: any) {
    this.socketService.sendEvent(event, data);
  }

  subscribeToEvents() {
    if (!this.subject) {
      this.observable = new Observable((sub) => {
        this.subscriber = sub;
      });
      this.subject = new Subject();
      this.observable.subscribe((data: any) => {
        this.subject.next(data);
      });
    }
    return this.subject;
  }

  private messageHandler(message) {
    if (message.event === 'user-data') {
      this.uniqueId = message.data.uniqueId;
      return false;
    }
    return message.data.uniqueId !== this.uniqueId;
  }
}
