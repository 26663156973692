import { PolicyParam } from '@projectTypes/Policy';

export default class Policy {
  id: number;
  name: string;
  params: PolicyParam[];

  constructor({ id, name }: { id: number; name: string }) {
    this.id = id;
    this.name = name;
  }

  setParamValue(paramName: string, value: any) {
    const paramIndex = this.params.findIndex(
      (param) => param.name === paramName,
    );
    if (paramIndex !== -1) {
      this.params[paramIndex].value = value;
    }
  }
}
