import config from '@/config';
import { UserActionDto } from '@projectTypes/UserAction';
import ApiService from './_private/ApiService';

export default class UserActionService {
  apiService: ApiService;

  constructor(host: string, path: string) {
    this.apiService = new ApiService(host, path);
  }

  setEditorId(editorId: string) {
    this.apiService.setEditorId(editorId);
  }

  setPlatformId(platformId: string) {
    this.apiService.setPlatformId(platformId);
  }

  async trackAction(actionType: string, data?: any): Promise<UserActionDto> {
    if (config.disableUserActions) {
      return {};
    }

    try {
      const retData = await this.apiService.trackAction(actionType, data);
      return retData;
    } catch (err) {
      return {};
    }
  }
}
