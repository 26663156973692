import BaseApiService from '@services/BaseApiService';
import config from '@config';
import { StreamConfig } from '@projectTypes/Stream';

export default class ApiService extends BaseApiService {
  constructor() {
    super();

    this.createAxiosInstance({
      host: config.backendHost,
      apiRoute: config.backendBasePath,
      route: '/',
    });
  }

  async getStreamConfig() {
    const response = await this.axiosInstance.get<StreamConfig>('/stream_live');

    return response.data;
  }
}
