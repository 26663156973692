import Policy from './Policy';

export default class MemoizationPolicy extends Policy {
  params = [
    {
      name: 'max_history',
      label: 'Max history',
      type: 'number',
      value: 8,
      required: false,
    },
  ];
}
