import { useMemo } from 'react';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer from '@slices/index';

let store;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
  stateReconciler: autoMergeLevel2,
};

function initStore(preloadedState) {
  let reducer = rootReducer;
  if (typeof window !== 'undefined') {
    reducer = persistReducer(persistConfig, rootReducer) as any;
    const configuredStore = configureStore({
      reducer,
      preloadedState,
      middleware: getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    });
    (configuredStore as any).__PERSISTOR = persistStore(configuredStore);
    return configuredStore;
  }

  return configureStore({ reducer, preloadedState });
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    store = undefined;
  }
  if (typeof window === 'undefined') return _store;
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}

export type AppDispatch = typeof store.dispatch;