import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Slot, TrackerStore, ActionProps } from '@projectTypes/Tracker';

const initialSlot = { key: '', value: '' };

const trackerInitialState: TrackerStore = {
  slots: [initialSlot],
  trackerAction: null,
};

const userSlice = createSlice({
  name: 'tracker',
  initialState: trackerInitialState,
  reducers: {
    addSlot(state, _action: PayloadAction<void>) {
      state.slots.push({ key: '', value: '' });
    },
    setSlotValue(state, action: PayloadAction<{ index: number; slot: Slot }>) {
      const { index, slot } = action.payload;
      state.slots[index] = slot;
      state.slots = [...state.slots];
    },
    removeSlot(state, action: PayloadAction<number>) {
      if (state.slots.length > 1) {
        state.slots.splice(action.payload, 1);
      } else {
        state.slots[0] = initialSlot;
        state.slots = [...state.slots];
      }
    },
    setSlots(state, action: PayloadAction<Slot[]>) {
      state.slots = action.payload;
    },
    setTrackerAction(state, action: PayloadAction<ActionProps | null>) {
      state.trackerAction = action.payload;
    },
  },
});

export const {
  addSlot,
  removeSlot,
  setSlotValue,
  setSlots,
  setTrackerAction,
} = userSlice.actions;

export default userSlice.reducer;
