export default interface Policy {
  components: PolicyData[];
}

export interface PolicyData {
  id: number;
  name: string;
  params: PolicyParam[];
}

export enum PolicyName {
  AugmentedMemoizationPolicy = 'AugmentedMemoizationPolicy',
  MemoizationPolicy = 'MemoizationPolicy',
  RulePolicy = 'RulePolicy',
  TEDPolicy = 'TEDPolicy',
}

export interface PolicyParam {
  name: string;
  label: string;
  type: string;
  value: any;
  predefinedValues?: any[];
  required?: boolean;
}
