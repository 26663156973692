import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';

const state = (store: Store) => store.python;

export const pythonSelector = createSelector(state, (s) => s);

export const pythonIsLoading = createSelector(state, (s) => s.isLoading);
export const pythonIsRunning = createSelector(state, (s) => s.isRunning);
export const pythonIsKilling = createSelector(state, (s) => s.isKilling);
export const pythonIsTesting = createSelector(state, (s) => s.isTesting);
