import { Action, AnyAction } from '@reduxjs/toolkit';

interface RejectedAction extends Action {
  error: Error;
}

export default function isRejectedAction(
  action: AnyAction,
): action is RejectedAction {
  return action.type.endsWith('rejected');
}
