import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Device, { DeviceStore } from '@projectTypes/Device';
import deviceService from '@services/DeviceService';
import actionNameCreator from '@helpers/actionNameCreator';
import userActionService from '@/services/UserActionService';
import Store from '@projectTypes/Store';
import terminalService from '@services/TerminalService';
import { strategyIsEmbedPy } from '@services/TerminalService/helpers';
import { getCommits } from '@slices/commitSlices';
import MqttService from '@services/MqttService';
import StemiBlobService from '@services/StemiBlobsService';

const deviceInitialState: DeviceStore = {
  isLoading: false,
  devices: [],
  showDeviceModal: false,
  isLoadingVersions: false,
  isLoadingVersionsFromDevice: false,
  isRunningCode: false,
  libVersions: [],
  firmwareVersions: [],
};

const anc = actionNameCreator('DEVICE');

const deviceSlices = createSlice({
  name: 'device',
  initialState: deviceInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setDevices(state, action: PayloadAction<Device[]>) {
      state.devices = action.payload;
    },
    setConnectedDevice(state, action: PayloadAction<Device>) {
      state.connectedDevice = action.payload;
      if (
        strategyIsEmbedPy(
          terminalService.terminalStrategyType,
          terminalService.terminalStrategy,
        )
      ) {
        terminalService.terminalStrategy.setDeviceId(action.payload?.id);
      }
    },
    setShowDeviceModal(state, action: PayloadAction<boolean>) {
      state.showDeviceModal = action.payload;
    },
    setIsLoadingVersions(state, action: PayloadAction<boolean>) {
      state.isLoadingVersions = action.payload;
    },
    setIsLoadingVersionsFromDevice(state, action: PayloadAction<boolean>) {
      state.isLoadingVersionsFromDevice = action.payload;
    },
    setIsRunningCode(state, action: PayloadAction<boolean>) {
      state.isRunningCode = action.payload;
    },
    setLastRanProjectId(state, action: PayloadAction<string>) {
      state.lastRanProjectId = action.payload;
    },
    setLibVersions(state, action: PayloadAction<string[]>) {
      state.libVersions = action.payload;
    },
    setFirmwareVersions(state, action: PayloadAction<string[]>) {
      state.firmwareVersions = action.payload;
    },
    setConnectedDeviceVersions(
      state,
      action: PayloadAction<DeviceStore['connectedDeviceVersions']>,
    ) {
      state.connectedDeviceVersions = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setDevices,
  setConnectedDevice,
  setShowDeviceModal,
  setIsLoadingVersions,
  setIsLoadingVersionsFromDevice,
  setIsRunningCode,
  setLastRanProjectId,
  setLibVersions,
  setFirmwareVersions,
  setConnectedDeviceVersions,
} = deviceSlices.actions;
export default deviceSlices.reducer;

export const connectToDevice = createAsyncThunk<void, string, { state: Store }>(
  anc('connectToDevice'),
  async (deviceId: string, { dispatch, getState }) => {
    dispatch(setIsLoading(true));
    try {
      const device = await deviceService.connectToDevice(deviceId);
      userActionService.trackAction('editor_connect_device', { deviceId });
      const { user, device: deviceStore, project } = getState();
      const { devices } = deviceStore;

      dispatch(
        setDevices(
          devices.map((d) => {
            if (d.id !== device.id) {
              return d;
            } else {
              return device;
            }
          }),
        ),
      );
      dispatch(setConnectedDevice(device));
      if (project.selectedProjectId) {
        setTimeout(
          () =>
            dispatch(
              getCommits({
                deviceId: device.id,
                userId: user.id,
                projectId: project.selectedProjectId,
              }),
            ),
          500,
        );
      }

      MqttService.publish(
        `device/${device.id}/command`,
        JSON.stringify({ action: 'check_versions' }),
      );
      MqttService.publish(
        `device/${device.id}/command`,
        JSON.stringify({ action: 'is_running' }),
      );
      MqttService.subscribe(
        `device/${device.id}/info`,
        (topic: string, msg: string) => {
          try {
            const parsed = JSON.parse(msg);
            if (parsed.libVersion || parsed.firmwareVersion) {
              dispatch(
                setConnectedDeviceVersions({
                  libVersion: parsed.libVersion,
                  firmwareVersion: parsed.firmwareVersion,
                }),
              );
              dispatch(setIsLoadingVersionsFromDevice(false));
            }
            if (parsed.running != null) {
              dispatch(setIsRunningCode(parsed.running));
            }
            if (parsed.projectId != null) {
              dispatch(setLastRanProjectId(parsed.projectId));
            }
          } catch (error) {
            console.log(error);
          }
        },
        true,
      );

      dispatch(setIsLoading(false));
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const pushCommitToDevice = createAsyncThunk<
  void,
  string,
  { state: Store }
>(
  anc('pushCommitToDevice'),
  async (commitId: string, { dispatch, getState }) => {
    dispatch(setIsLoading(true));
    try {
      const { device: deviceStore } = getState();
      const { devices, connectedDevice } = deviceStore;
      const device = await deviceService.pushCommitToDevice(
        connectedDevice.id,
        commitId,
      );
      userActionService.trackAction('editor_push_code_to_device', {
        deviceId: connectedDevice.id,
        commitId,
      });

      dispatch(
        setDevices(
          devices.map((d) => {
            if (d.id !== device.id) {
              return {
                ...d,
                currentCommitId: commitId,
              };
            } else {
              return device;
            }
          }),
        ),
      );
      dispatch(
        setConnectedDevice({ ...connectedDevice, currentCommitId: commitId }),
      );
      dispatch(setIsLoading(false));
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const disconnectFromDevice = createAsyncThunk<
  void,
  string,
  { state: Store }
>(
  anc('disconnectFromDevice'),
  async (deviceId: string, { dispatch, getState }) => {
    dispatch(setIsLoading(true));
    try {
      const device = await deviceService.disconnectFromDevice(deviceId);
      userActionService.trackAction('editor_dicconnect_device', { deviceId });
      const { devices } = getState().device;

      dispatch(
        setDevices(
          devices.map((d) => {
            if (d.id === device.id) {
              return device;
            } else {
              return d;
            }
          }),
        ),
      );
      MqttService.unSubscribeFromDevices();
      dispatch(setConnectedDevice(undefined));
      dispatch(setIsLoading(false));
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const getDevices = createAsyncThunk<void, string[], { state: Store }>(
  anc('getDevices'),
  async (schoolIds: string[], { dispatch, getState }) => {
    dispatch(setIsLoading(true));
    try {
      const { user, project } = getState();
      const {
        devices,
        connectedDevice,
      } = await deviceService.getDevicesInSchools(schoolIds, user.id);
      if (connectedDevice != null) {
        dispatch(setConnectedDevice(connectedDevice));
        if (project.selectedProjectId != null) {
          setTimeout(
            () =>
              dispatch(
                getCommits({
                  deviceId: connectedDevice.id,
                  userId: user.id,
                  projectId: project.selectedProjectId,
                }),
              ),
            500,
          );
        }
        MqttService.publish(
          `device/${connectedDevice.id}/command`,
          JSON.stringify({ action: 'check_versions' }),
        );
        MqttService.publish(
          `device/${connectedDevice.id}/command`,
          JSON.stringify({ action: 'is_running' }),
        );
        dispatch(setIsLoadingVersionsFromDevice(true));
        MqttService.subscribe(
          `device/${connectedDevice.id}/info`,
          (topic: string, msg: string) => {
            try {
              const parsed = JSON.parse(msg);
              if (parsed.libVersion || parsed.firmwareVersion) {
                dispatch(
                  setConnectedDeviceVersions({
                    libVersion: parsed.libVersion,
                    firmwareVersion: parsed.firmwareVersion,
                  }),
                );
                dispatch(setIsLoadingVersionsFromDevice(false));
              }
              if (parsed.running != null) {
                dispatch(setIsRunningCode(parsed.running));
              }
              if (parsed.projectId != null) {
                dispatch(setLastRanProjectId(parsed.projectId));
              }
            } catch (error) {
              console.log(error);
            }
          },
          true,
        );
        dispatch(getSoftwareVersions());
      }
      dispatch(setDevices(devices));
      dispatch(setIsLoading(false));
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const getOnlineDevicesInfo = createAsyncThunk<
  void,
  void,
  { state: Store }
>(anc('getOnlineDevicesInfo'), async (_, { dispatch, getState }) => {
  dispatch(setIsLoading(true));
  try {
    const { devices } = getState().device;
    const deviceIds = devices.map((d) => d.id);
    const devicesInfo = await MqttService.getOnlineDevicesInfo(deviceIds);
    const newDevices = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const device of devices) {
      if (devicesInfo.find((di) => di.id === device.id)) {
        newDevices.push({ ...device, online: true });
      } else {
        newDevices.push(device);
      }
    }
    dispatch(setDevices(newDevices));
    dispatch(setIsLoading(false));
  } catch (e) {
    dispatch(setIsLoading(false));
    // TODO: figureout ssl, that works for mqtt in general for some reason
    // throw e;
  }
});

export const getSoftwareVersions = createAsyncThunk<
  void,
  void,
  { state: Store }
>(anc('getSoftwareVersions'), async (_, { dispatch }) => {
  dispatch(setIsLoadingVersions(true));
  try {
    StemiBlobService.getVersions().then((data) => {
      const { libVersions, firmwareVersions } = data;
      dispatch(setLibVersions(libVersions));
      dispatch(setFirmwareVersions(firmwareVersions));
      dispatch(setIsLoadingVersions(false));
    });
  } catch (e) {
    dispatch(setIsLoadingVersions(false));
    throw e;
  }
});
