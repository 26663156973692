import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@chakra-ui/react';

import useDispatch from '@hooks/useDispatch';
import {
  pipelineComponentSelectorByType,
  firstComponentIndexSelectorByType,
} from '@selectors';
import {
  changeComponentIndex,
  removeComponent as removeComponentAction,
  setComponentParamValue,
} from '@slices/pipelineSlices';
import { PipelineComponentType } from '@projectTypes/Pipeline';
import PipelineComponent from './PipelineComponent';

interface PipelineComponentContainerProps {
  pipelineComponentType: PipelineComponentType;
}

const PipelineComponentContainer = (props: PipelineComponentContainerProps) => {
  const { pipelineComponentType } = props;
  const dispatch = useDispatch();
  const pipelineComponents = useSelector(
    pipelineComponentSelectorByType(pipelineComponentType),
  );
  const pipelineComponentsOffset = useSelector(
    firstComponentIndexSelectorByType(pipelineComponentType),
  );

  const removeComponent = (index: number) => {
    dispatch(removeComponentAction(pipelineComponentsOffset + index));
  };

  const setValue = (index: number, paramName: string, value: any) => {
    dispatch(
      setComponentParamValue({
        paramName,
        value,
        index: pipelineComponentsOffset + index,
      }),
    );
  };

  const moveComponent = useCallback(
    (dragIndex, hoverIndex) => {
      dispatch(
        changeComponentIndex({
          oldComponentIndex: dragIndex,
          newComponentIndex: hoverIndex,
        }),
      );
    },
    [pipelineComponents],
  );

  return (
    <Stack spacing="10px">
      {pipelineComponents.map((component, index) => (
        <PipelineComponent
          key={component.id}
          component={component}
          componentIndex={pipelineComponentsOffset + index}
          moveComponent={moveComponent}
          removeComponent={() => removeComponent(index)}
          setValue={(paramName: string, value: any) =>
            setValue(index, paramName, value)
          }
        />
      ))}
    </Stack>
  );
};

export default PipelineComponentContainer;
