/* eslint-disable */
// @ts-nocheck
import delay from '@/helpers/delay';
import { ESP_ROM_BAUD, Logger } from './const';
import { ESPLoader } from './esp_loader';

export type { Logger } from './const';
export { ESPLoader } from './esp_loader';

export {
  CHIP_FAMILY_ESP32,
  CHIP_FAMILY_ESP32S2,
  CHIP_FAMILY_ESP32S3,
  CHIP_FAMILY_ESP8266,
  CHIP_FAMILY_ESP32C3,
  CHIP_FAMILY_ESP32C6,
  CHIP_FAMILY_ESP32H2,
} from './const';

let port;
let device;
let read = false;
let reader;
let loader;
let stop = 0;
let onDisconnect = null;
let onMessage = null;

export const requestPort = async (filter?: boolean) => {
  if (port) {
    return port;
  }
  let filters = [];
  if (filter) {
    filters = [{ usbProductId: 24597, usbVendorId: 1027 }];
  }
  port = await (navigator as any).serial.requestPort({
    filters,
  });
  device = await port.getInfo();
  return port;
};

export const connect = async (logger: Logger) => {
  if (port) {
    await closePort();
  } else {
    await requestPort(true);
  }

  logger.log('Connecting...');
  await port.open({ baudRate: ESP_ROM_BAUD });

  logger.log('Connected successfully.');

  loader = new ESPLoader(port, logger);

  // loader.

  return loader;
};

export const connectSerial = async (
  onMessageCb?: (message: string) => void,
  onDisconnectCb?: () => void,
) => {
  if (onDisconnectCb) {
    onDisconnect = onDisconnectCb;
  }
  if (onMessageCb) {
    onMessage = onMessageCb;
  }
  if (port) {
    await closePort();
    await port.open({ baudRate: 9600 });
    setupReader(port, onMessage);
    return device;
  }
  await requestPort(true);
  let repeatCounter = 10;
  while (repeatCounter) {
    repeatCounter -= 1;
    try {
      await port.open({ baudRate: 9600 });
      break;
    } catch (e) {
      console.log(e);
      await closePort();
      if (repeatCounter === 0) {
        throw e;
      }
    }
  }

  await port.setSignals({ requestToSend: false });
  await port.setSignals({ dataTerminalReady: false });
  setupReader(port, onMessage);
  return device;
};

export const closePort = async () => {
  if (port) {
    try {
      console.log('resetting');
      await delay(100);
      if (reader) {
        reader.cancel();
        reader = null;
      }
      await delay(100);
      if (loader) {
        await loader.disconnect();
        loader = null;
      }
      await delay(100);
      read = false;

      await port.setSignals({ requestToSend: true });
      await delay(100);
      await port.setSignals({ dataTerminalReady: true });
      await delay(200);

      await port.setSignals({ requestToSend: false });
      await delay(100);
      await port.setSignals({ dataTerminalReady: false });
      await delay(200);

      await delay(1000);

      await port.close();
      await delay(200);
    } catch (e) {
      console.log(e);
    }
  }
};

const setupReader = async (port) => {
  reader = port.readable.getReader();
  const textDecoder = new TextDecoder();
  read = true;
  // Listen to data coming from the serial device.
  while (read) {
    try {
      await delay(100);
      const { value, done } = await reader.read();
      const stringValue = textDecoder.decode(value);
      if (stop === 2) {
        break;
      } else if (done) {
        // Allow the serial port to be closed later.
        break;
      }
      // value is a string.
      if (onMessage) {
        onMessage(stringValue);
      }
    } catch (err) {
      console.log('err', err.message);
      break;
    }
  }
  try {
    reader.releaseLock();
  } catch (e) {
    console.log(e);
  } finally {
    reader = null;
  }
};

if (typeof window !== 'undefined') {
  if (navigator && navigator.serial) {
    navigator.serial.addEventListener('disconnect', async (e) => {
      try {
        reader.releaseLock();
      } catch (e) {
        console.log(e);
      } finally {
        reader = null;
      }
      try {
        port.close();
      } catch (e) {
        console.log(e);
      } finally {
        port = null;
        device = null;
      }
      if (onDisconnect) {
        onDisconnect();
      }
    });
  }
}
