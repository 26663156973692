import YamlEditorService from '@services/YamlEditorService';
import { PolicyData, PolicyName } from '@projectTypes/Policy';

import Policy from './_private/Policy';
import PolicyFactory from './_private/PolicyFactory';

export default class PolicyService extends YamlEditorService {
  components: Policy[] = [];
  componentFactory: PolicyFactory;

  constructor() {
    super();
    this.componentFactory = new PolicyFactory();
    this.reset();
  }

  reset() {
    this.components = [];
  }

  addComponent(name: PolicyName) {
    const component = this.componentFactory.create(name);
    this.components.push(component);
    return this.getPoliciesComponentData(
      this.components.length - 1,
    ) as PolicyData;
  }

  removeComponent(componentIndex: number) {
    this.components.splice(componentIndex, 1);
    return this.getPoliciesComponentData() as PolicyData[];
  }

  setComponentParamValue(index: number, paramName: string, value: any) {
    this.components[index]?.setParamValue(paramName, value);
    return this.getPoliciesComponentData(index) as PolicyData;
  }

  setComponents(components: PolicyData[]) {
    this.components = components.map((component) =>
      this.componentFactory.create(component.name as any, component),
    );
  }

  getComponentsAsJson() {
    return {
      policies: this.components.map((component) => {
        const newComponent = {
          name: component.name,
        };

        component.params?.forEach((param) => {
          if (!param.required && param.value.length === 0) {
            return;
          }

          newComponent[param.name] =
            param.type === 'number' ? parseFloat(param.value) : param.value;
        });

        return newComponent;
      }),
    };
  }

  parsePoliciesFromYml(content: string) {
    const yamlData = super.parseFromYml(content);
    if (yamlData && (yamlData as any).policies) {
      this.components = (yamlData as any).policies.map((component) =>
        this.componentFactory.create(component.name, {
          name: component.name,
          params: Object.entries(component)
            .filter(([key]) => key !== 'name')
            .map(([key, value]) => ({
              value,
              name: key,
            })) as any,
        }),
      );
    } else {
      this.components = [];
    }
  }

  private getPoliciesComponentData(index?: number) {
    if (index >= 0) {
      return JSON.parse(JSON.stringify(this.components[index])) as PolicyData;
    }
    return JSON.parse(JSON.stringify(this.components)) as PolicyData[];
  }
}
