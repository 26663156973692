import IntentClassifierComponent from './IntentClassifierComponent';

export default class MitieIntentClassifier extends IntentClassifierComponent {
  params = [
    {
      name: 'C',
      label: 'C',
      type: 'string',
      value: '[1, 2, 5, 10, 20, 100]',
      required: true,
    },
    {
      name: 'kernels',
      label: 'Kernels',
      type: 'string',
      value: '["linear"]',
      required: true,
    },
    {
      name: 'gamma',
      label: 'Gamme',
      type: 'string',
      value: '[0.1]',
      required: true,
    },
    {
      name: 'max_cross_validation_folds',
      label: 'Max cross validation folds',
      type: 'number',
      value: 5,
      required: true,
    },
    {
      name: 'scoring_function',
      label: 'Scoring function',
      type: 'string',
      value: 'f1_weighted',
      required: true,
    },
  ];
}
