import FeaturizerComponent from './FeaturizerComponent';

export default class CountVectorsFeaturizer extends FeaturizerComponent {
  params = [
    {
      name: 'analyzer',
      label: 'Analyzer',
      type: 'string',
      value: 'word',
      predefinedValues: ['word', 'char', ' char_wb'],
      required: true,
    },
    {
      name: 'min_ngram',
      label: 'Min ngram',
      type: 'number',
      value: 1,
      required: true,
    },
    {
      name: 'max_ngram',
      label: 'Max ngram',
      type: 'number',
      value: 1,
      required: true,
    },
    {
      name: 'OOV_token',
      label: 'OOV token',
      type: 'string',
      value: '_oov_',
      required: true,
    },
    {
      name: 'use_shared_vocab',
      label: 'Use shared vocab',
      type: 'boolean',
      value: false,
      required: true,
    },
  ];
}
