import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';
import { ProjectNamespace, ProjectType } from '@projectTypes/Project';

const state = (store: Store) => store;

export const projectsSelector = createSelector(state, (s) => s.project);

export const selectedProjectIdSelector = createSelector(
  state,
  (s) => s.project.selectedProjectId,
);

export const selectedProjectSelector = createSelector(state, (s) =>
  s.project.selectedProjectId
    ? s.project.projects.find((p) => p.id === s.project.selectedProjectId)
    : null,
);

export const selectedProjectTemplateSlugSelector = createSelector(
  state,
  selectedProjectSelector,
  (s, selectedProject) => {
    if (
      selectedProject == null ||
      s.project.templates == null ||
      s.project.templates.length <= 0
    ) {
      return '';
    }

    const slug = s.project.templates.find(
      (t) => t.id === selectedProject.templateId,
    )?.slug;

    return slug || '';
  },
);

export const isGroupProjectSelector = createSelector(state, (s) =>
  s.project.selectedProjectId
    ? s.project.projects.find((p) => p.id === s.project.selectedProjectId)
        ?.isGroupProject
    : false,
);

export const isNewProjectInfoSelector = createSelector(
  state,
  (s) => s.project.isNewProjectInfo,
);

export const isProjectPython = createSelector(
  selectedProjectSelector,
  (s) =>
    s?.type === ProjectType.PYTHON && s?.namespace === ProjectNamespace.PYTHON,
);

export const isProjectEmbededPython = createSelector(
  selectedProjectSelector,
  (s) =>
    s?.type === ProjectType.PYTHON &&
    s?.namespace === ProjectNamespace.EMBED_PYTHON,
);

export const isProjectPlatformio = createSelector(
  selectedProjectSelector,
  (s) => s?.type === ProjectType.PLATFORMIO,
);

export const isPythonTestable = createSelector(state, (s) => {
  if (!isProjectPython(s)) {
    return true;
  }

  return s.file.files
    .filter((file) => file.projectId === s.project.selectedProjectId)
    .some((f) => f.name === 'test.json');
});
