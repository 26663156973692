import FeaturizerComponent from './FeaturizerComponent';

export default class RegexFeaturizer extends FeaturizerComponent {
  params = [
    {
      name: 'case_sensitive',
      label: 'Case sensitive',
      type: 'boolean',
      value: true,
      required: true,
    },
    {
      name: 'use_word_boundaries',
      label: 'Use word boundaries',
      type: 'boolean',
      value: true,
      required: true,
    },
  ];
}
