import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Heading, Stack } from '@chakra-ui/react';

import useDispatch from '@hooks/useDispatch';
import { fillPolicy } from '@slices/policySlices';
import File from '@projectTypes/File';
import { policyFileDataSelector, policySelector } from '@selectors';

import NewComponentForm from './NewPolicyForm';
import PipelineComponentContainer from './PoliciesContainer';

interface PipelineEditorProps {
  displayedFile: File;
  onChange: (value: string) => void;
}

const PoliciesEditor = (props: PipelineEditorProps) => {
  const { displayedFile, onChange } = props;
  const dispatch = useDispatch();
  const policyState = useSelector(policySelector);
  const policyFileData = useSelector(policyFileDataSelector);
  const [prevDisplayedFileId, setPrevDisplayedFileId] = useState('');
  const [prevFileCounter, setPrevFileCounter] = useState(-1);

  useEffect(() => {
    if (
      displayedFile &&
      (prevDisplayedFileId !== displayedFile.id ||
        displayedFile.fileCounter !== prevFileCounter)
    ) {
      setPrevDisplayedFileId(displayedFile.id);
      setPrevFileCounter(displayedFile.fileCounter);
      dispatch(fillPolicy(displayedFile));
    }
  }, [displayedFile]);

  useEffect(() => {
    onChange(policyFileData);
  }, [policyState.components]);

  return (
    <Box p="10px">
      <Stack spacing={4}>
        <Heading color="white">RASA policies</Heading>
        <PipelineComponentContainer
          pipelineComponents={policyState.components}
        />
        <NewComponentForm />
      </Stack>
    </Box>
  );
};

export default PoliciesEditor;
