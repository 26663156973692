import { PipelineComponentName } from '@projectTypes/Pipeline';
import FeaturizerComponent from './FeaturizerComponent';

export default class SpacyFeaturizer extends FeaturizerComponent {
  params = [
    {
      name: 'pooling',
      label: 'Polling',
      type: 'string',
      value: 'mean',
      required: true,
    },
  ];
  requiredComponentName = PipelineComponentName.SpacyNLP;
}
