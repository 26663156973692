import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';
import { PipelineComponentType } from '@projectTypes/Pipeline';
import pipelineService from '@services/PipelineService';

const state = (store: Store) => store;

export const pipelineSelector = createSelector(state, (s) => s.pipeline);

export const pipelineStatusSelector = createSelector(state, (s) => {
  const {
    isComponentOrderValid,
    componentIndexOrderNotValid,
    areComponentValuesValid,
    componentIndexValueNotValid,
    isComponentMinimumValid,
  } = s.pipeline;
  return {
    isComponentOrderValid,
    componentIndexOrderNotValid,
    areComponentValuesValid,
    componentIndexValueNotValid,
    isComponentMinimumValid,
  };
});

export const pipelineFileDataSelector = createSelector(state, () =>
  pipelineService.toFileData(),
);

export const pipelineComponentSelectorByType = (
  componentType: PipelineComponentType,
) =>
  createSelector(state, (s) =>
    s.pipeline.components.filter(
      (component) => component.type === componentType,
    ),
  );

export const firstComponentIndexSelectorByType = (
  componentType: PipelineComponentType,
) =>
  createSelector(state, (s) =>
    s.pipeline.components.findIndex(
      (component) => component.type === componentType,
    ),
  );
