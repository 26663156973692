import config from '@/config';
import { HeartBeatDto } from '@projectTypes/HeartBeat';
import ApiService from './_private/ApiService';

export default class HeartBeatService {
  apiService: ApiService;

  constructor(host: string, path: string) {
    this.apiService = new ApiService(host, path);
  }

  setPlatformId(platformId: string) {
    this.apiService.setPlatformId(platformId);
  }

  async heartBeat(
    url: string,
    scrollTop = 0,
    scrollLeft = 0,
  ): Promise<HeartBeatDto> {
    if (config.disableHeartBeat) {
      return { alive: false };
    }

    try {
      const retData = await this.apiService.heartBeat(
        url,
        scrollTop,
        scrollLeft,
      );
      return retData;
    } catch (err) {
      return { alive: false };
    }
  }
}
