import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
} from '@chakra-ui/react';

interface Param {
  name: string;
  label: string;
  type: string;
  value: any;
  predefinedValues?: any[];
  required?: boolean;
}

interface ComponentValueProps {
  param: Param;
  setValue: (value: any) => void;
}

const ComponentValue = (props: ComponentValueProps) => {
  const { param, setValue } = props;
  return (
    <FormControl display="flex" alignItems="center" mt="12px">
      <FormLabel htmlFor={param.name} color="white" mb="0">
        {param.label}
      </FormLabel>
      {param.type === 'boolean' ? (
        <Switch
          isChecked={param.value}
          name={param.name}
          isRequired={param.required}
          onChange={(event) => setValue(event.target.checked)}
          size="lg"
          borderRadius="3xl"
          padding="0px"
          bgColor="secondary"
          color="primary"
          overflow="hidden"
        />
      ) : param.predefinedValues ? (
        <Select
          value={param.value}
          onChange={(event) => setValue(event.target.value)}
          color="white"
          borderColor="white"
          maxW="300px"
          size="md"
          _placeholder={{
            color: '#AAA',
          }}
        >
          {param.predefinedValues.map((predefinedValue) => (
            <option key={predefinedValue} value={predefinedValue}>
              {predefinedValue}
            </option>
          ))}
        </Select>
      ) : (
        <Input
          placeholder={param.label}
          name={param.name}
          value={param.value}
          onChange={(event) => setValue(event.target.value)}
          color="white"
          borderColor="white"
          maxW="300px"
          size="md"
          _placeholder={{
            color: '#AAA',
          }}
        />
      )}
    </FormControl>
  );
};

export default ComponentValue;
