import Policy from './Policy';

export default class RulePolicy extends Policy {
  params = [
    {
      name: 'core_fallback_threshold',
      label: 'Core fallback threshold',
      type: 'number',
      value: 0.3,
      required: false,
    },
    {
      name: 'core_fallback_action_name',
      label: 'Core fallback action name',
      type: 'text',
      value: '',
      required: false,
    },
    {
      name: 'enable_fallback_prediction',
      label: 'Enable fallback prediction',
      type: 'boolean',
      value: true,
      required: false,
    },
    {
      name: 'restrict_rules',
      label: 'Restrict rules',
      type: 'boolean',
      value: true,
      required: false,
    },
    {
      name: 'check_for_contradictions',
      label: 'Check for contradictions',
      type: 'boolean',
      value: true,
      required: false,
    },
  ];
}
