import isWindowAvailable from '@helpers/isWindowAvailable';
import config from '@config';
import File from '@projectTypes/File';

export default class ScratchService {
  host: string;
  eventListener: any;
  lastEvent: any;
  file: File;
  onChange: any;
  interval: any;
  onLoad: () => void;
  onError: () => void;
  onSave: () => void;
  constructor(host: string) {
    this.host = host;
  }

  createEventListener(
    file: File,
    onChange: (value: string) => void,
    onLoad: () => void,
    onError: () => void,
    onSave: () => void,
  ) {
    this.file = file;
    this.onChange = onChange;
    this.onLoad = onLoad;
    this.onError = onError;
    this.onSave = onSave;

    if (isWindowAvailable()) {
      if (this.eventListener) {
        removeEventListener('message', this.eventListener);
      }
      this.eventListener = this.handleEvent.bind(this);
      window.addEventListener('message', this.eventListener);
      this.setupInterval();
    }
  }

  async handleEvent(e) {
    if (this.host.includes(e.origin)) {
      if (e.data.event === config.scratchLoaded) {
        const el = this.getElement();
        let blob = null;
        if (this.file.blob) {
          const resp = await fetch(this.file.blob);
          blob = await resp.blob();
        }
        el.contentWindow.postMessage(
          {
            blob,
            event: 'code',
            code: this.file.content,
            requestId: this.file.updatedAt.toString(),
          },
          '*',
        );
      }
      this.lastEvent = e;
      if (e.type === config.scratchError) {
        this.onError();
      }
      if (!e.data || !e.data.event) {
        return;
      }
      if (e.data.event === config.scratchGetContentEventName) {
        const el = this.getElement();
        if (el && el.contentWindow) {
          window.top.postMessage(
            {
              event: config.scratchCodeEventName,
              code: this.file.content,
              requestId: this.file.updatedAt.toString(),
            },
            '*',
          );
        } else {
          e.source.postMessage(
            {
              event: config.scratchCodeEventName,
              code: this.file.content,
            },
            { targetOrigin: e.origin },
          );
        }
      } else if (e.data.event === config.scratchCodeEventName) {
        const { code, blob } = e.data;
        // Code is old way of storing project
        const randomData = Math.random().toString();
        const blobUrl = URL.createObjectURL(blob);
        this.onChange(randomData, { blob: blobUrl });
        this.onLoad();
      } else if (e.data.event === config.scratchError) {
        this.onError();
      } else if (e.data.event === config.scratchSave) {
        this.onSave();
      }
    }
  }

  getElement() {
    if (!isWindowAvailable()) {
      return null;
    }
    const el = document.querySelector('#scratch-iframe');
    return el as any;
  }

  runCode() {
    const el = this.getElement();
    if (el && el.contentWindow) {
      el.contentWindow.postMessage({ event: config.scratchRunEvent }, '*');
    } else if (this.lastEvent) {
      this.lastEvent.source.postMessage(
        { event: config.scratchRunEvent },
        { targetOrigin: this.lastEvent.origin },
      );
    }
  }

  runAllCode() {
    const el = this.getElement();
    if (el && el.contentWindow) {
      for (let i = 0; i < 3; i++) {
        el.contentWindow.postMessage({ event: config.scratchRunAllEvent }, '*');
      }
    } else if (this.lastEvent) {
      for (let i = 0; i < 3; i++) {
        this.lastEvent.source.postMessage(
          { event: config.scratchRunAllEvent },
          { targetOrigin: this.lastEvent.origin },
        );
      }
    }
  }

  setupInterval() {
    if (this.interval) {
      return this.interval;
    }
    this.interval = setInterval(() => {
      const el = this.getElement();
      if (el && el.contentWindow) {
        el.contentWindow.postMessage({ event: config.scratchRequestCode }, '*');
      }
    }, 20000);
  }

  getInitalPath() {
    return `${this.host}`;
  }

  getFilePath() {
    return config.scratchHost;
  }
}
