import axios from 'axios';

const HOST = 'http://localhost:13374/';
const DISABLED = true;

interface LogsDto {
  logs: { type: string; data: string }[];
}

export class DesktopService {
  isDesktopLive: boolean;
  isHexapodReady: boolean;
  onCheckIsDesktopLive?: (_status: boolean, _hexapodReady: boolean) => void;
  onLogs?: (_logs: LogsDto) => void;

  constructor() {
    if (DISABLED) {
      return;
    }
    setInterval(async () => {
      await this.checkIsDesktopLive();
    }, 5000);
    setInterval(async () => {
      await this.checkLogs();
    }, 5000);
  }

  async upload(compileId: string) {
    const response = await axios.post(`${HOST}upload`, { compileId });
    return response.data;
  }

  async checkIsDesktopLive() {
    if (!this.onCheckIsDesktopLive) {
      return;
    }
    try {
      const response = await axios.get(HOST);
      this.isDesktopLive = true;
      this.isHexapodReady = response.data.isHexapodReady;
    } catch (e) {
      console.log(e);
      this.isDesktopLive = false;
      this.isHexapodReady = false;
    }
    this.onCheckIsDesktopLive(this.isDesktopLive, this.isHexapodReady);
  }

  async checkLogs() {
    if (!this.isDesktopLive) {
      return;
    }
    if (!this.onLogs) {
      return;
    }
    const response = await axios.get<any>(`${HOST}logs`);
    if (this.onLogs) {
      this.onLogs(response.data);
    }
  }
}

export default new DesktopService();
