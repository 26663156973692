export interface RealTimeEditStore {
  userFilePositions: Record<string, UserFilePosition>;
}

export interface UserFilePosition {
  uniqueId: number;
  username: string;
  fileId: string;
  color: string;
}

export enum RealTimeEvents {
  FILE_DATA = 'file-data',
  FILE_CHANGE = 'file-change',
  FILE_SAVE = 'file-save',
  PROJECT_SELECT = 'project-select',
  SETUP = 'setup',
  SELECTION = 'selection',
  KEY = 'key',
  USER_DISCONNECTED = 'user-disconnected',
  USER_CONNECT = 'user-connect',
}
