import FeaturizerComponent from './FeaturizerComponent';

export default class LexicalSyntacticFeaturizer extends FeaturizerComponent {
  params = [
    {
      name: 'fatures',
      label: 'Features',
      type: 'string',
      value: '[]',
      required: false,
    },
  ];
}
