import { PipelineComponentName } from '@projectTypes/Pipeline';

import PipelineComponent from './PipelineComponent';
import CountVectorsFeaturizer from './PipelineComponent/FeaturizerComponent/CountVectorsFeaturizer';
import RegexFeaturizer from './PipelineComponent/FeaturizerComponent/RegexFeaturizer';
import MitieNLP from './PipelineComponent/LanguageModelComponent/MitieNLP';
import SpacyNLP from './PipelineComponent/LanguageModelComponent/SpacyNLP';
import SpacyTokenizer from './PipelineComponent/TokenizerComponent/SpacyTokenizer';
import WhitespaceTokenizer from './PipelineComponent/TokenizerComponent/WhitespaceTokenizer';
import MitieTokenizer from './PipelineComponent/TokenizerComponent/MitieTokenizer';
import MitieFeaturizer from './PipelineComponent/FeaturizerComponent/MitieFeaturizer';
import SpacyFeaturizer from './PipelineComponent/FeaturizerComponent/SpacyFeaturizer';
import ConveRTFeaturizer from './PipelineComponent/FeaturizerComponent/ConveRTFeaturizer';
import LanguageModelFeaturizer from './PipelineComponent/FeaturizerComponent/LanguageModelFeaturizer';
import MitieIntentClassifier from './PipelineComponent/IntentClassifierComponent/MitieIntentClassifier';
import KeywordIntentClassifier from './PipelineComponent/IntentClassifierComponent/KeywordIntentClassifier';
import FallbackClassifier from './PipelineComponent/IntentClassifierComponent/FallbackClassifier';
import LexicalSyntacticFeaturizer from './PipelineComponent/FeaturizerComponent/LexicalSyntacticFeaturizer';
import EntityExtractorComponent from './PipelineComponent/EntityExtractorComponent';
import EntitySynonymMapper from './PipelineComponent/EntityExtractorComponent/EntitySynonymMapper';
import MitieEntityExtractor from './PipelineComponent/EntityExtractorComponent/MitieEntityExtractor';
import RegexEntityExtractor from './PipelineComponent/EntityExtractorComponent/RegexEntityExtractor';
import SpacyEntityExtractor from './PipelineComponent/EntityExtractorComponent/SpacyEntityExtractor';
import ResponseSelector from './PipelineComponent/SelectorComponent/ResponseSelector';
import DIETClassifier from './PipelineComponent/CombinedIntentClassifiersAndEntityExtractorsComponent/DIETClassifier';

const components: Record<PipelineComponentName, typeof PipelineComponent> = {
  [PipelineComponentName.MitieNLP]: MitieNLP,
  [PipelineComponentName.SpacyNLP]: SpacyNLP,
  [PipelineComponentName.SpacyTokenizer]: SpacyTokenizer,
  [PipelineComponentName.WhitespaceTokenizer]: WhitespaceTokenizer,
  [PipelineComponentName.MitieTokenizer]: MitieTokenizer,
  [PipelineComponentName.RegexFeaturizer]: RegexFeaturizer,
  [PipelineComponentName.CountVectorsFeaturizer]: CountVectorsFeaturizer,
  [PipelineComponentName.MitieFeaturizer]: MitieFeaturizer,
  [PipelineComponentName.SpacyFeaturizer]: SpacyFeaturizer,
  [PipelineComponentName.ConveRTFeaturizer]: ConveRTFeaturizer,
  [PipelineComponentName.LanguageModelFeaturizer]: LanguageModelFeaturizer,
  [PipelineComponentName.KeywordIntentClassifier]: KeywordIntentClassifier,
  [PipelineComponentName.MitieIntentClassifier]: MitieIntentClassifier,
  [PipelineComponentName.FallbackClassifier]: FallbackClassifier,
  [PipelineComponentName.LexicalSyntacticFeaturizer]: LexicalSyntacticFeaturizer,
  [PipelineComponentName.EntityExtractorComponent]: EntityExtractorComponent,
  [PipelineComponentName.EntitySynonymMapper]: EntitySynonymMapper,
  [PipelineComponentName.MitieEntityExtractor]: MitieEntityExtractor,
  [PipelineComponentName.RegexEntityExtractor]: RegexEntityExtractor,
  [PipelineComponentName.SpacyEntityExtractor]: SpacyEntityExtractor,
  [PipelineComponentName.ResponseSelector]: ResponseSelector,
  [PipelineComponentName.DIETClassifier]: DIETClassifier,
};

export default class PipelineComponentFactory {
  componentId = 0;

  create(
    name: PipelineComponentName,
    data?: Partial<PipelineComponent>,
  ): PipelineComponent {
    const NamedPipelineComponent = components[name];
    const component = new NamedPipelineComponent({
      id: this.componentId,
      name,
    });
    this.componentId += 1;
    if (data && data.params) {
      data.params.forEach((param) => {
        const paramIndex = component.params?.findIndex(
          (p) => p.name === param.name,
        );
        if (paramIndex !== undefined && paramIndex !== -1) {
          component.params[paramIndex] = {
            ...component.params[paramIndex],
            ...param,
          };
        }
      });
    }
    return component;
  }
}
