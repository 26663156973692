import BaseApiService from '@services/BaseApiService';
import { AxiosInstance } from 'axios';

export default class ApiService extends BaseApiService {
  axiosInstance: AxiosInstance;

  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/platform',
    });
  }

  async getUserInfo() {
    const response = await this.axiosInstance.get('/user-info');
    return response.data;
  }
}
