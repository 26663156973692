import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';

const state = (store: Store) => store;

export const stateSelector = createSelector(state, (s) => s);

export * from './fileSelectors';
export * from './projectSelectors';
export * from './userSelectors';
export * from './errorSelectors';
export * from './userSelectors';
export * from './terminalSelectors';
export * from './pipelineSelectors';
export * from './policySelectors';
export * from './trackerSelectors';
export * from './realTimeEditSelectors';
export * from './globalSelectors';
export * from './pythonSelectors';
export * from './editorSelectors';
export * from './streamSelectors';
export * from './deviceSelectors';
export * from './commitSelectors';
export * from './diffSelectors';
