import {
  PipelineComponentName,
  PipelineComponentType,
  PipelineComponentParam,
} from '@projectTypes/Pipeline';

export default class PipelineComponent {
  id: number;
  name: string;
  type: PipelineComponentType;
  params: PipelineComponentParam[];
  requiredComponentType?: PipelineComponentType;
  requiredComponentName?: PipelineComponentName;

  constructor({ name, id }: { id: number; name: string }) {
    this.id = id;
    this.name = name;
  }

  setParamValue(paramName: string, value: any) {
    const paramIndex = this.params.findIndex(
      (param) => param.name === paramName,
    );
    if (paramIndex !== -1) {
      this.params[paramIndex].value = value;
    }
  }

  get areValuesValid() {
    if (!this.params) {
      return true;
    }
    return this.params.reduce(
      (acc, param) =>
        acc && (param.required ? typeof param.value === param.type : true),
      true,
    );
  }
}
