import React, { useRef } from 'react';
import { Badge, Box, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDrag, useDrop } from 'react-dnd';

import {
  PipelineComponentData,
  PipelineComponentType,
} from '@projectTypes/Pipeline';
import ParamValue from '../ParamValue';

interface PipelineComponentProps {
  component: PipelineComponentData;
  componentIndex: number;
  moveComponent: (oldIndex: number, newIndex: number) => void;
  removeComponent: () => void;
  setValue: (paramName: string, value: any) => void;
}

// Drag-drop example https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_hooks_js/04-sortable/simple?from-embed=&file=/src/Card.jsx
const PipelineComponent = (props: PipelineComponentProps) => {
  const {
    component,
    componentIndex,
    moveComponent,
    removeComponent,
    setValue,
  } = props;

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'pipeline-component',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = (item as any).index;
      const hoverIndex = componentIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveComponent(dragIndex, hoverIndex);
      (item as any).index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'pipeline-component',
    item: {
      type: 'pipeline-component',
      id: componentIndex,
      index: componentIndex,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Box
      ref={ref}
      opacity={opacity}
      bg="primary"
      p="6"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <HStack spacing={4}>
          <Badge borderRadius="full" px="2" colorScheme="green">
            {PipelineComponentType[component.type]}
          </Badge>
          <Badge borderRadius="full" px="2" colorScheme="purple">
            {component.name}
          </Badge>
          {(component.requiredComponentName ||
            component.requiredComponentType) && (
            <HStack spacing={2}>
              <Text color="white" fontSize="sm">
                REQUIRES
              </Text>
              <Badge borderRadius="full" px="2" colorScheme="red">
                {component.requiredComponentName ||
                  PipelineComponentType[component.requiredComponentType]}
              </Badge>
            </HStack>
          )}
        </HStack>
        <IconButton
          onClick={removeComponent}
          aria-label="Delete component"
          icon={<DeleteIcon />}
          bg="secondary"
          color="white"
        />
      </Flex>

      <Text fontSize="3xl" color="white">
        {component.name}
      </Text>
      {component.params?.map((param, index) => (
        <ParamValue
          key={index as any}
          param={param}
          setValue={(value: any) => setValue(param.name, value)}
        />
      ))}
    </Box>
  );
};

export default PipelineComponent;
