import axios, { AxiosInstance } from 'axios';
import { ErrorType } from '@projectTypes/Error';

interface AxiosInstanceProps {
  host: string;
  apiRoute?: string;
  route?: string;
}

export default class BaseApiService {
  axiosInstance: AxiosInstance;

  setToken(token: string) {
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  createAxiosInstance(axiosInstanceProps: AxiosInstanceProps) {
    const { host, apiRoute, route } = axiosInstanceProps;
    let baseURL = `${host}`;
    if (apiRoute) {
      baseURL += apiRoute;
    }
    if (route) {
      baseURL += route;
    }
    this.axiosInstance = axios.create({
      baseURL,
    });
    this.setErrorInterceptors();
  }

  private setErrorInterceptors() {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        if (response.status >= 500) {
          throw ErrorType.ServerError;
        } else if (response.status >= 400) {
          throw ErrorType.UserError;
        }
        return response;
      },
      (error) => {
        if (error?.response?.data) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
      },
    );
  }
}
