import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalStore } from '@projectTypes/Global';

const globalInitialState: GlobalStore = {
  isLeftSidebarOpen: true,
  isRightSidebarOpen: false,
  fontSize: 14,
};

const globalSlice = createSlice({
  name: 'global',
  initialState: globalInitialState,
  reducers: {
    setIsLeftSidebarOpen(state, action: PayloadAction<boolean>) {
      state.isLeftSidebarOpen = action.payload;
    },
    setIsRightSidebarOpen(state, action: PayloadAction<boolean>) {
      state.isRightSidebarOpen = action.payload;
    },
    resetFontSize: (state, _action: PayloadAction<void>) => {
      state.fontSize = 14;
    },
    increaseFontSize: (state, _action: PayloadAction<void>) => {
      state.fontSize += 2;
    },
    decreaseFontSize: (state, _action: PayloadAction<void>) => {
      state.fontSize -= 2;
    },
  },
});

export const {
  setIsLeftSidebarOpen,
  setIsRightSidebarOpen,
  resetFontSize,
  increaseFontSize,
  decreaseFontSize,
} = globalSlice.actions;

export default globalSlice.reducer;
