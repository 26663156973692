// import router from 'next/router';

import isWindowAvailable from './isWindowAvailable';

export default function getQueryStringValue(key: string) {
  /*
    NEXTJS router is no loaded at first sometimes
    Using router.ready has some bugs 
    return router.query[key];
  */
  if (!isWindowAvailable()) {
    return null;
  }
  const urlSearchParams = new URLSearchParams(window.location.search);
  const allParams = urlSearchParams.getAll(key);
  if (allParams.length > 1) {
    return allParams;
  }
  return allParams[0];
}
