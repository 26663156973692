import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException, setUser } from '@sentry/nextjs';

import Store from '@projectTypes/Store';
import User from '@projectTypes/User';
import { AdditionalUserInfoDto } from '@projectTypes/Platform';
import actionNameCreator from '@helpers/actionNameCreator';
import getQueryStringValue from '@helpers/getQueryStringValue';
import authService from '@services/AuthService';
import userActionService from '@services/UserActionService';
import heartBeatService from '@services/HeartBeatService';
import platformService from '@services/PlatformService';
import config from '@config';

import { getUserProjects, setShowProjectModal } from './projectSlices';

const userInitialState: User = {
  isLoggedIn: false,
  id: null,
  username: '',
  publicUsername: '',
  role: null,
  isLoading: false,
  token: null,
};

const anc = actionNameCreator('USER');

export const loginUser = createAsyncThunk(
  anc('loginUser'),
  async (
    { username, password }: { username: string; password: string },
    { dispatch },
  ) => {
    dispatch(setIsLoading(true));
    try {
      const userData = await authService.login(username, password);
      dispatch(setIsLoggedIn(true));
      dispatch(getUserProjects());
      dispatch(setIsLoading(false));
      dispatch(setShowProjectModal(true));
      if (userData.platformId) {
        dispatch(getAdditionalUserInfo());
      }
      return userData;
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const loginGuest = createAsyncThunk(
  anc('loginGuest'),
  async (_props, { dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const userData = await authService.loginGuest();
      dispatch(setIsLoggedIn(true));
      dispatch(getUserProjects());
      dispatch(setIsLoading(false));
      dispatch(setShowProjectModal(true));
      if (userData.platformId) {
        dispatch(getAdditionalUserInfo());
      }
      return userData;
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const signupUser = createAsyncThunk(
  anc('signupUser'),
  async (
    { username, password }: { username: string; password: string },
    { dispatch },
  ) => {
    dispatch(setIsLoading(true));
    try {
      const userData = await authService.signup(username, password);
      dispatch(setIsLoggedIn(true));
      dispatch(getUserProjects());
      dispatch(setIsLoading(false));
      return userData;
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const getAdditionalUserInfo = createAsyncThunk(
  anc('getAdditionalUserInfo'),
  async (_, { dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const additionalUserInfo = await platformService.getAdditionalUserInfo();
      dispatch(setAdditionalUserInfo(additionalUserInfo));
      dispatch(setIsLoading(false));
      return additionalUserInfo;
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const verifyUser = createAsyncThunk<boolean, void, { state: Store }>(
  anc('verifyUser'),
  async (_, { dispatch, getState }) => {
    const platformToken = getQueryStringValue(config.platformToken) as string;
    const user = getState().user;
    console.log({ user });
    if (!platformToken && !user.token) {
      return false;
    }
    dispatch(setIsLoading(true));
    try {
      let userData;
      console.log({ user });
      if (platformToken) {
        userData = await authService.loginToken({ platformToken });
      } else {
        userData = await authService.isLoggedIn(user.token);
      }
      console.log({ userData });
      if (userData.publicUsername) {
        dispatch(setPublicUsername(userData.publicUsername));
      }
      if (userData.platformId) {
        heartBeatService.setPlatformId(userData.platformId);
        userActionService.setPlatformId(userData.platformId);
        userActionService.setEditorId(userData.id);
        userActionService.trackAction('editor_open');
        dispatch(getAdditionalUserInfo());
      }
      if (userData.id === user.id) {
        dispatch(getUserProjects());
        return true;
      } else {
        dispatch(setUserData(userData));
        dispatch(getUserProjects());
        return true;
      }
    } catch (e) {
      console.log('test');
      dispatch(setIsLoading(false));
      dispatch(setUserData({}));
      dispatch(setIsLoggedIn(false));
      dispatch(logoutUser());
      throw e;
    }
    return false;
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    setUserData(state, action: PayloadAction<Record<string, any>>) {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    setUsername(state, action: PayloadAction<string>) {
      state.username = action.payload;
    },
    setPublicUsername(state, action: PayloadAction<string>) {
      state.publicUsername = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
    setAdditionalUserInfo(state, action: PayloadAction<AdditionalUserInfoDto>) {
      state.additionalUserInfo = action.payload;
    },
  },
  extraReducers: {
    [loginUser.fulfilled as any]: (state, action) => {
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.role = action.payload.role;
    },
    [loginGuest.fulfilled as any]: (state, action) => {
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.role = action.payload.role;
    },
    [verifyUser.fulfilled as any]: (state, action) => {
      if (action.payload) {
        state.isLoggedIn = true;
      } else {
        state = { ...userInitialState };
      }
    },
  },
});

export const {
  setUserData,
  setUsername,
  setPublicUsername,
  setIsLoading,
  setIsLoggedIn,
  setAdditionalUserInfo,
} = userSlice.actions;

export default userSlice.reducer;

export const logoutUser = createAsyncThunk(
  anc('logoutUser'),
  async (_, { dispatch }) => {
    try {
      authService.logout();
    } catch (e) {
      console.log(e);
    } finally {
      // Check rootReducer for this action
      dispatch({ type: 'store/reset' });
    }
  },
);

export const updateUser = createAsyncThunk<void, { publicUsername?: string }>(
  anc('updateUser'),
  async (data, { dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const user = await authService.updateUser(data);
      dispatch(setPublicUsername(user.publicUsername));
    } catch (e) {
      captureException(e);
    } finally {
      dispatch(setIsLoading(false));
    }
  },
);
