import BaseApiService from '@services/BaseApiService';
import { FileDto } from '@projectTypes/File';

export default class TemplateApiService extends BaseApiService {
  constructor(host: string, apiRoute: string) {
    super();
    this.createAxiosInstance({
      host,
      apiRoute,
      route: '/templates',
    });
  }

  async getTemplateFiles(templateId: string): Promise<FileDto[]> {
    const response = await this.axiosInstance.get(`/${templateId}/files/`);
    return response.data;
  }

  async getTemplateFile(
    templateId: string,
    templateFileId: string,
  ): Promise<FileDto[]> {
    const response = await this.axiosInstance.get(
      `/${templateId}/files/${templateFileId}`,
    );
    return response.data;
  }

  async resetProjectFiles(_projectId: string) {
    // TODO: Implement this
  }
}
