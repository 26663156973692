import React, { useState } from 'react';

import useDispatch from '@hooks/useDispatch';
import { Button, Stack, Select } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import { addComponent } from '@slices/policySlices';
import { PolicyName } from '@projectTypes/Policy';

const NewComponentForm = () => {
  const dispatch = useDispatch();
  const [newComponent, setNewComponent] = useState<PolicyName>();

  const addNewComponent = () => {
    if (newComponent) {
      dispatch(addComponent(newComponent));
    }
  };

  return (
    <Stack direction="row" spacing={4}>
      <Select
        placeholder="Select component"
        maxW="300px"
        bg="primary"
        color="white"
        border="none"
        value={newComponent}
        onChange={(event) => setNewComponent(event.target.value as PolicyName)}
      >
        {Object.values(PolicyName).map((value) => (
          <option key={value} value={value} style={{ color: 'black' }}>
            {value}
          </option>
        ))}
      </Select>
      <Button
        onClick={addNewComponent}
        leftIcon={<AddIcon />}
        bg="primary"
        color="white"
      >
        Add component
      </Button>
    </Stack>
  );
};

export default NewComponentForm;
